import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private routes: Router) { }
  canActivate(
     next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (sessionStorage.getItem('token') != null || (JSON.parse(localStorage.getItem('LoginData')) != null && JSON.parse(localStorage.getItem('LoginData')).userId != null))//sessionStorage.getItem('username') != null) {
    {
      return true;
    }
    else {
      this.routes.navigate(['/login']);
      return false;
    }
    //return true;
  }
}

