import { NgModule, InjectionToken } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, } from '@angular/router';
import { MaterialModule } from '../material.module';


export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  api_base_url: string;
  login: string;
  Header: string;
  //Menu
  getAllMenus: string;
  getMenusData: string;
  bindDropDownMenu: string;
  getAllPredefineRole: string;
  getDashboardAllRecord: string;
  getIADHeadDashboardRecord: string;
  getAuditDHDashboardRecord: string;
  getallschedule: string;
  getallAuditInchart: string;
  getallAuditDHInchart: string;
  getallIAdHeadInchart: string;
  GetDashboardforAuditeeUsers:string;
  GetAuditStatusDashboard:string;
  getDashboardIADHead: string;
  getallAuditInchartforAuditeeSup: string;
  //End Of Menu
  getRoles: string;
  upsertRoles: string;
  toggleRoleActivation: string;
  //Employee
  getAllEmployee: string;
  getAllDesig: string;
  SaveEmployee: string;
  ActiveDeactiveUser: string;
  //End Of Employee
  //Master
  SaveRiskCategory: string;
  GetAllCategoryDetails: string;
  GetAllRiskCategoryDetails: string;
  ActiveDeactive: string;
  BindDropDawnCategory: string;
  //End Of Master
  //Audit Structure
  BindDropFYear: string;
  BindAuditFYearDropDown:string;
  BindAuditQuarterDropDown: string;
  BindUserDropDownForwardReturn:string;
  getController: string;
  getAllPAO: string;
  getauditlist: string;
  assignAuditByController: string;
  getassignPaoAudit: string;
  getScheduledAudits: string;
  updateScheduledAudit: string;
  deleteScheduledAudit: string;
  pendingFinancialForVerifyQuarter: string;
  UpdateScheduledAuditExtension:string;
  updateReScheduleAudit:string;

  //Quartarly Audit
  bindAuditInGrid: string;
  SaveQuartalyAudit: string;
  assignToSupervisor: string;
  SaveQuartalyPlanAudit: string;
  assignQuarterlyPlanToSupervisor: string;
  BindSupervisorDropDown: string;
  assignAuditToSupervisor:string;
  cancelAudit:string;
  //End Of Quartarly Audit

  //End Of Audit Structure

  //Verification
  getScheduledAuditsForVerification: string;
  updateScheduledAuditsForVerification: string;
  getPendingTaskCount: string;
  getAuditDetailsForVerificationDropdown: string;
  getAuditsOfConferenceForVerification: string;
  getTeamOfAuditForVerification: string;
  updateTeamOfAuditForVerification: string;
  getAuditsForwardedToSupervisor: string;
  getAuditsWhoseParasOrComplainceForwardedToSupervisor: string;
  getAuditsWhoseSettlementLetterForwardedToSupervisor: string;
  getDistinctQuartersBasedOnStatusAndFY: string;
  GetApprovedAuditScheduledForExtension:string;
  RequestForUpdationofApprovedAuditScheduleDate:string;
  UpdateExtensionofApprovedAuditSchedule:string;
  RequestToForwardScheduleAuditToAlternateSupervisor:string;
  RequestToForwardScheduleAuditToSupervisor:string;
  //End of Verification

  //Audits
  getApprovedScheduledAudits: string;
  getApprovedAuditTeamAuditsForDropdown: string;
  GetApprovedAuditTeam: string;
  GetApprovedAuditTeamList:string;
  GetApprovedAuditTeam_Audits: string;
  GetQuarterAuditTeam: string;
  revertAuditTeam: string;

  GetAllPAaoAuditRecord: string;
  SendIntimationMails: string;
  GetSendIntimationMailsToAuditeeHead: string;
  //End of Audits
  //change password
  ChangePassword: string;
  CheckUserAndSendMail:string;
  ForgetpasswordGetMail:string;
  GetLatestWhatsNewEntry:string;
  //end changepassword


  //Assign User
  getUserRoleEmployees: string;
  assignUserRole: string;
  getAuditsForDropdown: string;
  getAssignAuditTeam: string;
  checkIfUserAlreadyAssignedToAnotherAudit: string;
  assignAuditTeam: string;
  userRoleDelegation: string;
  getAllUser: string;
  getAllRoleForUser: string;
  getRoleDelegationHistory: string;
  getAuditForwardUser:string;
  //End of Assign User
  //verify Anual plan
  Getauditlistforverify: string;
  getAllQuarterAudit: string;
  anualplanApprove: string;
  rejectanualsAudit: string;
  GetauditlistforverifyQuarterly: string;
  quarterlylplanApprove: string;
  rejectQuarterlyAudit: string;
  getAllPendingQuarter: string;
  supervisorTosupervisor: string;
  supervisorTosupervisorQuarterlyPlan: string;
  GetTotalAuditReport:string;
  GetTotalAuditUnits:string;
  GetAuditDueReport:string;
  //End Of verify

  //Audit Programme
  getAuditProgramme: string;
  // end of Audit Programme

  //Audit Observation
  GetAuditHistoryForAuditObservation :string;
  getAuditDHAuditList: string;
  getAuditDetailsByAuditId: string;
  getAuditDHAuditListOnFIncialYear: string;
  getAuditDHVAuditListOnFinancialYear: string;
  getAuditEntryConference: string;
  updateAuditEntryConference: string;
  getAuditExitConference: string;
  updateAuditExitConference: string;
  getAuditPara: string;
  GetAuditParaAndFinancialImplementation:string;
  updateAuditPara: string;
  getAuditParaDetail: string;
  deleteAuditPara: string;
  postParaComment: string;
  getParaComments: string;
  getAuditParaSettlementLetter: string;
  updateAuditParaSettlementLetter: string;
  updateAuditParaSettlementLetterStatus: string;
  updateAuditStatus: string;
  updateEntryConferenceStatus: string;
  updateExitConferenceStatus: string;
  updateAuditParaStatus: string;
  updateAuditComplianceStatus: string;
  getAllFinancialImplicationCases: string;
  getAuditParaFinancialImplicationCases: string;
  DeleteFileUploadDetailsFromAudit: string;
  DeleteEntryConferenceDetailsAttendees: string;  
  DownLoadAuditFile: string;
  updateAuditMemo: string;
  GetAuditMemoList: string;
  getAuditMemoDetail: string;
  postMemoComment: string;
  getMemoComments: string;
  updateAuditMemoStatus: string;
  deleteAuditMemo: string;
  GenerateDocxBrowser:string;
  GetAuditParaImplementation:string;
  // end of Audit Observation
  //Auditee Observation
  getAuditeeDHAuditList: string;
  getAuditeeEntryConference: string;
  updateAuditeeEntryConference: string;
  getAuditeeExitConference: string;
  updateAuditeeExitConference: string;
  getAuditeePara: string;
  updateAuditeePara: string;
  getAuditeeParaDetail: string;
  deleteAuditeePara: string;
  postAuditeeParaComment: string;
  getAuditeeParaComments: string;
  getAuditeeParaSettlementLetter: string;
  updateAuditeeParaSettlementLetter: string;
  updateAuditeeParaSettlementLetterStatus: string;
  updateAuditeeStatus: string;
  updateAuditeeParaStatus: string;
  GetAuditMemoParaList: string;
  CheckAuditMemoParaRequest: string;
  updateAuditConferenceStatusBySupervisor: string;
  GetAuditObservationDetails: string;
  GetAuditParaListForSettlementLetter: string;
  GetAuditParaSettlementLetterList: string;
  // end of Audit Observation

  //Auditee Universe
  GetAllAuditee: string;
  SaveAuditee: string;
  ActiveDeactiveAuditee: string;
  AuditeeDesignation: string;
  AuditeeRole: string;
  postAuditeeMemoComment: string;
  getAuditeeMemoComments: string;
  GetAuditeeSupervisorDetailsExists:string;
  UpdateAuditeeSupervisorDetailsToExistingAudit:string;
  //Auditee Universe

  // Audit Report
  getAuditsForAuditReportBySupervisor: string;
  getAuditReport: string;
  getAuditReportForm2:string;
  InsertupdateForm2AuditReport:string;
  InsertAuditForwardingLetterDetails:string;
  GetAuditForwardingLetterDetails:string;
  InsertAuditReportExecutiveSummary:string;
  GetAuditReportsExecutiveSummary:string;
  getAuditReportForm4:string;
  InsertupdateForm4AuditReport:string;
  AuditReportsParaFinancialImplicationCases:string;
  AuditReportSave:string;
  UpdateAuditReportRequest:string;
  AuditReportParasStatus:string;
  GetAuditReportListForSupervisor: string;
  GetAuditReportSupervisorAccessDetails:string;
  GetAnnualPlanAuditReport:string;
  GetQuarterlyPlanAuditReport:string;
  GetScheduleAuditPlan:string;
  GetApprovedPaoByControllerCode:string;
  GetScheduleApprovalStatus:string;
  GetAuditMemoReport:string;
  GetTotalParaSettlementStatusReportOnFinancialYear:string;
  GetAgewiseStatusofOutStandingParasOnFinancialYear:string;
  // end of audit report
  //desk audit
  getDeskAuditFiles: string;
  DeskAuditSaveAndUpdate: string;
  active_deactiveDeskAudit: string;
  //Common
  bindSupervisorDropDown: string;
  //End of Common
  //Request History
  GetAllRequestType: string;
  GetRequestTypeData: string;
  GetRequestTypeDetails: string;
  GetAuditHistory: string;
  //End
  //What's new
  GetAllWhatsNewEntry: string;
  PostWhatsNewEntry: string;
  SoftDeleteWhatsNewEntry: string;
  GetAuditUsers: string;
  //End

  GetUserManualList: string;
  PostUserManualFile: string;
  ActivateDeactivateUserManual: string;
  GetActiveUserManual: string;
}

const getHostName = window.location.hostname;

let apiHost = 'https://iaos.cga.gov.in:8080/api/Bridge/';

export const APP_DI_CONFIG: AppConfig = {
  api_base_url: apiHost,
  //==============DashBoard=======
  Header: apiHost + 'Login/LogOff', //Added for audittrail
  login: apiHost + 'Login/LoginCheck',
  getAllMenus: apiHost + 'Dashboard/getAllMenus',
  getDashboardAllRecord: apiHost + 'Dashboardhome/GetAllDashboardRecord',
  getIADHeadDashboardRecord: apiHost + 'Dashboardhome/GetAll_IADHead_DashboardRecord',
  getAuditDHDashboardRecord: apiHost + 'Dashboardhome/GetAuditDhDashboardAllRecord',
  getallschedule: apiHost + 'Dashboardhome/getallschedule',
  getallAuditInchart: apiHost + 'Dashboardhome/GetallAuditInchart',
  getallAuditDHInchart: apiHost + 'Dashboardhome/GetallAuditDHInchart',
  getallIAdHeadInchart: apiHost + 'Dashboardhome/Getall_IADHeadInchart',
  GetDashboardforAuditeeUsers:apiHost + 'Dashboardhome/GetDashboardforAuditeeUsers',
  GetAuditStatusDashboard:apiHost + 'Dashboardhome/GetAuditStatusDashboard',
  getDashboardIADHead : apiHost + 'Dashboardhome/getDashboardIADHead',
  getallAuditInchartforAuditeeSup :  apiHost + 'Dashboardhome/getallAuditInchartforAuditeeSup',
  //============End Of DashBoard======
  //==========Menu=========
  getMenusData: apiHost + 'Menu/BindMenuInGride',
  bindDropDownMenu: apiHost + 'Menu/bindMenuInDropDown',
  getAllPredefineRole: apiHost + 'Menu/GetAllPredefineRole',
  //=========End Of Menu===



  //=========Role============
  getRoles: apiHost + 'Role/GetRoles',
  upsertRoles: apiHost + 'Role/UpsertRoles',
  toggleRoleActivation: apiHost + 'Role/ToggleRoleActivation',
  //=========End of Role================


  //=========Employee=========
  getAllEmployee: apiHost + 'Employee/GetAllEmployee',
  getAllDesig: apiHost + 'Employee/GetAllDesignation',
  SaveEmployee: apiHost + 'Employee/SaveEmployee?hdnEmpId=',
  ActiveDeactiveUser: apiHost + 'Employee/ActiveDeactiveUser',

  //=======End Of Employee=========

  //==============Assign User=============
  getUserRoleEmployees: apiHost + 'AssignUser/GetUserRoleEmployees',
  assignUserRole: apiHost + 'AssignUser/AssignUserRole',
  getAuditsForDropdown: apiHost + 'AssignUser/GetAuditsForDropdown',
  getAssignAuditTeam: apiHost + 'AssignUser/GetAssignAuditTeam',
  checkIfUserAlreadyAssignedToAnotherAudit: apiHost + 'AssignUser/CheckIfUserAlreadyAssignedToAnotherAudit',
  assignAuditTeam: apiHost + 'AssignUser/AssignAuditTeam',
  userRoleDelegation: apiHost + 'AssignUser/UserRoleDelegation',
  getAllUser: apiHost + 'AssignUser/GetAllUser',
  getAllRoleForUser: apiHost + 'AssignUser/GetAllRoleForUser',
  getRoleDelegationHistory: apiHost + 'AssignUser/UserRoleDelegationHistory',
  getAuditForwardUser:apiHost+'AssignUser/GetAuditForwardUser',
  //============End of Assign User=============

  //==============Master==========
  SaveRiskCategory: apiHost + 'RiskCategory/SaveRiskCategory?hdnCategoryId=',
  GetAllCategoryDetails: apiHost + 'RiskCategory/GetAllCategoryDetails',
  GetAllRiskCategoryDetails: apiHost + 'RiskCategory/GetAllRiskCategoryDetails',
  ActiveDeactive: apiHost + 'RiskCategory/ActiveDeactive',
  BindDropDawnCategory: apiHost + 'RiskCategory/BindDropDawnCategory',
  //========End of Master=========


  //Audit Structure
  BindDropFYear: apiHost + 'CreateAuditplan/BindFYearDropDown',
  BindAuditFYearDropDown: apiHost + 'CreateAuditplan/BindAuditFYearDropDown',
  BindAuditQuarterDropDown: apiHost + 'CreateAuditplan/BindAuditQuarterDropDown',
  BindUserDropDownForwardReturn: apiHost + 'CreateAuditplan/BindUserDropDownForwardReturn',
  pendingFinancialForVerifyQuarter: apiHost + 'CreateAuditplan/pendingFinancialForVerifyQuarter',
  BindSupervisorDropDown: apiHost + 'CreateAuditplan/BindSupervisorDropDown',
  getAllPAO: apiHost + 'CreateAuditplan/GetAllPAO',
  getassignPaoAudit: apiHost + 'CreateAuditplan/GetassignPaoAudit',
  getController: apiHost + 'CreateAuditplan/getController',

  getauditlist: apiHost + 'CreateAuditplan/Getauditlist',

  assignAuditByController: apiHost + 'CreateAuditplan/AssignAuditByController',
  getScheduledAudits: apiHost + 'ScheduleAudit/GetScheduledAudits',
  updateScheduledAudit: apiHost + 'ScheduleAudit/UpdateScheduledAudit',
  deleteScheduledAudit: apiHost + 'ScheduleAudit/DeleteScheduledAudit',
  UpdateScheduledAuditExtension: apiHost + 'ScheduleAudit/UpdateScheduledAuditExtension',
  updateReScheduleAudit: apiHost + 'ScheduleAudit/UpdateReScheduledAudit',


  //Quartaly Audit
  bindAuditInGrid: apiHost + 'CreateAuditplan/BindAuditInGrid',
  SaveQuartalyAudit: apiHost + 'CreateAuditplan/SaveQuartalyAudit',
  assignToSupervisor: apiHost + 'CreateAuditplan/AssignToSupervisor',
  assignQuarterlyPlanToSupervisor: apiHost + 'CreateAuditplan/AssignQuarterlyPlanToSupervisor',
  SaveQuartalyPlanAudit: apiHost + 'CreateAuditplan/SaveQuartalyPlanAudit',
  cancelAudit :apiHost + 'CreateAuditplan/cancelAudit',
  assignAuditToSupervisor: apiHost + 'CreateAuditplan/AssignAuditToSupervisor',
  //End Of


  //End Of Audit Structure

  //Verification
  getScheduledAuditsForVerification: apiHost + 'VerifyScheduledAudit/GetScheduledAuditsForVerification',
  updateScheduledAuditsForVerification: apiHost + 'VerifyScheduledAudit/UpdateScheduleAuditForVerification',
  getPendingTaskCount: apiHost + 'VerifyScheduledAudit/GetPendingTaskCount',
  getAuditDetailsForVerificationDropdown: apiHost + 'VerifyAuditTeam/GetAuditDetailsForVerificationDropdown',
  getAuditsOfConferenceForVerification: apiHost + 'VerifyAuditConference/GetAuditsOfConferenceForVerification',
  getTeamOfAuditForVerification: apiHost + 'VerifyAuditTeam/GetTeamOfAuditForVerification',
  updateTeamOfAuditForVerification: apiHost + 'VerifyAuditTeam/UpdateTeamOfAuditForVerification',
  getAuditsForwardedToSupervisor: apiHost + 'VerifyAuditCompliance/GetAuditsForwardedToSupervisor',
  getAuditsWhoseParasOrComplainceForwardedToSupervisor: apiHost + 'VerifyAuditCompliance/GetAuditsWhoseParasOrComplainceForwardedToSupervisor',
  getAuditsWhoseSettlementLetterForwardedToSupervisor: apiHost + 'VerifyAuditCompliance/GetAuditsWhoseSettlementLetterForwardedToSupervisor',
  getDistinctQuartersBasedOnStatusAndFY: apiHost + 'VerifyScheduledAudit/GetDistinctQuartersBasedOnStatusAndFY',
  GetApprovedAuditScheduledForExtension: apiHost + 'VerifyScheduledAudit/GetApprovedAuditScheduledForExtension',
  RequestForUpdationofApprovedAuditScheduleDate: apiHost + 'VerifyScheduledAudit/RequestForUpdationofApprovedAuditScheduleDate',
  UpdateExtensionofApprovedAuditSchedule: apiHost + 'VerifyScheduledAudit/UpdateExtensionofApprovedAuditSchedule',
  RequestToForwardScheduleAuditToAlternateSupervisor: apiHost + 'VerifyScheduledAudit/RequestToForwardScheduleAuditToAlternateSupervisor',
  RequestToForwardScheduleAuditToSupervisor: apiHost + 'VerifyScheduledAudit/RequestToForwardScheduleAuditToSupervisor',

  Getauditlistforverify: apiHost + 'Verifyannualplan/Getauditlistforverify',
  getAllQuarterAudit: apiHost + 'Verifyannualplan/GetAllQuarterAudit',
  anualplanApprove: apiHost + 'Verifyannualplan/AnualplanApprove',
  rejectanualsAudit: apiHost + 'Verifyannualplan/RejectanualsAudit',
  GetauditlistforverifyQuarterly: apiHost + 'Verifyannualplan/GetauditlistforverifyQuarterly',
  rejectQuarterlyAudit: apiHost + 'Verifyannualplan/RejectQuarterlyAudit',
  getAllPendingQuarter: apiHost + 'Verifyannualplan/getAllPendingQuarter',
  quarterlylplanApprove: apiHost + 'Verifyannualplan/QuarterlylplanApprove',
  supervisorTosupervisor: apiHost + 'Verifyannualplan/SupervisorTosupervisor',
  supervisorTosupervisorQuarterlyPlan: apiHost + 'Verifyannualplan/SupervisorTosupervisorQuarterlyPlan',
  GetTotalAuditReport:apiHost + 'AuditReport/GetTotalAuditReport',
  GetTotalAuditUnits:apiHost + 'AuditReport/GetTotalAuditUnits',
  GetAuditDueReport : apiHost+ 'AuditReport/GetAuditDueReport',
  //End of Verification

  //Audits
  GetAuditHistoryForAuditObservation: apiHost + 'Audits/GetAuditHistoryForAuditObservation', 
  getApprovedScheduledAudits: apiHost + 'Audits/GetApprovedScheduledAudits',
  getApprovedAuditTeamAuditsForDropdown: apiHost + 'Audits/GetApprovedAuditTeamAuditsForDropdown',
  GetApprovedAuditTeam: apiHost + 'Audits/GetApprovedAuditTeam',
  GetApprovedAuditTeam_Audits: apiHost + 'Audits/GetApprovedAuditTeam_Audits',
  GetApprovedAuditTeamList: apiHost + 'Audits/GetApprovedAuditTeamList',
  GetQuarterAuditTeam: apiHost + 'Audits/GetQuarterAuditTeam',
  revertAuditTeam: apiHost + 'Audits/RevertAuditTeam',

  GetAllPAaoAuditRecord: apiHost + 'SendIntimationMails/GetAllPAaoAuditRecord',
  SendIntimationMails: apiHost + 'SendIntimationMails/SendMail',

  GetSendIntimationMailsToAuditeeHead: apiHost + 'SendIntimationMails/GetSendIntimationMailsToAuditeeHead',
  
  //End of Audits

  // Audit Programme
  getAuditProgramme: apiHost + 'AuditProgramme/GetAuditProgrammes',
  // End of Audit Programme

  // Audit Observation
  getAuditDHAuditList: apiHost + 'AuditObservation/GetAuditDHAuditsList',
  getAuditDetailsByAuditId: apiHost + 'AuditObservation/GetAuditDetailsByAuditId',
  getAuditDHAuditListOnFIncialYear: apiHost + 'AuditObservation/GetAuditDHAuditsListOnFincialYear',
  getAuditDHVAuditListOnFinancialYear: apiHost + 'AuditObservation/GetAuditDHVAuditsListOnFinancialYear',
  getAuditEntryConference: apiHost + 'AuditObservation/GetAuditEntryConference',
  updateAuditEntryConference: apiHost + 'AuditObservation/UpdateAuditEntryConference',
  getAuditExitConference: apiHost + 'AuditObservation/GetAuditExitConference',
  updateAuditExitConference: apiHost + 'AuditObservation/UpdateAuditExitConference',
  getAuditPara: apiHost + 'AuditObservation/GetAuditPara',
  GetAuditParaAndFinancialImplementation:  apiHost + 'AuditeeObservation/GetAuditParaAndFinancialImplementation',
  updateAuditPara: apiHost + 'AuditObservation/UpdateAuditPara',
  getAuditParaDetail: apiHost + 'AuditObservation/GetAuditParaDetails',
  deleteAuditPara: apiHost + 'AuditObservation/DeleteAuditPara',
  postParaComment: apiHost + 'AuditObservation/PostParaCommentByAuditor',
  getParaComments: apiHost + 'AuditObservation/GetParaComments',
  getAuditParaSettlementLetter: apiHost + 'AuditObservation/GetAuditParaSettlementLetter',
  updateAuditParaSettlementLetter: apiHost + 'AuditObservation/UpdateAuditParaSettlementLetter',
  updateAuditParaSettlementLetterStatus: apiHost + 'AuditObservation/UpdateParaSettlementLetterStatus',
  updateAuditStatus: apiHost + 'AuditObservation/UpdateAuditStatus',
  updateEntryConferenceStatus: apiHost + 'AuditObservation/UpdateEntryConferenceStatus',
  updateExitConferenceStatus: apiHost + 'AuditObservation/UpdateExitConferenceStatus',
  updateAuditParaStatus: apiHost + 'AuditObservation/UpdateAuditParaStatus',
  updateAuditComplianceStatus: apiHost + 'AuditObservation/UpdateAuditComplianceStatus',
  getAllFinancialImplicationCases: apiHost + 'AuditObservation/GetAllFinancialImplicationCases',
  getAuditParaFinancialImplicationCases: apiHost + 'AuditObservation/GetAuditParaFinancialImplicationCases',
  DeleteFileUploadDetailsFromAudit: apiHost + 'AuditObservation/DeleteFileUploadDetailsFromAudit',
  DeleteEntryConferenceDetailsAttendees: apiHost + 'AuditObservation/DeleteEntryConferenceDetailsAttendees',  
  DownLoadAuditFile: apiHost + 'AuditObservation/DownLoadAuditFile',
  updateAuditMemo: apiHost + 'AuditObservation/UpdateAuditMemo',
  GetAuditMemoList: apiHost + 'AuditObservation/GetAuditMemoList',
  getAuditMemoDetail: apiHost + 'AuditObservation/GetAuditMemoDetails',
  postMemoComment: apiHost + 'AuditObservation/PostMemoCommentByAuditor',
  getMemoComments: apiHost + 'AuditObservation/GetMemoComments',
  updateAuditMemoStatus: apiHost + 'AuditObservation/UpdateAuditMemoStatus',
  deleteAuditMemo: apiHost + 'AuditObservation/DeleteAuditMemo',
  GetAuditMemoParaList: apiHost + 'AuditObservation/GetAuditMemoParaList',
  CheckAuditMemoParaRequest: apiHost + 'AuditObservation/CheckAuditMemoParaRequest',
  updateAuditConferenceStatusBySupervisor: apiHost + 'AuditObservation/UpdateAuditConferenceStatusBySupervisor',
  GetAuditObservationDetails: apiHost + 'AuditObservation/AuditObservationDetails',
  GetAuditParaListForSettlementLetter: apiHost + 'AuditObservation/GetAuditParaListForSettlementLetter',
  GetAuditParaSettlementLetterList: apiHost + 'AuditObservation/GetAuditParaSettlementLetterList',
  GenerateDocxBrowser: apiHost + 'AuditReport/GenerateDocxBrowser',
  GetAuditParaImplementation:  apiHost + 'AuditObservation/GetAuditParaAndFinancialImplementation',
  //End of Audit Observation

  // Auditee Observation
  getAuditeeDHAuditList: apiHost + 'AuditeeObservation/GetAuditDHAuditsList',
  getAuditeeEntryConference: apiHost + 'AuditeeObservation/GetAuditEntryConference',
  updateAuditeeEntryConference: apiHost + 'AuditeObservation/UpdateAuditEntryConference',
  getAuditeeExitConference: apiHost + 'AuditeeObservation/GetAuditExitConference',
  updateAuditeeExitConference: apiHost + 'AuditeeObservation/UpdateAuditExitConference',
  getAuditeePara: apiHost + 'AuditeeObservation/GetAuditPara',
  updateAuditeePara: apiHost + 'AuditeeObservation/UpdateAuditPara',
  getAuditeeParaDetail: apiHost + 'AuditeeObservation/GetAuditParaDetails',
  deleteAuditeePara: apiHost + 'AuditeeObservation/DeleteAuditPara',
  postAuditeeParaComment: apiHost + 'AuditeeObservation/PostParaCommentByAuditor',
  getAuditeeParaComments: apiHost + 'AuditeeObservation/GetParaComments',
  getAuditeeParaSettlementLetter: apiHost + 'AuditeeObservation/GetAuditParaSettlementLetter',
  updateAuditeeParaSettlementLetter: apiHost + 'AuditeeObservation/UpdateAuditParaSettlementLetter',
  updateAuditeeParaSettlementLetterStatus: apiHost + 'AuditeeObservation/UpdateParaSettlementLetterStatus',
  updateAuditeeStatus: apiHost + 'AuditeeObservation/UpdateAuditStatus',
  updateAuditeeParaStatus: apiHost + 'AuditeeObservation/UpdateAuditParaStatus',
  postAuditeeMemoComment: apiHost + 'AuditeeObservation/PostMemoCommentByAuditee',
  getAuditeeMemoComments: apiHost + 'AuditeeObservation/GetMemoCommentsByAuditee',
  //End of Auditee Observation
  //Desk Audit
  getDeskAuditFiles: apiHost + 'DeskAudit/getDeskAuditFiles',
  DeskAuditSaveAndUpdate: apiHost + 'DeskAudit/SaveAndUpdateDeskAudit',
  active_deactiveDeskAudit: apiHost + 'DeskAudit/active_deactiveDeskAudit',
  //change password
  ChangePassword: apiHost + 'ChangePassword/ChangePassword',
  CheckUserAndSendMail: apiHost + 'ChangePassword/CheckUserAndSendMail',
  ForgetpasswordGetMail: apiHost + 'ChangePassword/GetUserEmail',
  GetLatestWhatsNewEntry:apiHost + 'Login/GetWhatsNewLatestEntries',
  //end
  //Auditee
  GetAllAuditee: apiHost + 'AuditeeUniverse/GetAllAuditee',
  SaveAuditee: apiHost + 'AuditeeUniverse/SaveAuditee',
  ActiveDeactiveAuditee: apiHost + 'AuditeeUniverse/ActiveDeactiveAuditee',
  AuditeeDesignation: apiHost + 'AuditeeUniverse/GetAllDesignation',
  AuditeeRole: apiHost + 'AuditeeUniverse/GetAuditeeRole',
  GetAuditeeSupervisorDetailsExists: apiHost + 'AuditeeUniverse/GetAuditeeSupervisorDetailsExists',
  UpdateAuditeeSupervisorDetailsToExistingAudit: apiHost + 'AuditeeUniverse/UpdateAuditeeSupervisorDetailsToExistingAudit',
  //Auditee

  //Audit Report
  getAuditsForAuditReportBySupervisor: apiHost + 'AuditReport/GetAuditsForAuditReportBySupervisor',
  getAuditReport: apiHost + 'AuditReport/GetAuditReport',
  getAuditReportForm2: apiHost + 'AuditReport/GetAuditReportForm2',
  InsertupdateForm2AuditReport: apiHost + 'AuditReport/InsertupdateForm2AuditReport',  
  InsertAuditForwardingLetterDetails:apiHost+'AuditReport/InsertAuditForwardingLetterDetails',
  GetAuditForwardingLetterDetails:apiHost+'AuditReport/GetAuditForwardingLetterDetails',
  InsertAuditReportExecutiveSummary:apiHost+'AuditReport/InsertAuditReportExecutiveSummary',
  GetAuditReportsExecutiveSummary:apiHost+'AuditReport/GetAuditReportsExecutiveSummary',
  getAuditReportForm4: apiHost + 'AuditReport/GetAuditReportForm4',
  InsertupdateForm4AuditReport: apiHost + 'AuditReport/InsertupdateForm4AuditReport',
  AuditReportsParaFinancialImplicationCases: apiHost + 'AuditReport/GetAuditParaFinancialImplicationCases',
  AuditReportSave:apiHost + 'AuditReport/AuditReportSave',
  UpdateAuditReportRequest:apiHost + 'AuditReport/updateAuditReportFormRequest',
  AuditReportParasStatus:apiHost + 'AuditReport/GetAuditReportParasStatus',
  GetAuditReportListForSupervisor:apiHost + 'AuditReport/GetAuditReportListForSupervisor',
  GetAuditReportSupervisorAccessDetails :apiHost + 'AuditReport/GetAuditReportSupervisorAccessDetails',
  GetAnnualPlanAuditReport:apiHost + 'AuditReport/GetAnnualAuditPlanReportList',
  GetQuarterlyPlanAuditReport:apiHost + 'AuditReport/GetQuarterlyAuditPlanReport',
  GetScheduleAuditPlan:apiHost + 'AuditReport/GetScheduledAuditReport',
  GetApprovedPaoByControllerCode :apiHost + 'AuditReport/GetApprovedPaoByControllerCode',
  GetScheduleApprovalStatus :apiHost + 'AuditReport/GetScheduleApprovalStatus',
  GetAuditMemoReport:apiHost + 'AuditReport/GetAuditMemoReport',
  GetTotalParaSettlementStatusReportOnFinancialYear:apiHost + 'AuditReport/GetTotalParaSettlementStatusReportOnFinancialYear',
  GetAgewiseStatusofOutStandingParasOnFinancialYear:apiHost + 'AuditReport/GetAgewiseStatusofOutStandingParasOnFinancialYear',
  //end of Audit Report

  //Common
  bindSupervisorDropDown: apiHost + 'CreateAuditplan/BindSupervisorDropDown',
  GetAuditUsers: apiHost + 'CreateAuditplan/GetAuditUsers', 
  //end of Common

  //Request History
  GetAllRequestType: apiHost + 'RequestHistory/GetAllRequestType',
  GetRequestTypeData: apiHost + 'RequestHistory/GetRequestTypeData',
  GetRequestTypeDetails: apiHost + 'RequestHistory/GetRequestTypeDetails',
  GetAuditHistory: apiHost + 'RequestHistory/GetAuditHistory',
  //Request History
  //What's new
  GetAllWhatsNewEntry: apiHost + 'WhatsNew/GetAllWhatsNewEntry',
  PostWhatsNewEntry: apiHost + 'WhatsNew/PostWhatsNewEntry',
  SoftDeleteWhatsNewEntry: apiHost + 'WhatsNew/SoftDeleteWhatsNewEntry',
  //What's new

  GetUserManualList: apiHost + 'UserManual/GetUserManualList',
  PostUserManualFile: apiHost + 'UserManual/PostUserManualFile',
  ActivateDeactivateUserManual: apiHost + 'UserManual/ActivateDeactivateUserManual',
  GetActiveUserManual: apiHost + 'UserManual/GetActiveUserManual',

};



@NgModule({
  imports: [

    FormsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [],
  exports: [

    FormsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})

export class GlobalModule {
}
