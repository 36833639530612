import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { loginModel } from '../model/loginModel';
import { LoginServices } from './loginservice';
import * as CryptoJS from 'crypto-js'
import { HeaderServices } from '../services/headerservices/header.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { WhatsNewModel } from '../model/WhatsNewModel';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [LoginServices, HeaderServices]
})
export class LoginComponent implements OnInit {
	loginModel: loginModel;
	loginForm: FormGroup;
	captcha: string = '';
	captchaPass: string = '';
	captchaPassControl: string = '';
	CheckLoginRole: boolean;
	loginResponse: any;
	deptId: string = 'IAD';
	selectedRoleUserMapId: number;
	NewloginForm: FormGroup;
	fontSize = 14; //Added for Zoom in Zoom out
	@ViewChild('para', {
		static: true
	}) para: ElementRef; //Added for Zoom in Zoom out
	ForgetpasswordPopUp: boolean;
	userEmail: string;
	userName: string;
	whatsNewList: any = [];
	show_button: Boolean = false;
	show_eye: Boolean = false;

	constructor(private router: Router, private _Service: LoginServices,
		private _HService: HeaderServices,
		private _snackBar: MatSnackBar,
		private route: ActivatedRoute,
		public translate: TranslateService) {
		translate.addLangs(['English', 'Hindi']);
		translate.setDefaultLang('English');
		localStorage.setItem('CurrentSelectedLanguages', JSON.stringify(translate.currentLang));
		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match('Hindi') ? 'Hindi' : 'English');
		localStorage.setItem('breadcrumbsData', JSON.stringify([]));
	}

	loginDetails: any = [];

	loginUserId: number;

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		alert('Please enter Login details to enter.');
		if (sessionStorage.getItem("userId")) {
			this._HService.LogOff(sessionStorage.getItem("userId")).subscribe(response => {});
		}
		sessionStorage.clear();
		localStorage.clear();
		this.router.navigate(['/']);
	}
	ngOnInit() {
		if (sessionStorage.getItem("userId")) {
			this._HService.LogOff(sessionStorage.getItem("userId")).subscribe(response => {});
		}
		sessionStorage.clear();
		localStorage.clear();

		this.createForm();
		this.randomString();
		localStorage.setItem('breadcrumbsData', JSON.stringify([]));
		this.GetWhatsNewList();
	}

	GetWhatsNewList() {
		this._Service.getWhatsNewList().subscribe(response => {
			if (response != undefined) {
				this.whatsNewList = response;
			}
		});
	}

	randomString() {
		//clear the contents of captcha div first
		document.getElementById('captcha').innerHTML = "";
		var charsArray =
			"0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ";
		var lengthOtp = 6;
		var captcha = [];
		for (var i = 0; i < lengthOtp; i++) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
			if (captcha.indexOf(charsArray[index]) == -1)
				captcha.push(charsArray[index]);
			else i--;
		}
		var canv = document.createElement("canvas");
		canv.id = "captcha";
		canv.width = 145;
		canv.height = 45;
		var ctx = canv.getContext("2d");
		ctx.font = "30px Arial";
		ctx.strokeText(captcha.join(""), 15, 32);

		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		this.captcha = captcha.join("");
		document.getElementById("captcha").append(canv); // adds the canvas to the body element
		
	}

	captchaReset() {
		if (document.getElementById('captchaReset')) {
			document.getElementById('captchaReset').innerHTML = "";
		}
		var charsArray =
			"0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ";
		var lengthOtp = 6;
		var captcha = [];

		for (var i = 0; i < lengthOtp; i++) {

			var index = Math.floor(Math.random() * charsArray.length + 1);
			if (captcha.indexOf(charsArray[index]) == -1)
				captcha.push(charsArray[index]);
			else i--;
		}

		var canv = document.createElement("canvas");
		canv.id = "captchaCanvas";
		canv.width = 150;
		canv.height = 45;
		var ctx = canv.getContext("2d");
		ctx.font = "30px Arial";
		ctx.strokeText(captcha.join(""), 25, 32);

		this.captchaPass = captcha.join("");

		document.getElementById("captchaReset").append(canv);
	}


	createForm() {
		this.loginForm = new FormGroup({
			userName: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required),
			captchaCode: new FormControl('', Validators.required)
		});

		this.NewloginForm = new FormGroup({
			userName: new FormControl(''),
			password: new FormControl(''),
			captchaCode: new FormControl('')
		});
	}
	resetCaptcha() {
		this.loginForm = new FormGroup({
			captchaCode: new FormControl('', Validators.required)


		});

		this.NewloginForm = new FormGroup({
			captchaCode: new FormControl('')
		});
	}


	showPassword() {
		this.show_button = !this.show_button;
		this.show_eye = !this.show_eye;
	}


	RedirecToHome(formDirective: FormGroupDirective) {
		if (this.loginForm.valid) {
			this.userName = this.loginForm.controls.userName.value;
			if (this.loginForm.controls.captchaCode.value == this.captcha) {
				var key = CryptoJS.enc.Utf8.parse('SPA865HYEMEW237K');

				var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.loginForm.controls.userName.value), key, {
					keySize: 128 / 8,
					iv: key,
					mode: CryptoJS.mode.CBC,
					padding: CryptoJS.pad.Pkcs7
				});
				var encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.loginForm.controls.password.value), key, {
					keySize: 128 / 8,
					iv: key,
					mode: CryptoJS.mode.CBC,
					padding: CryptoJS.pad.Pkcs7
				});


				this.NewloginForm.controls.userName.setValue('+:' + encryptedlogin);
				this.NewloginForm.controls.password.setValue('+:' + encryptedpassword);
				this.NewloginForm.removeControl('captchaCode');
				//this.NewloginForm.controls.captchaCode.setValue(this.loginForm.controls.captchaCode.value);
				this._Service.Login(this.NewloginForm.value).subscribe(res => {
					this.loginResponse = res.result;
					if (this.loginResponse.status == true) {
						this.CheckLoginRole = true;
					} else {
						swal("Username/Password not valid.");
						this.createForm();
						this.randomString();
						this.loginForm.reset();
					}
				});
			} else {
				swal("Captcha is not correct.");
				//this.createForm();
				//this.loginForm.reset();
				this.loginForm.controls.captchaCode.setValue('');
				this.loginForm.controls.captchaCode.updateValueAndValidity();
				this.randomString();

			}
		}
	}

	//Added for Zoom in Zoom out
	changeFont(operator) {

		if (operator == "*") {
			this.fontSize = 14;
		}
		if ((this.fontSize > 22 && operator == "+") || (this.fontSize < 10 && operator == "-")) {
			return false;
		} else {
			operator === '+' ? this.fontSize++ : this.fontSize--;
			(this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;
		}
	}

	NavigateToDashboard() {
		if (this.selectedRoleUserMapId > 0) {
			this.CheckLoginRole = false;
			let selectedRoleData = this.loginResponse.result.filter(a => a.roleUserMapId == this.selectedRoleUserMapId)[0] as any;
			sessionStorage.setItem('userId', selectedRoleData.userId);
			sessionStorage.setItem('token', JSON.stringify(this.loginResponse.token));
			sessionStorage.setItem('roleId', selectedRoleData.roleId);
			localStorage.setItem("LoginData", JSON.stringify(selectedRoleData));
			localStorage.setItem("DeptId", JSON.stringify({
				"deptId": this.deptId
			}));
			localStorage.setItem('breadcrumbsData', JSON.stringify([]));
			localStorage.setItem('LoginResponse', JSON.stringify(this.loginResponse));
			localStorage.setItem('CurrentSelectedLanguages', JSON.stringify(this.translate.currentLang));
			if (this.loginResponse.result[0].isFirstLogin == true) {
				this.router.navigate(['/changepassword'], {
					relativeTo: this.route,
					queryParams: {
						u: this.userName
					}
				});
			} else {
				this.router.navigate(['dashboard/home']);
			}

		}
	}

	closeRoleModal() {
		this.CheckLoginRole = false;
		this.ForgetpasswordPopUp = false;
	}

	GetCurrentLanguages(value: string) {
		if (localStorage["CurrentSelectedLanguages"] == null || localStorage["CurrentSelectedLanguages"] == "") {
			localStorage.removeItem("CurrentSelectedLanguages");
		}
		if (value.toString() != undefined && value.toString().length > 0) {
			localStorage.setItem('CurrentSelectedLanguages', JSON.stringify(value));
			this.translate.use(value);
		}
	}

	openPopUpForgetPassword() {
		this.ForgetpasswordPopUp = true;
		this.userEmail = '';
		setTimeout(() => this.captchaReset(), 50);
	}

	checkUser() {
		if (!this.userEmail) {
			swal("Please enter Email.")
			return false;
		}
		if (this.captchaPassControl == this.captchaPass) {
			this._Service.CheckUserAndSendMail(this.userEmail).subscribe(res => {

				if (res == "success") {
					swal("Email is sent for changing password.");
					this.closeRoleModal();
				} else {
					swal("User details not found in system.");
					this.closeRoleModal();
				}
				this.captchaPassControl = null;
			});
		} else {
			swal("Captcha is not correct.");
			this.captchaReset();
		}

	}


	OpenPDFDocument(fileName: string) {
    this._Service.ViewPDFDocument('Documents/WhatsNew/' + fileName).subscribe(res => {
			const fileURL = URL.createObjectURL(new Blob([res], {
				type: 'application/pdf'
			}));
			window.open(fileURL, '_blank');
		});
	}

	ViewNewUpdates() {
		const updateUrl = this.router.serializeUrl(
			this.router.createUrlTree(['/newupdates'])
		);
		window.open(updateUrl, '_blank');
	}

}
