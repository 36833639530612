import { Injectable } from "@angular/core";
@Injectable()
export class CommonMsg {
  /* common messages use in application */
  saveMsg: string = 'Record has been saved successfully.';
  updateMsg: string = 'Record has been updated successfully.';
  updateMsgForAuditeeHeadApprove: string = 'Record has been approved';
  updateMsgForAuditeeHeadReject: string = 'Record has been rejected';
  AuditeeHeadApproveandForwardtoSupervisor: string = 'Para has been approved and forwared to Audit Supervisor';
  AuditeeHeadParaRejected: string='Para has been returned successfully.';
  AuditeeHeadComplianceRejected: string='Audit Compliance Report has been returned successfully.';
  AuidtScheduleReturnMsg: string='Audit Schedule returned to IAD Head';
  deleteMsg: string = 'Record has been deleted successfully.';
  alreadyExistMsg: string = 'Record Already Exist.';
  noRecordMsg: string = 'No Record Found.';
  enterCorrectValueMsg: string = 'Please Enter correct value.';
  checkDeclarationMsg: string = 'Please check Declaration.';
  forwardSupervisorMsg: string = 'Forwarded to Audit Supervisor';
  forwardAuditHeadMsg: string = 'Forwarded to Audit Head';
  forwardAuditAAOMsg: string = 'Forwarded to Audit AAO';
  forwardAuditeeHeadMsg: string = 'Forwarded to Auditee Head';
  forwardAuditDHMsg: string = 'Forwarded to Audit Entry User';
  verifiedParaLetter: string = 'Para Settlement Letter Verified';
  rejectAuditDHMsg: string = 'Returned and forwarded to Audit Entry User';
  updateFailedMsg: string = 'Failed to update Record.';
  alreadyForwardedMsg: string = 'Record already forwarded';
  deleteFailedMsg: string = 'Failed to Delete Record';
  forwardCheckerMsg: string = 'Record forwarded to Checker successfully';
  saveFailedMsg: string = 'Failed to Save Record';
  formatfileMsg: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  pdffileMsg: string = 'Only pdf file upload';
  apppdffileMsg: string = 'application/pdf';
  errorMsg: string = 'An error has occurred. Please contact your system administrator.';
  commonerrorMsg: string = 'Some error occured!';
  fileDelettion:string='File deleted successfully';
  AuditParaDetailsComment:string='Please enter valid comment.';
  AttendeeNameRequired:string='Please enter valid Name';
  AttendeeDesignation:string='Please enter valid Designation.';
  AttendeeMobileNo:string='Please enter valid Mobile No.';
  AttendeeEmailID:string='Please enter valid Email ID.';
  AttendeeEmailexist:string='Email ID already exist';
  AttendeeMobileNoExist:string='Mobile No. already exist';
  ExitDeletionRemarks:string='Please enter deletion remarks for selected Paras'
  recordsApprovedByAuditHead:string='Exit Conference approved successfully';
  auditParaApprovedByAuditHead:string='Audit Para Approved successfully';
  auditmemorejected:string='Audit Memo returned to Reviewer';
  auditmemoForwarded:string='Audit Memo forwarded to Reviewer';
  auditmemoApproved:string='Audit Memo approved';
  auditmemoFreeze:string='Audit Memo freezed';
  auditmemoclose:string='Audit Memo closed';
  auditmemoForwardedtoAuditHead:string='Audit Memo forwarded to Audit Head for approval';
  auditMemoRejectedByAuditHead:string='Audit Memo has been returned successfully';
  approvedbyAuditHead:string='Entry Conference approved and forwarded to Auditee Head'
  approveAuditDHMsg:string = 'Para Settlement Letter approved successfully';
  approveAuditDHreportMsg:string = 'Audit Report accepted successfully';
  auditparacompliancerejected:string='Audit Para Compliance returned to Reviewer';
  auditparacomplianceForwarded:string='Audit Para Compliance forwarded to Reviewer';
  /* common messages use in application */

  /* New Loan message */
  amountLessEqualloanAmountMsg: string = 'Entered Amount should be less than or equal to Loan Amount';
  amountActuallyPaidMsg: string = 'Entered Actually Paid Amount should be less than or equal to Cost Amount'
  loanAmountGreaterthenInstallMsg: string = 'Loan Amount should be greater than installment Amount';
  maximumLimitLoanAmountMsg: string = 'Maximum Limit for Loan Amount is Rs. 25 Lakhs';
  maxLimitLoanAmountMsg: string = 'Maximum Limit for Loan Amount of Computer Advance is Rs. 50000 or actual price of the PC, whichever is lower';
  maxLimitLoanAmount1LK80Msg: string = 'Maximum Limit for Loan Amount is Rs. 1,80,000.00';
  maxLimitLoanAmount2LK50Msg: string = 'Maximum Limit for Loan Amount is Rs. 2,50,000.00';
  /* New Loan message */

  /* Assign User */
  selectAtleastOneAssigner: string = 'Please assign atleast one employee';
  selectAtleastOneAssignerToAuditDH: string = 'Please assign atleast one employee to Audit DH';
  selectAtleastOneAssignerToAuditAAO: string = 'Please assign atleast one employee to Audit AAO';
  selectAtleastOneAssignerToAuditHead: string = 'Please assign atleast one employee to Audit Head';
  selectOnlyOneAssigner: string = 'Please Assign only one employee';
  /* Assign User */
  entryconferenceReturnMsg :string = 'Entry Conference returned to Reviewer';
  approvedEntryConfMsg: string = "Entry Conference approved successfully";
  entryconferenceForwardMsg: string='Entry Conference forwarded to Reviewer';

  exitconferenceReturnMsg :string = 'Exit Conference returned to Reviewer';
  approvedexitConfMsg: string = "Exit Conference approved successfully";
  exitconferenceForwardMsg: string='Exit Conference forwarded to Reviewer';

  selectReviewer:string="Please select Reviewer";
  returnRemarks:string="Please enter return remarks";

  auditReportReturnMsg :string = 'Audit Report returned to Reviewer';  
  auditReportForwardMsg: string='Audit Report forwarded to Reviewer';

  complianceReturnMsg :string = 'Para compliance returned to Reviewer';  
  complianceForwardMsg: string='Para compliance forwarded to Reviewer';
  complianceApproveMsg: string = "Para compliance approved successfully";

  settlementReturnMsg :string = 'Para settlement letter returned to Reviewer';  
  settlementForwardMsg: string='Para settlement letter forwarded to Reviewer';
  settlementApproveMsg: string = "Para settlement letter approved successfully";

iadAdminEmail: string = "iad-cga[at]nic[dot]in";

  charaterOnlyNoSpace(event): boolean {
    if (event.target.value === "") {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode !== 32) {
        return true;
      }
      return false;
    }
  }

  getquartersWithDate(finYear: string): any[] {
    let quarters = [];
    if (finYear) {
      let years = finYear.split("-");

      quarters = [{ Id: "Q1", Desc: "Q1 (1 April, " + years[0] + " to 30 June, " + years[0] + ")", StartDate: "04/01/" + years[0], EndDate: "06/30/" + years[0] },
        { Id: "Q2", Desc: "Q2 (1 July, " + years[0] + " to 30 September, " + years[0] + ")", StartDate: "07/01/" + years[0], EndDate: "09/30/" + years[0] },
        { Id: "Q3", Desc: "Q3 (1 October, " + years[0] + " to 31 December, " + years[0] + ")", StartDate: "10/01/" + years[0], EndDate: "12/31/" + years[0] },
        { Id: "Q4", Desc: "Q4 (1 January, 20" + years[1] + " to 31 March, 20" + years[1] + ")", StartDate: "01/01/20" + years[1], EndDate: "03/31/20" + years[1]}]
    }
    return quarters;
  }

  getRandomColor(): string {
    var specificColors = ['bf5f82', '9575cd', '8c7b75', '00867d'];
    var letters = '0123';
    let letter = letters[Math.floor(Math.random() * 4)];
    var color = '#' + specificColors[+letter];
    return color;
  }
  onEditScrollTop() {
    window.scrollTo(0, 0);
  }
}
