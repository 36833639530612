import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable } from 'rxjs';
import { audit, map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';

@Injectable({
  providedIn: 'root'
})
export class AuditReportService {

  constructor(private _http: HttpClient,private _Service: LoginServices , @Inject(APP_CONFIG) private config: AppConfig) { }

  BindDropFYear(): Observable<any> {
    return this._http.get(this.config.BindDropFYear, {}).pipe(map((resp: any) => { return resp.result }));
}

  getAuditsForAuditReportBySupervisor(pageSize, pageNumber, searchTerm): Observable<any> {
    return this._http.get(this.config.getAuditsForAuditReportBySupervisor + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, {}).pipe(map((resp: any) => {return resp.result}));
  }

  getAuditReport(auditId): Observable<any> {
    return this._http.get(this.config.getAuditReport + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  getAuditReportForm2(auditId): Observable<any> {
    return this._http.get(this.config.getAuditReportForm2 + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  InsertupdateForm2AuditReport(obj): Observable<any> {
    return this._http.post(this.config.InsertupdateForm2AuditReport, obj).pipe(map((resp: any) => { return resp.result }));
  }

  InsertAuditForwardingLetterDetails(obj): Observable<any> {
    if(obj.RoleUserMapId != "")
    {
      obj.RoleUserMapId = this._Service.encryptPost(obj.RoleUserMapId);
    }
    obj.CreatedBy = this._Service.encryptPost(obj.CreatedBy);
    return this._http.post(this.config.InsertAuditForwardingLetterDetails, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditForwardingLetterDetails(obj): Observable<any> {
    if( obj.RoleUserMapId != undefined && obj.RoleUserMapId != "" )
    {
      obj.RoleUserMapId = this._Service.encryptPost(obj.RoleUserMapId)
    }
    
    return this._http.post(this.config.GetAuditForwardingLetterDetails, obj).pipe(map((resp: any) => { return resp.result }));
  }
  InsertAuditReportExecutiveSummary(obj): Observable<any> {
     obj.CreatedBy = this._Service.encryptPost(obj.CreatedBy);
    return this._http.post(this.config.InsertAuditReportExecutiveSummary, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditReportsExecutiveSummary(obj): Observable<any> {
    return this._http.post(this.config.GetAuditReportsExecutiveSummary, obj).pipe(map((resp: any) => { return resp.result }));
  }
  getAuditReportForm4(auditId): Observable<any> {
    return this._http.get(this.config.getAuditReportForm4 + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  InsertupdateForm4AuditReport(obj): Observable<any> {
  return this._http.post(this.config.InsertupdateForm4AuditReport, obj).pipe(map((resp: any) => { return resp.result }));
  }
  AuditReportsParaFinancialImplicationCases(auditId): Observable<any> {
    return this._http.get(this.config.AuditReportsParaFinancialImplicationCases + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  // Mobile Data View
  MAuditReportsParaFinancialImplicationCases(auditId): Observable<any> {
    auditId = this._Service.encryptString(auditId);
    return this._http.get(this.config.AuditReportsParaFinancialImplicationCases + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  AuditReportParasStatus(auditId): Observable<any> {
    return this._http.get(this.config.AuditReportParasStatus + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  MAuditReportParasStatus(auditId): Observable<any> {
    return this._http.get(this.config.AuditReportParasStatus + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  AuditReportSave(obj): Observable<any> {
    obj.CreatedBy = this._Service.encryptPost(obj.CreatedBy);
    return this._http.post(this.config.AuditReportSave, obj).pipe(map((resp: any) => { return resp.result }));
  }
  UpdateAuditReportRequest(obj): Observable<any> {
    obj.UserID = this._Service.encryptPost(obj.UserID);
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.UpdateAuditReportRequest, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditReportListForSupervisor(pageSize, pageNumber, searchTerm, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.GetAuditReportListForSupervisor + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm+"&roleUserMapId="+roleUserMapId, {}).pipe(map((resp: any) => {return resp.result}));
  }
  AuditReportSupervisorAccessDetails(roleUserMapId): Observable<any> {
    roleUserMapId=this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.GetAuditReportSupervisorAccessDetails + "?roleUserMapID=" + roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  GetAnnualPlanAuditReport(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetAnnualPlanAuditReport, obj).pipe(map((resp: any) => { return resp.result }));
  }
  
  GetQuarterlyPlanAuditReport(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetQuarterlyPlanAuditReport, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetScheduleAuditPlan(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetScheduleAuditPlan, obj).pipe(map((resp: any) => { return resp.result }));
  }

  GetApprovedPaoByControllerCode(ControllerID) {
    ControllerID=this._Service.encryptString(ControllerID);
    return this._http.get(this.config.GetApprovedPaoByControllerCode + "?ControllerID=" + ControllerID, {}).pipe(map((resp: any) => { return resp.result }));
  }
  GetScheduleApprovalStatus() {
    return this._http.get(this.config.GetScheduleApprovalStatus, {}).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditMemoReport(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetAuditMemoReport, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetTotalParaSettlementStatusReportOnFinancialYear(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetTotalParaSettlementStatusReportOnFinancialYear, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAgewiseStatusofOutStandingParasOnFinancialYear(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetAgewiseStatusofOutStandingParasOnFinancialYear, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetTotalAuditReport(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetTotalAuditReport, obj).pipe(map((resp: any) => { return resp.result }));
  }  
  GetAuditUnitsReport(FYearId) {
    FYearId=this._Service.encryptString(FYearId);
    return this._http.get(this.config.GetTotalAuditUnits  + "?FYearId=" + FYearId,{}).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditDueReport(obj): Observable<any> {
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetAuditDueReport, obj).pipe(map((resp: any) => { return resp.result }));
  }

}
