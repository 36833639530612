<div class="row">
  <div class="col-md-12">
    <div class="card left-brd-pink">
      <div class="card-header">
        <strong class="card-title">{{selectedAudit.name}}</strong>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <!-- Form 1 Forwarding Letter Details -->
    <div class="card left-brd-white">
      <div class="card-body card-block">
        <mat-card *ngIf="auditReportForwardingLetterDetails">
          <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
            <div class="row">
              <div class="col-md-12" style="text-align: center; font-size: small;">
                <b style="padding-left: 79%;">{{auditReportName}}</b><br>
                <b>Goverment of India</b><br>
                <b>Ministry of Finance</b><br>
                <b>Department of Expenditure</b><br>
                <b>Controller General of Accounts</b><br>
                <b>Internal Audit Devision</b><br>
                <b>Mahalekha Niyantrak Bhawan</b><br>
                <b>E-Block, GPO, INA</b><br>
                <b>New Delhi – 110023</b><br>
                <b>Email Id:- {{_msg.iadAdminEmail}}</b><br>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <b>No.</b> <span style="display: inline-block;">{{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterNumber}}</span>
                <span style="padding-left: 79%;">Dated: {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterDate | date: 'dd/MM/yyyy'}}</span>
              </div>
              <!-- <div class="col-md-4">
              <b>Dated:</b> {{auditReportForwardingLetterDetails[0].letterDate | date: 'dd/MM/yyyy'}}
            </div> -->
            </div><br />
            <div class="row" style="font-size: small;">
              <div class="col-md-3">
                <b>To,</b>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterTo}}"></div>
                <b></b>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>Subject</u>: </b></div>
              <div class="col-md-10" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterSubject}}"></div>
              </div>

            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterContent}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-8"></div>
              <div class="col-md-4" style="word-wrap:break-word">
                <b>Yours Faithfully,</b><br />
                <div innerHTML="{{auditReportForwardingLetterDetails.letterFrom}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>Copy To:-</u></b></div><br />
              <div class="col-md-8" style="text-align: left;">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterCopyTo}}"></div>
              </div>
              <div class="col-md-2">
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <!-- Form 2 Enter Key Components Related to Auditee -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <img src="../../../../assets/img/Nationalemblem.png">
            </div>
          </div><br>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b>Goverment of India</b><br>
              <b>Ministry of Finance</b> <br>
              <b>Office of the Controller General of Accounts</b> <br><br>
              <b>Internal Audit Division</b> <br><br>
              <b>New Delhi</b><br><br>
              <b>I.R.No. {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.auditRow}} / {{selectedAudit.financialYear}} </b><br><br>
              <b>Inspection Report of </b><br>
              <b>{{selectedAudit.paoName}}</b><br><br>
              <b>Period of Audit</b><br>
              <b>({{selectedAudit.currentFinancialYear}})</b><br><br>
              <b>Inspection conducted by </b><br>
              <b>{{selectedauditorteam}}</b><br><br>
              <b>From {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} to {{selectedAudit.auditToDate | date:
                'dd/MM/yyyy'}}</b><br><br>
              <b>Report Issued On - {{auditReportForwardingLetterDetails.approvedDate | date: 'dd/MM/yyyy'}}</b><br><br>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b style="text-align:center;margin-bottom: 1em;">KEY COMPONENTS RELATED TO AUDITEE</b>
            </div>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>Status of authorization during the period of Audit</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of PAO</th>
                  <th>Period of posting</th>
                  <th>Key activation period</th>
                  <th>office tele number</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.statusofAuthorizationList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.periodofPosting}}</td>
                  <td>{{comp.keyActivationPeriod}}</td>
                  <td>{{comp.officeTelephoneNumber}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Sanctioned strength and working strength of the office as on Audit date</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of the Post</th>
                  <th>Sanction strength</th>
                  <th>Working strength</th>
                  <th>vacancies</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.sanctionedStrengthList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.sanctionStrength}}</td>
                  <td>{{comp.workingStrength}}</td>
                  <td>{{comp.vacancies}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Budget/Expenditure of the auditee(only case of budget overridden) -
              <span *ngFor="let comp of KeyComponents.budget_ExpenditureList;index as i;">
                <span *ngIf="comp.budget_Expenditure == ''"> N/A</span>
                <span *ngIf="comp.budget_Expenditure != ''"> {{comp.budget_Expenditure}}</span>
              </span>
            </b>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Count of NCDDOs/CDDOs/DDOs of the Auditee</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Number of NCDDOs</th>
                  <th>Number of CDDOs:</th>
                  <th>Number of DDOs:</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.ncddOs_CDDOs_DDOsList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofNCDDOs}}</td>
                  <td>{{comp.numberofCDDOs}}</td>
                  <td>{{comp.numberofDDOs}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding CGA Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCGAAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding C&AG Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCAGAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding Internal Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingInternalAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 3 Executive Summary -->
    <div class="card left-brd-white">
      <div class="card-body card-block">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row">
            <div class="col-md-12" style="text-align: center;font-size: small;">
              <span style="text-align: center;font-weight: bold;"> Executive Summary:</span> <br><br>
              <div innerHTML="{{executiveSummery}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 4 Detailed Risk Assessments Findings -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row" style="margin-top: 2%; text-align: center;">
            <div class="col-md-12" style="text-align: center;">
              <b>OVERVIEW OF RISK ASSESSMENT OF {{selectedAudit.paoName}} </b>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <table>
                <tr>
                  <td>S No.</td>
                  <td>CATEGORY OF RISK</td>
                  <td>RISK RATING</td>
                  <td>COLOUR CODING</td>
                  <td>Marks</td>
                </tr>
                <tr *ngFor="let comp of Overall;index as i; ">
                  <td>{{i+1}}</td>
                  <td>
                    <span *ngIf="comp.riskId == 1"> Financial Risk </span>
                    <span *ngIf="comp.riskId == 2"> Acounting Risk </span>
                    <span *ngIf="comp.riskId == 3"> ServiceDelivery Risk </span>
                    <span *ngIf="comp.riskId == 4"> System Risk </span>
                    <span *ngIf="comp.riskId == 5"> Documentation Risk </span>
                    <span *ngIf="comp.riskId == 6"> Establishment Risk </span>
                    <span *ngIf="comp.riskId == 7"> Other Risk </span>

                  </td>
                  <td>
                    {{comp.value}}
                  </td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div style="word-wrap:break-word">
                <div class="col-md-2"
                  style="background-color: red;height: 30px;width: 2.3em;margin-right: 2%;float: left;">
                </div>
                <div class="col-md-10" style="text-align: left;"> High Risk Area that must be addressed to immediately.
                </div>

              </div>
            </div>

            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: yellow;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;"> A moderate risk area that requires to be addressed with
                greater controls &
                supervison.</div>
              <div class="col-md-4"></div>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: green;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;"> A low risk area which should be maintained with adequate
                controls.</div>
              <div class="col-md-4"></div>
            </div>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong class="card-title">Observations of the Audit Party </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <table>
              <thead>
                <tr>
                  <th style="width:10%">Para Number</th>
                  <th style="width:90%">Para Title</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let para of auditParaList;index as i; ">
                  <td>{{para.paraNumber}}</td>
                  <td>{{para.title}}</td>
                </tr>
            </table>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>PART 1</strong> <br>
            <strong class="card-title">DETAILED RISK ASSESSMENT FINDINGS </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>1.Financial Risk</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.financialRisk;index as i; ">
                  <td>1.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>2.Accounting</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.accounting;index as i; ">
                  <td>2.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>3.Service Delivery</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.serviceDelivery;index as i; ">
                  <td>3.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>4. Documentation</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.documentation;index as i; ">
                  <td>4.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>5. Establishment</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.establishment;index as i; ">
                  <td>5.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>6. System</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.system;index as i; ">
                  <td>6.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <br>
          <div class="row" style="margin-top: 2%;">
            <b>7. Other</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.other;index as i; ">
                  <td>7.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>PART 2</strong> <br>
            <strong class="card-title">DETAILED REPORT</strong>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>The inspection of {{selectedAudit.paoName}}, has been conducted for the financial year
              {{selectedAudit.financialYear}} during the period from
              {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} to {{selectedAudit.auditToDate | date:
              'dd/MM/yyyy'}}
              . The detailed observations of the inspection are as under: -
            </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <!--------------------------------------------------------------->
            <div style="width:100%;margin: 1%;" *ngFor="let details of auditParaList;index as i;">
              <div>
                <div><strong>Para Title : </strong> <label>{{details.title}}</label></div>
                <div><strong>Criteria : </strong>
                  <div class="conference-background-color" innerHTML="{{details.criteria}}"></div>
                </div>
                <div><strong>Conditions : </strong>
                  <div class="conference-background-color" innerHTML="{{details.conditions}}"></div>
                </div>
                <div><strong>Consequences : </strong>
                  <div class="conference-background-color" innerHTML="{{details.consequences}}"></div>
                </div>
                <div><strong>Causes (Role position) : </strong>
                  <div class="conference-background-color" innerHTML="{{details.causes}}"></div>
                </div>
                <div><strong>Corrective Actions : </strong>
                  <div class="conference-background-color" innerHTML="{{details.correctiveActions}}"></div>
                </div>
                <div><strong>Details : </strong>
                  <div class="conference-background-color" innerHTML="{{details.details}}"></div>
                </div>
                <div><strong>Severity : </strong>
                  <div class="conference-background-color"><label>{{details.severity}}</label></div>
                </div>
                <div>
                  <strong>Risk Category :</strong>
                  <div class="conference-background-color">
                    <label>{{details.categoryName}}</label>
                  </div>
                </div>
                <div>
                  <strong>Risk Category Description: </strong>
                  <div class="conference-background-color"> <label>{{details.riskCategoryDesc}}</label>
                  </div>
                </div>
                <div>
                  <strong>Audit Memo: </strong>
                  <div class="conference-background-color"> <label>{{details.memoNumber}}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="details.finResponse.length > 0">
                <strong>Financial Implications Details : </strong>
                <table>
                  <thead>
                    <tr>
                      <th>
                        Financial Implication Case
                      </th>
                      <th>
                        Amount
                      </th>
                      <th>
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let impli of details.finResponse">
                      <td>{{impli.financialImplicationCase}}</td>
                      <td>{{impli.amount}}</td>
                      <td>{{impli.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-------------------------------------------------------------->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" id="print-section" style="display: none;">
  <div class="col-md-12">
    <!-- Form 1 Forwarding Letter Details -->
    <div class="card left-brd-white">
      <div class="card-body card-block">
        <mat-card *ngIf="auditReportForwardingLetterDetails">
          <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
            <div class="row">
              <div class="col-md-12" style="text-align: center; font-size: small;">
                <b style="padding-left: 75%;">{{auditReportName}}</b><br>
                <b>Goverment of India</b><br>
                <b>Ministry of Finance</b><br>
                <b>Department of Expenditure</b><br>
                <b>Controller General of Accounts</b><br>
                <b>Internal Audit Devision</b><br>
                <b>Mahalekha Niyantrak Bhawan</b><br>
                <b>E-Block, GPO, INA</b><br>
                <b>New Delhi – 110023</b><br> 
                <b>Email Id:- {{_msg.iadAdminEmail}}</b><br>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <b>No.</b> <span style="display: inline-block;">{{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterNumber}}</span>
                <span style="padding-left: 75%;display: inline-block;">Dated: {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterDate | date: 'dd/MM/yyyy'}}</span>
              </div>
              <!-- <div class="col-md-4">
              <b>Dated:</b> {{auditReportForwardingLetterDetails[0].letterDate | date: 'dd/MM/yyyy'}}
            </div> -->
            </div><br />
            <div class="row" style="font-size: small;">
              <div class="col-md-3">
                <b>To,</b>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterTo}}"></div>
                <b></b>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>Subject</u>: </b></div>
              <div class="col-md-10" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterSubject}}"></div>
              </div>

            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterContent}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-8"></div>
              <div class="col-md-4" style="word-wrap:break-word">
                <b>Yours Faithfully,</b><br />
                <div innerHTML="{{auditReportForwardingLetterDetails.letterFrom}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>Copy To:-</u></b></div><br />
              <div class="col-md-8" style="text-align: left;">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterCopyTo}}"></div>
              </div>
              <div class="col-md-2">
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <!-- Form 2 Enter Key Components Related to Auditee -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <img src="../../../../assets/img/Nationalemblem.png">
            </div>
          </div><br>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b>Goverment of India</b><br>
              <b>Ministry of Finance</b> <br>
              <b>Office of the Controller General of Accounts</b> <br><br>
              <b>Internal Audit Division</b> <br><br>
              <b>New Delhi</b><br><br>
              <b>I.R.No. {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.auditRow}} / {{selectedAudit.financialYear}} </b><br><br>
              <b>Inspection Report of </b><br>
              <b>{{selectedAudit.paoName}}</b><br><br>
              <b>Period of Audit</b><br>
              <b>({{selectedAudit.currentFinancialYear}})</b><br><br>
              <b>Inspection conducted by </b><br>
              <b>{{selectedauditorteam}}</b><br><br>
              <b>From {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} to {{selectedAudit.auditToDate | date:
                'dd/MM/yyyy'}}</b><br><br>
              <b>Report Issued On - {{auditReportForwardingLetterDetails.approvedDate | date: 'dd/MM/yyyy'}}</b><br><br>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b style="text-align:center;margin-bottom: 1em;">KEY COMPONENTS RELATED TO AUDITEE</b>
            </div>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>Status of authorization during the period of Audit</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of PAO</th>
                  <th>Period of posting</th>
                  <th>Key activation period</th>
                  <th>office tele number</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.statusofAuthorizationList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.periodofPosting}}</td>
                  <td>{{comp.keyActivationPeriod}}</td>
                  <td>{{comp.officeTelephoneNumber}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Sanctioned strength and working strength of the office as on Audit date</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of the Post</th>
                  <th>Sanction strength</th>
                  <th>Working strength</th>
                  <th>vacancies</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.sanctionedStrengthList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.sanctionStrength}}</td>
                  <td>{{comp.workingStrength}}</td>
                  <td>{{comp.vacancies}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Budget/Expenditure of the auditee(only case of budget overridden) -
              <span *ngFor="let comp of KeyComponents.budget_ExpenditureList;index as i;">
                <span *ngIf="comp.budget_Expenditure == ''"> N/A</span>
                <span *ngIf="comp.budget_Expenditure != ''"> {{comp.budget_Expenditure}}</span>
              </span>
            </b>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Count of NCDDOs/CDDOs/DDOs of the Auditee</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Number of NCDDOs</th>
                  <th>Number of CDDOs:</th>
                  <th>Number of DDOs:</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.ncddOs_CDDOs_DDOsList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofNCDDOs}}</td>
                  <td>{{comp.numberofCDDOs}}</td>
                  <td>{{comp.numberofDDOs}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding CGA Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCGAAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding C&AG Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCAGAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding Internal Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of Para</th>
                  <th>Para Settled</th>
                  <th>Para Outstanding</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingInternalAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 3 Executive Summary -->
    <div class="card left-brd-white">
      <div class="card-body card-block">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row">
            <div class="col-md-12" style="text-align: center;font-size: small;">
              <span style="text-align: center;font-weight: bold;"> Executive Summary:</span> <br><br>
              <div innerHTML="{{executiveSummery}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 4 Detailed Risk Assessments Findings -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row" style="margin-top: 2%; text-align: center;">
            <div class="col-md-12" style="text-align: center;">
              <b>OVERVIEW OF RISK ASSESSMENT OF {{selectedAudit.paoName}} </b>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <table>
                <tr>
                  <td>S No.</td>
                  <td>CATEGORY OF RISK</td>
                  <td>RISK RATING</td>
                  <td>COLOUR CODING</td>
                  <td>Marks</td>
                </tr>
                <tr *ngFor="let comp of Overall;index as i; ">
                  <td>{{i+1}}</td>
                  <td>
                    <span *ngIf="comp.riskId == 1"> Financial Risk </span>
                    <span *ngIf="comp.riskId == 2"> Acounting Risk </span>
                    <span *ngIf="comp.riskId == 3"> ServiceDelivery Risk </span>
                    <span *ngIf="comp.riskId == 4"> System Risk </span>
                    <span *ngIf="comp.riskId == 5"> Documentation Risk </span>
                    <span *ngIf="comp.riskId == 6"> Establishment Risk </span>
                    <span *ngIf="comp.riskId == 7"> Other Risk </span>

                  </td>
                  <td>
                    {{comp.value}}
                  </td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div style="word-wrap:break-word">
                <div class="col-md-2"
                  style="background-color: red;height: 30px;width: 2.3em;margin-right: 2%;float: left;">
                </div>
                <div class="col-md-10" style="text-align: left;"> High Risk Area that must be addressed to immediately.
                </div>

              </div>
            </div>

            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: yellow;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;"> A moderate risk area that requires to be addressed with
                greater controls &
                supervison.</div>
              <div class="col-md-4"></div>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: green;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;"> A low risk area which should be maintained with adequate
                controls.</div>
              <div class="col-md-4"></div>
            </div>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong class="card-title">Observations of the Audit Party </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <table>
              <thead>
                <tr>
                  <th style="width:10%">Para Number</th>
                  <th style="width:90%">Para Title</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let para of auditParaList;index as i; ">
                  <td>{{para.paraId}}</td>
                  <td>{{para.title}}</td>
                </tr>
            </table>
          </div>


          <div style="text-align: center;margin-top: 2%;">
            <strong>PART 1</strong> <br>
            <strong class="card-title">DETAILED RISK ASSESSMENT FINDINGS </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>1.Financial Risk</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.financialRisk;index as i; ">
                  <td>1.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>2.Accounting</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.accounting;index as i; ">
                  <td>2.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>3.Service Delivery</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.serviceDelivery;index as i; ">
                  <td>3.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>4. Documentation</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.documentation;index as i; ">
                  <td>4.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>5. Establishment</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.establishment;index as i; ">
                  <td>5.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>6. System</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.system;index as i; ">
                  <td>6.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <br>
          <div class="row" style="margin-top: 2%;">
            <b>7. Other</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">S.No</th>
                  <th style="width:70%">Aspects</th>
                  <th style="width:20%">Color Coding</th>
                  <th>Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.other;index as i; ">
                  <td>7.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>PART 2</strong> <br>
            <strong class="card-title">DETAILED REPORT</strong>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>The inspection of {{selectedAudit.paoName}}, has been conducted for the financial year
              {{selectedAudit.financialYear}} during the period from
              {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} to {{selectedAudit.auditToDate | date:
              'dd/MM/yyyy'}}
              . The detailed observations of the inspection are as under: -
            </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <!--------------------------------------------------------------->
            <div style="width:100%;margin: 1%;" *ngFor="let details of auditParaList;index as i;">
              <div>
                <div><strong>Para Title : </strong> <label>{{details.title}}</label></div>
                <div><strong>Criteria : </strong>
                  <div class="conference-background-color" innerHTML="{{details.criteria}}"></div>
                </div>
                <div><strong>Conditions : </strong>
                  <div class="conference-background-color" innerHTML="{{details.conditions}}"></div>
                </div>
                <div><strong>Consequences : </strong>
                  <div class="conference-background-color" innerHTML="{{details.consequences}}"></div>
                </div>
                <div><strong>Causes (Role position) : </strong>
                  <div class="conference-background-color" innerHTML="{{details.causes}}"></div>
                </div>
                <div><strong>Corrective Actions : </strong>
                  <div class="conference-background-color" innerHTML="{{details.correctiveActions}}"></div>
                </div>
                <div><strong>Details : </strong>
                  <div class="conference-background-color" innerHTML="{{details.details}}"></div>
                </div>
                <div><strong>Severity : </strong>
                  <div class="conference-background-color"><label>{{details.severity}}</label></div>
                </div>
                <div>
                  <strong>Risk Category :</strong>
                  <div class="conference-background-color">
                    <label>{{details.categoryName}}</label>
                  </div>
                </div>
                <div>
                  <strong>Risk Category Description: </strong>
                  <div class="conference-background-color"> <label>{{details.riskCategoryDesc}}</label>
                  </div>
                </div>
                <div>
                  <strong>Audit Memo: </strong>
                  <div class="conference-background-color"> <label>{{details.memoNumber}}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="details.finResponse.length > 0">
                <strong>Financial Implications Details : </strong>
                <table>
                  <thead>
                    <tr>
                      <th>
                        Financial Implication Case
                      </th>
                      <th>
                        Amount
                      </th>
                      <th>
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let impli of details.finResponse">
                      <td>{{impli.financialImplicationCase}}</td>
                      <td>{{impli.amount}}</td>
                      <td>{{impli.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-------------------------------------------------------------->
          </div>
        </div>
      </div>
    </div>

  </div>
</div>