import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root'
})
export class AuditObservationService {
  
  constructor(private _http: HttpClient, private _Service: LoginServices, @Inject(APP_CONFIG) private config: AppConfig, private _CommonService: CommonService) { }

  BindDropFYear(): Observable<any> {
    return this._http.get(this.config.BindDropFYear, {}).pipe(map((resp: any) => { return resp.result }));
  }

  BindAuditFYearDropDown(userID): Observable<any> {
    userID = this._Service.encryptString(userID);
    return this._http.get(this.config.BindAuditFYearDropDown + "?userID=" + userID, {}).pipe(map((resp: any) => { return resp.result }));
  }
  BindAuditQuarterDropDown(financialYearId, userID): Observable<any> {
    financialYearId = this._Service.encryptString(financialYearId);
    userID = this._Service.encryptString(userID);
    return this._http.get(this.config.BindAuditQuarterDropDown + "?financialYearId=" + financialYearId + "&userID=" + userID, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditDHAuditList(auditUserId): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    return this._http.get(this.config.getAuditDHAuditList + "?auditUserId=" + auditUserId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditDHAuditListOnFIncialYear(auditUserId, financialYearId, quarter): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    financialYearId = this._Service.encryptString(financialYearId);
    quarter = this._Service.encryptString(quarter);
    return this._http.get(this.config.getAuditDHAuditListOnFIncialYear + "?auditUserId=" + auditUserId + "&financialYearId=" + financialYearId + "&quarter=" + quarter, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditDHVAuditListOnFinancialYear(auditUserId, financialYearId, quarter): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    financialYearId = this._Service.encryptString(financialYearId);
    quarter = this._Service.encryptString(quarter);
    return this._http.get(this.config.getAuditDHVAuditListOnFinancialYear + "?auditUserId=" + auditUserId + "&financialYearId=" + financialYearId + "&quarter=" + quarter, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditDetailsByAuditId(auditUserId, auditId): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    return this._http.get(this.config.getAuditDetailsByAuditId + "?auditUserId=" + auditUserId + "&auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  // Mobile Data View
  getMAuditDetailsByAuditId(auditUserId, auditId): Observable<any> {
    return this._http.get(this.config.getAuditDetailsByAuditId + "?auditUserId=" + auditUserId + "&auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditEntryConference(auditId, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditEntryConference + "?auditId=" + auditId+"&roleUserMapId="+roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditEntryConference(obj): Observable<any> {   
    return this._http.post(this.config.updateAuditEntryConference, obj).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditExitConference(auditId, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditExitConference + "?auditId=" + auditId+"&roleUserMapId=" + roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditExitConference(obj): Observable<any> {
    return this._http.post(this.config.updateAuditExitConference, obj).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditParas(auditId, pageSize, pageNumber, searchTerm, roleID, type, roleUserMapId): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditPara + "?auditId=" + auditId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm + "&roleID=" + roleID + "&type=" + type+"&roleUserMapId="+roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  getAuditParaAndFinancialImplementation(auditId, pageSize, pageNumber, searchTerm, roleID, type): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditParaAndFinancialImplementation + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp }));
  }
  getAuditParaImplementation(auditId, pageSize, pageNumber, searchTerm, roleID, type): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditParaImplementation + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp }));
  }

  getAuditParaDetails(paraId, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditParaDetail + "?paraId=" + paraId+"&roleUserMapId="+roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditPara(obj, files): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    };
    formData.append('AuditId', obj.auditId);
    formData.append('ParaId', obj.paraId);
    formData.append('Title', obj.title.toString());
    formData.append('Criteria', obj.criteria.toString());
    formData.append('Conditions', obj.conditions.toString());
    formData.append('Consequences', obj.consequences.toString());
    formData.append('Causes', obj.causes.toString());
    formData.append('CorrectiveActions', obj.correctiveActions.toString());
    formData.append('Severity', obj.severity.toString());
    formData.append('RiskCategoryId', obj.riskCategoryId);
    formData.append('UserId', obj.userId);
    formData.append('roleUserMapid', obj.roleUserMapId);
    formData.append('Details', obj.details.toString());
    formData.append('FinancialImplications', JSON.stringify(obj.paraFinancialImplications));
    formData.append('SubRiskCategoryID', obj.subriskCategoryID)
    formData.append('memoID', obj.MemoID);
     return this._http.post(this.config.updateAuditPara, formData).pipe(map((resp: any) => { return resp.result }));
  }

  deleteAuditPara(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.deleteAuditPara, obj).pipe(map((resp: any) => { return resp.result }));
  }

  postParaComment(obj, files): Observable<any> {
    const formData: FormData = new FormData();
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.roleUserMapID = this._Service.encryptPost(obj.roleUserMapID);
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditId', obj.auditId);
    formData.append('ParaId', obj.paraId);
    formData.append('Comment', obj.comment.toString());
    formData.append('UserId', obj.userId);
    formData.append('roleUserMapID', obj.roleUserMapID);
    formData.append('Dislat', obj.lat);
    formData.append('Dislong', obj.lng);
    return this._http.post(this.config.postParaComment, formData).pipe(map((resp: any) => { return resp.result }));
  }

  getParaComments(paraId): Observable<any> {
    return this._http.get(this.config.getParaComments + "?paraId=" + paraId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditParaSettlementLetter(paraSettlementLetterID, roleUserMapID): Observable<any> {
    roleUserMapID = this._Service.encryptString(roleUserMapID);
    return this._http.get(this.config.getAuditParaSettlementLetter + "?paraSettlementLetterID=" + paraSettlementLetterID+"&roleUserMapID="+roleUserMapID, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditParaSettlementLetter(obj, files): Observable<any> {
    const formData: FormData = new FormData();
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditParaSettlementLetterId', obj.auditParaSettlementLetterId);
    formData.append('LetterNumber', obj.letterNumber);
    formData.append('LetterDate', this.convert(new Date(obj.letterDate)));
    formData.append('LetterTo', obj.letterTo);
    formData.append('LetterSubject', obj.letterSubject);
    formData.append('LetterFrom', obj.letterFrom);
    formData.append('LetterCopyTo', obj.letterCopyTo);
    formData.append('LetterContent', obj.letterContent);
    formData.append('AuditId', obj.auditId);
    formData.append('UserId', obj.userId);
    formData.append('AuditParaIDs', obj.SelectedAuditParaDetails);
    formData.append('RoleUserMapId', obj.roleUserMapId);
    return this._http.post(this.config.updateAuditParaSettlementLetter, formData).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditParaSettlementLetterStatus(obj): Observable<any> {
    obj.UserID = this._Service.encryptPost(obj.UserID);
    if(obj.StatusID != "")
    {
      obj.StatusID = this._Service.encryptPost(obj.StatusID);
    }
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.updateAuditParaSettlementLetterStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditStatus(obj): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.statusId = this._Service.encryptPost(obj.statusId);
    return this._http.post(this.config.updateAuditStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  updateEntryConferenceStatus(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.roleUserMapID = this._Service.encryptPost(obj.roleUserMapID);
    obj.StatusId = this._Service.encryptPost(obj.StatusId);
    return this._http.post(this.config.updateEntryConferenceStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  updateExitConferenceStatus(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    if(obj.StatusId != "")
    {
      obj.StatusId = this._Service.encryptPost(obj.StatusId);
    }
    
    return this._http.post(this.config.updateExitConferenceStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditParaStatus(obj): Observable<any> {
    obj.UserID = this._Service.encryptPost(obj.UserID);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    obj.statusId = this._Service.encryptPost(obj.statusId);
    return this._http.post(this.config.updateAuditParaStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }
  updateAuditComplianceStatus(obj): Observable<any> {
    obj.UserID = this._Service.encryptPost(obj.UserId);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    obj.statusId = this._Service.encryptPost(obj.statusId);
    return this._http.post(this.config.updateAuditComplianceStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  getFinancialImplicationsForDropdown(): Observable<any> {
    return this._http.get(this.config.getAllFinancialImplicationCases, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditParaFinancialImplications(paraId: any): Observable<any> {
    return this._http.get(this.config.getAuditParaFinancialImplicationCases + "?paraId=" + paraId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  convert(str): any {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  DeleteFileUploadFromAuditRecords(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.userId);
    return this._http.post(this.config.DeleteFileUploadDetailsFromAudit, obj).pipe(map((resp: any) => { return resp.result }));
  };

  DeleteentryConferenceDetailAttendees(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.DeleteEntryConferenceDetailsAttendees, obj).pipe(map((resp: any) => { return resp.result }));
  };

  getdownloadDetails(url: string) {
    url = this._Service.encryptString(url);
    return this._http.get(this.config.api_base_url + 'DeskAudit/DownloadFile?url=' + url, { responseType: 'arraybuffer' }).pipe(map((resp: any) => { return resp }));

  };

  async getAllFiles(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    url = this._Service.encryptString(url);
    let completeurl = this.config.api_base_url + "DeskAudit/DownloadFile?url=" + url;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };

  getAuditExistConferenceList(auditId, pageSize, pageNumber, searchTerm): Observable<any> {
    auditId = this._Service.encryptString(auditId);
    return this._http.get(this.config.getAuditPara + "?auditId=" + auditId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditMemo(obj, files): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('auditId', obj.auditId);
    formData.append('memoID', obj.memoID);
    formData.append('roleUserMapId', this._Service.encryptString(obj.roleUserMapId));
    formData.append('memoNumber', obj.memoNumber);
    formData.append('memoTitle', obj.memoTitle);
    formData.append('memoDate', this.convert(new Date(obj.memoDate)));
    formData.append('criteria', obj.criteria);
    formData.append('userId', obj.userId);
    formData.append('statusID', this._Service.encryptPost(obj.statusID));
    return this._http.post(this.config.updateAuditMemo, formData).pipe(map((resp: any) => { return resp.result }));
  }

  GetAuditMemoList(auditId, roleID, pageSize, pageNumber, searchTerm): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditMemoList + "?auditId=" + auditId + "&roleID=" + roleID + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditMemoDetails(memoID, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditMemoDetail + "?memoID=" + memoID + "&roleUserMapId=" + roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  postMemoComment(obj, files): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.UserRoleMapID = this._Service.encryptPost(obj.UserRoleMapID);
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditId', obj.auditId);
    formData.append('MemoID', obj.memoID);
    formData.append('Comment', obj.comment.toString());
    formData.append('UserId', obj.userId);
    formData.append('UserRoleMapID', obj.UserRoleMapID);
    formData.append('Dislat', obj.lat);
    formData.append('Dislong', obj.lng);
    return this._http.post(this.config.postMemoComment, formData).pipe(map((resp: any) => { return resp.result }));
  }

  getMemoComments(memoID): Observable<any> {
    return this._http.get(this.config.getMemoComments + "?memoID=" + memoID, {}).pipe(map((resp: any) => { return resp.result }));
  }

  openLocation(lat:any, lng:any){  
    this._CommonService.viewLocation(lat, lng);
  }

  updateAuditMemoStatus(obj): Observable<any> {
    obj.userID = this._Service.encryptPost(obj.userID);
    if(obj.RoleUserMapID != null || obj.RoleUserMapID != ""){
      obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    }
    if(obj.statusId != null || obj.statusId != ""){
      obj.statusId = this._Service.encryptPost(obj.statusId);
    }
    return this._http.post(this.config.updateAuditMemoStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  deleteAuditMemo(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.deleteAuditMemo, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditMemoParaList(auditId, roleID): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditMemoParaList + "?auditId=" + auditId + "&roleID=" + roleID, {}).pipe(map((resp: any) => { return resp.result }));
  }
  CheckAuditMemoParaRequest(obj): Observable<any> {
    return this._http.post(this.config.CheckAuditMemoParaRequest, obj).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditConferenceStatusBySupervisor(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.userroleUserMapID = this._Service.encryptPost(obj.userroleUserMapID);
    obj.ForwardedroleUserMapID = this._Service.encryptPost(obj.ForwardedroleUserMapID);
    if(obj.StatusId != "")
    {
      obj.StatusId = this._Service.encryptPost(obj.StatusId);
    }
    return this._http.post(this.config.updateAuditConferenceStatusBySupervisor, obj).pipe(map((resp: any) => { return resp.result }));
  }
  GetAuditObservationDetails(obj): Observable<any> {
    obj.UserID = this._Service.encryptPost(obj.UserID);
    obj.RoleID =this._Service.encryptPost(obj.RoleID);
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.GetAuditObservationDetails, obj).pipe(map((resp: any) => { return resp.result }));
  }

  GetAuditParaListForSettlementLetter(auditId, roleID): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditParaListForSettlementLetter + "?auditId=" + auditId + "&roleID=" + roleID, {}).pipe(map((resp: any) => { return resp.result }));
  }

  GetAuditParaSettlementLetterList(auditId, roleID, pageSize, pageNumber, searchTerm): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditParaSettlementLetterList + "?auditId=" + auditId + "&roleID=" + roleID + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, {}).pipe(map((resp: any) => { return resp.result }));
  }

  async GenerateDocxBrowser(memoID: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    let completeurl = this.config.api_base_url + "DeskAudit/GenerateDocxBrowser?memoID=" + memoID;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };

  async GenerateDocxBrowserForParaID(ParaID: number) {
    ParaID = this._Service.encryptString(ParaID);
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    let completeurl = this.config.api_base_url + "DeskAudit/GenerateDocxBrowserForParaID?ParaID=" + ParaID;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };

  DownloadFile(objfile: any, fileType: string): Observable<any> {
    let fileExtension = fileType;
    return this._http.post(this.config.api_base_url + "DeskAudit/DownloadFile", objfile, { responseType: 'blob' }).pipe(map((res) => {
      var blob = new Blob([res], { type: fileExtension })
      return blob;
    }));
  }
}
