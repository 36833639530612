import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root'
})
export class AuditeeObservationService {
  headers: any;
  constructor(private _http: HttpClient, private _Service: LoginServices, @Inject(APP_CONFIG) private config: AppConfig, private _CommonService: CommonService) { 
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods':'GET, POST',
        'Access-Control-Allow-Credentials':'true',
        'Access-Control-Max-Age':'362880'
      }) 
    }
  }

  BindDropFYear(): Observable<any> {

    return this._http.get(this.config.BindDropFYear, this.headers).pipe(map((resp: any) => { return resp.result }));

  }
  BindAuditFYearDropDown(userID): Observable<any> {
    userID = this._Service.encryptString(userID);
    return this._http.get(this.config.BindAuditFYearDropDown + "?userID=" + userID, this.headers).pipe(map((resp: any) => { return resp.result }));
  }
  BindAuditQuarterDropDown(financialYearId, userID): Observable<any> {
    financialYearId = this._Service.encryptString(financialYearId);
    userID = this._Service.encryptString(userID);
    return this._http.get(this.config.BindAuditQuarterDropDown + "?financialYearId=" + financialYearId + "&userID=" + userID, this.headers).pipe(map((resp: any) => { return resp.result }));
  }
  getdownloadDetails(url: string) {
    url = this._Service.encryptString(url);
    return this._http.get(this.config.api_base_url + 'AuditeeObservation/DownloadFile?url=' + url, { responseType: 'arraybuffer' }).pipe(map((resp: any) => { return resp }));
  }

  getAuditDHAuditListOnFIncialYear(auditUserId, financialYearId, quarter): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    financialYearId = this._Service.encryptString(financialYearId);
    quarter = this._Service.encryptString(quarter);
    return this._http.get(this.config.getAuditDHAuditListOnFIncialYear + "?auditUserId=" + auditUserId + "&financialYearId=" + financialYearId + "&quarter=" + quarter, {}).pipe(map((resp: any) => { return resp.result }));
  }
  getAuditDHAuditList(auditUserId): Observable<any> {
    auditUserId = this._Service.encryptString(auditUserId);
    var Result = this._http.get(this.config.getAuditeeDHAuditList + "?auditUserId=" + auditUserId, this.headers).pipe(map((resp: any) => { return resp }));
    return Result;
  }

  getAuditEntryConference(auditId, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditeeEntryConference + "?auditId=" + auditId+"&roleUserMapId="+roleUserMapId, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditEntryConference(obj): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    return this._http.post(this.config.updateAuditeeEntryConference, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateEntryConferenceStatus(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.roleUserMapID = this._Service.encryptPost(obj.roleUserMapID);
    if(obj.StatusId != "")
    {
      obj.StatusId= this._Service.encryptPost(obj.StatusId);
    }
   return this._http.post(this.config.updateEntryConferenceStatus, obj, this.headers).pipe(map((resp: any) => { return resp.result }));
  }


  getAuditExitConference(auditId, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
        return this._http.get(this.config.getAuditeeExitConference + "?auditId=" + auditId+"&roleUserMapId="+roleUserMapId, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateExitConferenceStatus(obj): Observable<any> {
    obj.userId = this._Service.encryptPost(obj.userId);
    obj.roleUserMapId = this._Service.encryptPost(obj.roleUserMapId);
    obj.StatusId = this._Service.encryptPost(obj.StatusId);
    return this._http.post(this.config.updateExitConferenceStatus, obj, this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  updateAuditExitConference(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.updateAuditeeExitConference, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  getAuditParas(roleId, auditId, pageSize, pageNumber, searchTerm): Observable<any> {
    roleId = this._Service.encryptString(roleId);
    return this._http.get(this.config.getAuditeePara + "?roleId=" + roleId + "&auditId=" + auditId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, this.headers).pipe(map((resp: any) => { return resp }));
  }


  getAuditParaDetails(paraId, roleUserMapID): Observable<any> {    
    roleUserMapID = this._Service.encryptString(roleUserMapID);
    return this._http.get(this.config.getAuditeeParaDetail + "?paraId=" + paraId+"&roleUserMapId="+roleUserMapID, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditPara(obj, files): Observable<any> {
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditId', obj.auditId);
    formData.append('ParaId', obj.paraId);
    formData.append('Title', obj.title.toString());
    formData.append('Criteria', obj.criteria.toString());
    formData.append('Conditions', obj.conditions.toString());
    formData.append('Consequences', obj.consequences.toString());
    formData.append('Causes', obj.causes.toString());
    formData.append('CorrectiveActions', obj.correctiveActions.toString());
    formData.append('Severity', obj.severity.toString());
    formData.append('RiskCategoryId',obj.riskCategoryId);
    formData.append('UserId', this._Service.encryptPost(obj.userId));
    return this._http.post(this.config.updateAuditeePara, formData, this.headers).pipe(map((resp: any) => { return resp }));
  }

  deleteAuditPara(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.deleteAuditeePara, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  postParaComment(obj, files): Observable<any> {
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditId', obj.auditId);
    formData.append('ParaId', obj.paraId);
    formData.append('Comment', obj.comment.toString());
    formData.append('UserId', this._Service.encryptPost(obj.userId));
    formData.append('roleUserMapID', this._Service.encryptPost(obj.roleUserMapID));
    formData.append('Dislat', obj.lat);
    formData.append('Dislong', obj.lng);
    return this._http.post(this.config.postAuditeeParaComment, formData).pipe(map((resp: any) => { return resp }));
  }

  getParaComments(paraId): Observable<any> {
    return this._http.get(this.config.getParaComments + "?paraId=" + paraId, this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditParaSettlementLetter(auditId): Observable<any> {
    auditId = this._Service.encryptString(auditId);
    return this._http.get(this.config.getAuditeeParaSettlementLetter + "?auditId=" + auditId, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditParaSettlementLetter(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    return this._http.post(this.config.updateAuditeeParaSettlementLetter, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditParaSettlementLetterStatus(auditId, statusId, rejectionRemark): Observable<any> {
    return this._http.get(this.config.updateAuditeeParaSettlementLetterStatus + "?auditId=" + auditId + "&statusId=" + statusId + "&rejectionRemark=" + rejectionRemark, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditStatus(obj): Observable<any> {
    obj.StatusId = this._Service.encryptPost(obj.StatusId);
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    return this._http.post(this.config.updateAuditeeStatus, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  updateAuditParaStatus(obj): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.statusId = this._Service.encryptPost(obj.statusId);
    return this._http.post(this.config.updateAuditeeParaStatus, obj, this.headers).pipe(map((resp: any) => { return resp }));
  }

  DeleteFileUploadFromAuditRecords(obj): Observable<any> {
    return this._http.post(this.config.DeleteFileUploadDetailsFromAudit, obj, this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  GetAuditMemoList(auditId, roleID, pageSize, pageNumber, searchTerm): Observable<any> {
    roleID = this._Service.encryptString(roleID);
    return this._http.get(this.config.GetAuditMemoList + "?auditId=" + auditId + "&roleID=" + roleID + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  getAuditMemoDetails(memoID, roleUserMapId): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    return this._http.get(this.config.getAuditMemoDetail + "?memoID=" + memoID + "&roleUserMapId=" + roleUserMapId, this.headers).pipe(map((resp: any) => { return resp.result }));
  }
  postMemoComment(obj, files): Observable<any> {
    const formData: FormData = new FormData();
    if (files) {
      for (var i = 0; i < files.length; i++) {
        formData.append('UploadFiles', files[i]);
      }
    }
    formData.append('AuditId', obj.auditId);
    formData.append('MemoID', obj.memoID);
    formData.append('Comment', obj.comment.toString());
    formData.append('UserId', obj.userId);
    formData.append('UserRoleMapID', obj.UserRoleMapID);
    formData.append('Dislat', obj.lat);
    formData.append('Dislong', obj.lng);
    return this._http.post(this.config.postAuditeeMemoComment, formData).pipe(map((resp: any) => { return resp }));
  }

  getMemoComments(memoID): Observable<any> {
    return this._http.get(this.config.getAuditeeMemoComments + "?memoID=" + memoID, this.headers).pipe(map((resp: any) => { return resp }));
  }

  openLocation(lat:any, lng:any){  
    this._CommonService.viewLocation(lat, lng);
  }

  updateAuditMemoStatus(obj): Observable<any> {
    obj.userID = this._Service.encryptPost(obj.userID);
    if(obj.RoleUserMapID != null || obj.RoleUserMapID != ""){
      obj.RoleUserMapID = this._Service.encryptPost(obj.RoleUserMapID);
    }
    if(obj.statusId != null || obj.statusId != ""){
    obj.statusId = this._Service.encryptPost(obj.statusId);
    }
    return this._http.post(this.config.updateAuditMemoStatus, obj).pipe(map((resp: any) => { return resp.result }));
  }

  async getAllFiles(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    url = this._Service.encryptString(url);
    let completeurl = this.config.api_base_url + "DeskAudit/DownloadFile?url=" + url;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };

  getWhatsNewFileURL(url: string) {
    return this.config.api_base_url + "Documents/WhatsNew/" + url;
  };

  async GenerateDocxBrowser(memoID: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    let completeurl = this.config.api_base_url + "DeskAudit/GenerateDocxBrowser?memoID=" + memoID;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };

  async GenerateDocxBrowserForParaID(ParaID: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    let completeurl = this.config.api_base_url + "DeskAudit/GenerateDocxBrowserForParaID?ParaID=" + ParaID;
    const res = await this._http.get(completeurl, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  };
}
