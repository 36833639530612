import { Component, OnInit,Inject,Injectable , Input,TemplateRef, Output, EventEmitter,ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { MatDialog,MatDialogConfig,MatDialogRef, MAT_DIALOG_DATA,MatDialogModule } from '@angular/material/dialog';
import { DeskauditService } from '../../services/desk-audit/deskaudit.service';
import { saveAs } from 'file-saver';
import { AuditeeObservationService } from '../../services/auditee_observation/auditee-observation.service';
import { MailBodyPopupComponent } from '../../shared-module/mail-body-popup/mail-body-popup.component'

@Component({
  selector: 'app-mail-history-popup',
  templateUrl: './mail-history-popup.component.html',
  styleUrls: ['./mail-history-popup.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class MailHistoryPopupComponent implements OnInit {
  mailHistroyID: string;
  mailContentArr: any[] = [];
  mailContent: string;

  dataSourcehistory : any[];
  letterNumber:any;
  letterTitle:any;
  titleHead: any;
  hidden=true;
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  displayedColumnsMoreDetails: string[] = ['MailTo', 'MailCC', 'MailSubject','MailBody','Response','FileName'];
  
  constructor(private services: DeskauditService, @Inject(MAT_DIALOG_DATA) public data: any,
  private _service: AuditeeObservationService ,
 private dialog: MatDialog 
  ) { }

  ngOnInit() {    
   
    this.dataSourcehistory=this.data.dataSourceMoreDetails;    
    this.titleHead=this.data.titleHead
  }
  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);    
  }
  DownloadFile(url: any): any {    
    this._service.getdownloadDetails('Documents/SentInimateMails/' + url).subscribe(res => {
      saveAs(new Blob([res], { type: 'application/pdf;charset=utf-8' }), url);
    });
  }

  ViewMailConent(mailid: string): any {   
    this.mailHistroyID=mailid;
    this.mailContentArr = this.dataSourcehistory.filter(s => s.mailHistoryID ==mailid);
    this.mailContent = this.mailContentArr[0]["mailBody"];
    this.openDialog(this.mailContent);   
  }
  openDialog(data)
  {  
   const dialogConfig= new MatDialogConfig();
  dialogConfig.disableClose=false;
  dialogConfig.autoFocus=true;
  dialogConfig.width='60rem',
  dialogConfig.maxHeight=500;
  dialogConfig.data={
  dataSourceMoreDetails:data,
  titleHead: 'Sent Mail History'  
  };
  this.dialog.open(MailBodyPopupComponent,dialogConfig);
  }
  

}

