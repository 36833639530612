import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {
  constructor(private _http: HttpClient,
    private _Service: LoginServices,
    @Inject(APP_CONFIG) private config: AppConfig) { }
  ChangePassword(requestmodel): Observable<any> {
    return this._http.post(this.config.ChangePassword, requestmodel).pipe(map((resp: any) => { return resp.result }));
  }
  SetPassword(email: any,password:any): Observable<any> {
    email = this._Service.encryptString(email);
    return this._http.get(this.config.CheckUserAndSendMail+"?userName="+email+"&type=2&password="+password);
  }

  ForgetpasswordGetMail(encEmail:any){
    return this._http.get(this.config.ForgetpasswordGetMail+"?userName="+encEmail).pipe(map((resp: any) => { return resp.result }));;
  }

  CheckUserAndSendMail(email: any): Observable<any> {
    email = this._Service.encryptString(email);
    return this._http.get(this.config.CheckUserAndSendMail+"?userName="+email+"&type=3").pipe(map((resp: any) => { return resp.result }));
  }
}
