<div class="login-wrapper">
    <!-- Header Start here -->
<div class="container">
    <header class="header">
    <div class="top-left">
        <div class="navbar-header">
          <a class="navbar-brand"><img src="../../../assets/img/oias.png" class="img-fluid"></a>
        </div>
      </div>
    </header>
</div>
<hr/>

    <div class="login-body" #para>
        <div class="form-wraper">
            <div class="container form-outer">
                <div class="row">
                    <div class="col">
                        <h1>Publications</h1>
                        <div *ngFor="let data of whatsNewList;">
                            <div class="barmBook">
                                <div class="heading">
                                <h4 class="barmBookHeading" style="font-size: 16px;">
                                    {{data.description}}&nbsp;
                                </h4>
                                <span class="" *ngIf="data.fileName.endsWith('.pdf')">
                                    <i class="fa fa-file-pdf-o" style="color: red;"></i>
                                </span>
                            </div>
                                <div class="clearfix"></div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-info ml-0"
                                        (click)="DownloadPDF(data.fileName)">Download</button>
                                    <button type="button" class="btn btn-info"
                                        (click)="OpenPDFDocument(data.fileName)">Preview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <p>&copy; 2023</p>
      </footer>

</div>
