import { Component, OnInit,Inject,Injectable , Input,TemplateRef, Output, EventEmitter,ViewChild  } from '@angular/core';

import { MatDialog,MatDialogConfig,MatDialogRef, MAT_DIALOG_DATA,MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-mail-body-popup',
  templateUrl: './mail-body-popup.component.html',
  styleUrls: ['./mail-body-popup.component.css']
})
export class MailBodyPopupComponent implements OnInit {
  dataSourcehistory : any[];
  letterNumber:any;
  letterTitle:any;
  mailconent: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
           
    this.mailconent=this.data.dataSourceMoreDetails
  }

}
