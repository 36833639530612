import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: '[blank-space-directive]'
})
export class BlankSpaceDirective {
  @HostListener('keydown', ['$event']) onkeydown(e) {
    if (e.target.value === "") {
      const charCode = (e.which) ? e.which : e.keyCode;
      if (charCode !== 32) {
        return true;
      }
      return false;
    }
  }
}
