<div class="row">
  <div class="col-md-12">
    <div class="card left-brd-pink">
      <div class="card-header">
        <strong class="card-title">{{selectedAudit.name}}</strong>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="roleId==6|| roleId==5 || roleId==10">
      <div class="row" id="contentToConvert">
        <div class="col-md-12">
          <!-- Form 1 Forwarding Letter Details -->
          <div class="card left-brd-pink">
            <div class="card-body card-block">
              <mat-card *ngIf="auditReportForwardingLetterDetails">
                <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
                  <div class="row">
                    <div class="col-md-12" style="text-align: center; font-size: small;">
                      <b style="padding-left: 79%;">{{auditReportName}}</b><br>
                      <b>{{'AuditDH_FinalReport.GovermentofIndia' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.MinistryofFinance' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.DepartmentofExpenditure' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.ControllerGeneralofAccounts' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.InternalAuditDevision' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.MahalekhaNiyantrakBhawan' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.EBlockGPOINA' | translate}}</b><br>
                      <b>{{'AuditDH_FinalReport.NewDelhi' | translate}} – 110023</b><br>
                      <b>{{'AuditDH_FinalReport.EmailId' | translate}}:- {{_msg.iadAdminEmail}}</b><br>
                    </div>
                  </div>
                  <div class="row" style="font-size: small;">
                    <div class="col-md-12" style="word-wrap:break-word">
                      <b>{{'AuditDH_FinalReport.No' | translate}}.</b>
                      <span style="display: inline-block;">{{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterNumber}}</span> 
                      <span style="padding-left: 79%;">{{'AuditDH_FinalReport.Dated'| translate}} {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <!-- <div class="col-md-4">
                        <b>Dated:</b> {{auditReportForwardingLetterDetails[0].letterDate | date: 'dd/MM/yyyy'}}
                      </div> -->
                  </div><br />
                  <div class="row" style="font-size: small;">
                    <div class="col-md-3">
                      <b>{{'AuditDH_FinalReport.To' | translate}},</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-11" style="word-wrap:break-word">
                      <div innerHTML="{{auditReportForwardingLetterDetails.letterTo}}"></div>
                      <b></b>
                    </div>
                  </div>
                  <div class="row" style="font-size: small;">
                    <div class="col-md-2"><b><u>{{'AuditDH_FinalReport.Subject' | translate}}</u>: </b></div>
                    <div class="col-md-10" style="word-wrap:break-word">
                      <div innerHTML="{{auditReportForwardingLetterDetails.letterSubject}}"></div>
                    </div>

                  </div>
                  <div class="row" style="font-size: small;">
                    <div class="col-md-12" style="word-wrap:break-word">
                      <div innerHTML="{{auditReportForwardingLetterDetails.letterContent}}"></div>
                    </div>
                  </div>
                  <div class="row" style="font-size: small;">
                    <div class="col-md-8"></div>
                    <div class="col-md-4" style="word-wrap:break-word">
                      <b>{{'AuditDH_FinalReport.YoursFaithfully' | translate}},</b><br />
                      <div innerHTML="{{auditReportForwardingLetterDetails.letterFrom}}"></div>
                    </div>
                  </div>
                  <div class="row" style="font-size: small;">
                    <div class="col-md-2"><b><u>{{'AuditDH_FinalReport.CopyTo' | translate}}:-</u></b></div><br />
                    <div class="col-md-8" style="text-align: left;">
                      <div innerHTML="{{auditReportForwardingLetterDetails.letterCopyTo}}"></div>
                    </div>
                    <div class="col-md-2">
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <!-- Form 2 Enter Key Components Related to Auditee -->
          <div class="card left-brd-pink">
            <div class="card-body card-block" style="font-size: small;">
              <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <img src="../../../../assets/img/Nationalemblem.png">
                  </div>
                </div><br>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <b>{{'AuditDH_FinalReport.GovermentofIndia' | translate}}</b><br>
                    <b>{{'AuditDH_FinalReport.MinistryofFinance' | translate}}</b> <br>
                    <b>{{'AuditDH_FinalReport.OfficeoftheControllerGeneralofAccounts' | translate}}</b> <br><br>
                    <b>{{'AuditDH_FinalReport.InternalAuditDivision' | translate}}</b> <br><br>
                    <b>{{'AuditDH_FinalReport.NewDelhi' | translate}}</b><br><br>
                    <b>{{'AuditDH_FinalReport.IRNo' | translate}}. {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.auditRow}} /
                      {{selectedAudit.financialYear}} </b><br><br>
                    <b>{{'AuditDH_FinalReport.InspectionReportof' | translate}} </b><br>
                    <b>{{selectedAudit.paoName}}</b><br><br>
                    <b>{{'AuditDH_FinalReport.PeriodofAudit' | translate}}</b><br>
                    <b>({{selectedAudit.currentFinancialYear}})</b><br><br>
                    <b>{{'AuditDH_FinalReport.Inspectionconductedby' | translate}} </b><br>
                    <b>{{selectedauditorteam}}</b><br><br>
                    <b>{{'AuditDH_FinalReport.From' | translate}} {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}}
                      to {{selectedAudit.auditToDate | date:
                      'dd/MM/yyyy'}}</b><br><br>
                    <b>{{'AuditDH_FinalReport.ReportIssuedOn' | translate}} -
                      {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.approvedDate | date: 'dd/MM/yyyy'}}</b><br><br>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <b style="text-align:center;margin-bottom: 1em;">{{'AuditDH_FinalReport.KEYCOMPONENTSRELATEDTOAUDITEE'
                      | translate}}</b>
                  </div>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.StatusofauthorizationduringtheperiodofAudit' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NameofPAO' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.PeriodofPosting' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.KeyActivationPeriod' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.officetelenumber' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.statusofAuthorizationList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.nameofPAO}}</td>
                        <td>{{comp.periodofPosting}}</td>
                        <td>{{comp.keyActivationPeriod}}</td>
                        <td>{{comp.officeTelephoneNumber}}</td>
                      </tr>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.SanctionedstrengthandworkingstrengthoftheofficeasonAuditdate' |
                    translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NameofPost' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.SanctionStrength' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.WorkingStrength' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Vacancies' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.sanctionedStrengthList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.nameofPAO}}</td>
                        <td>{{comp.sanctionStrength}}</td>
                        <td>{{comp.workingStrength}}</td>
                        <td>{{comp.vacancies}}</td>
                      </tr>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.BudgetExpenditureoftheauditeeonlycaseofbudgetoverridden' | translate}} -
                    <span *ngFor="let comp of KeyComponents.budget_ExpenditureList;index as i;">
                      <span *ngIf="comp.budget_Expenditure == ''"> N/A</span>
                      <span *ngIf="comp.budget_Expenditure != ''"> {{comp.budget_Expenditure}}</span>
                    </span>
                  </b>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.CountofNCDDOsCDDOsDDOsoftheAuditee' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NumberofNCDDOs' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NumberofCDDOs' | translate}}:</th>
                        <th>{{'AuditDH_FinalReport.NumberofDDOs' | translate}}:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.ncddOs_CDDOs_DDOsList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.numberofNCDDOs}}</td>
                        <td>{{comp.numberofCDDOs}}</td>
                        <td>{{comp.numberofDDOs}}</td>
                      </tr>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.StatusofoutstandingCGAAuditPara' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.outstandingCGAAuditParasList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.numberofParas}}</td>
                        <td>{{comp.paraSettled}}</td>
                        <td>{{comp.paraOutstanding}}</td>
                        <td>{{comp.remarks}}</td>
                      </tr>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.StatusofoutstandingCAGAuditPara' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.outstandingCAGAuditParasList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.numberofParas}}</td>
                        <td>{{comp.paraSettled}}</td>
                        <td>{{comp.paraOutstanding}}</td>
                        <td>{{comp.remarks}}</td>
                      </tr>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>{{'AuditDH_FinalReport.StatusofoutstandingInternalAuditPara' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of KeyComponents.outstandingInternalAuditParasList;index as i; ">
                        <td>{{i+1}}</td>
                        <td>{{comp.numberofParas}}</td>
                        <td>{{comp.paraSettled}}</td>
                        <td>{{comp.paraOutstanding}}</td>
                        <td>{{comp.remarks}}</td>
                      </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Form 3 Executive Summary -->
          <div class="card left-brd-pink">
            <div class="card-body card-block">
              <div class="row">
                <div class="col-md-12" style="text-align: center;font-size: small;">
                  <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
                    <span style="text-align: center;font-weight: bold;"> {{'AuditDH_FinalReport.ExecutiveSummary' |
                      translate}}:</span> <br><br>
                    <div innerHTML="{{executiveSummery}}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form 4 Detailed Risk Assessments Findings -->
          <div class="card left-brd-pink">
            <div class="card-body card-block" style="font-size: small;">
              <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
                <div class="row" style="margin-top: 2%; text-align: center;">
                  <div class="col-md-12" style="text-align: center;">
                    <b>{{'AuditDH_FinalReport.OVERVIEWOFRISKASSESSMENTOF' | translate}} {{selectedAudit.paoName}} </b>
                  </div>
                  <div class="col-md-12" style="margin-top: 2%;">
                    <table>
                      <tr>
                        <td>{{'AuditDH_FinalReport.SNo' | translate}}.</td>
                        <td>{{'AuditDH_FinalReport.CATEGORYOFRISK' | translate}}</td>
                        <td>{{'AuditDH_FinalReport.RISKRATING' | translate}}</td>
                        <td>{{'AuditDH_FinalReport.COLOURCODING' | translate}}</td>
                        <td>{{'AuditDH_FinalReport.Marks' | translate}}</td>
                      </tr>
                      <tr *ngFor="let comp of Overall;index as i; ">
                        <td>{{i+1}}</td>
                        <td>
                          <span *ngIf="comp.riskId == 1"> {{'AuditDH_FinalReport.FinancialRisk' | translate}} </span>
                          <span *ngIf="comp.riskId == 2"> {{'AuditDH_FinalReport.AcountingRisk' | translate}} </span>
                          <span *ngIf="comp.riskId == 3"> {{'AuditDH_FinalReport.ServiceDeliveryRisk' | translate}}
                          </span>
                          <span *ngIf="comp.riskId == 4"> {{'AuditDH_FinalReport.SystemRisk' | translate}} </span>
                          <span *ngIf="comp.riskId == 5"> {{'AuditDH_FinalReport.DocumentationRisk' | translate}}
                          </span>
                          <span *ngIf="comp.riskId == 6"> {{'AuditDH_FinalReport.EstablishmentRisk' | translate}}
                          </span>
                          <span *ngIf="comp.riskId == 7"> {{'AuditDH_FinalReport.OtherRisk' | translate}} </span>

                        </td>
                        <td>
                          {{comp.value}}
                        </td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-12" style="margin-top: 2%;">
                    <div style="word-wrap:break-word">
                      <div class="col-md-2"
                        style="background-color: red;height: 2.3em;width: 2%;float: left;margin-right: 2%;">
                      </div>
                      <div class="col-md-10" style="text-align: left;">
                        {{'AuditDH_FinalReport.HighRiskAreathatmustbeaddressedtoimmediately' | translate}}.</div>

                    </div>
                  </div>

                  <div class="col-md-12" style="margin-top: 2%;">
                    <div class="col-md-2"
                      style="background-color:yellow;height: 2.3em;width: 2%;float: left;margin-right: 2%;"></div>
                    <div class="col-md-6" style="text-align: left;">
                      {{'AuditDH_FinalReport.Amoderateriskareathatrequirestobeaddressedwithgreatercontrolssupervison' |
                      translate}}.</div>
                    <div class="col-md-4"></div>
                  </div>
                  <div class="col-md-12" style="margin-top: 2%;">
                    <div class="col-md-2"
                      style="background-color:green;height: 2.3em;width: 2%;float: left;margin-right: 2%;"></div>
                    <div class="col-md-6" style="text-align: left;">
                      {{'AuditDH_FinalReport.Alowriskareawhichshouldbemaintainedwithadequatecontrols' | translate}}.
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
                <div style="text-align: center;margin-top: 2%;">
                  <strong class="card-title">{{'AuditDH_FinalReport.ObservationsoftheAuditParty' | translate}} </strong>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.ParaNumber' | translate}}</th>
                        <th style="width:90%">{{'AuditDH_FinalReport.ParaTitle' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let para of auditParaList;index as i; ">
                        <td>{{para.paraNumber}}</td>
                        <td>{{para.title}}</td>
                      </tr>
                  </table>
                </div>

                <div style="text-align: center;margin-top: 2%;">
                  <strong>{{'AuditDH_FinalReport.PART' | translate}} 1</strong> <br>
                  <strong class="card-title">{{'AuditDH_FinalReport.DETAILEDRISKASSESSMENTFINDINGS' | translate}}
                  </strong>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>1.{{'AuditDH_FinalReport.FinancialRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.financialRisk;index as i; ">
                        <td>1.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>2.{{'AuditDH_FinalReport.AcountingRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.accounting;index as i; ">
                        <td>2.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>3.{{'AuditDH_FinalReport.ServiceDeliveryRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.serviceDelivery;index as i; ">
                        <td>3.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>4. {{'AuditDH_FinalReport.DocumentationRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.documentation;index as i; ">
                        <td>4.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>5. {{'AuditDH_FinalReport.EstablishmentRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.establishment;index as i; ">
                        <td>5.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>6. {{'AuditDH_FinalReport.SystemRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.system;index as i; ">
                        <td>6.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <b>7. {{'AuditDH_FinalReport.OtherRisk' | translate}}</b>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                        <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                        <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                        <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comp of Form4Component.other;index as i; ">
                        <td>7.{{i+1}}</td>
                        <td>{{comp.aspectValue}}</td>
                        <td style="max-width: 15%;"
                          [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                          <span *ngIf="comp.colorValue == ''">N/A</span>
                        </td>
                        <td style="max-width: 15%;">
                          {{comp.marks}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="text-align: center;margin-top: 2%;">
                  <strong>{{'AuditDH_FinalReport.PART' | translate}} 2</strong> <br>
                  <strong class="card-title">{{'AuditDH_FinalReport.DETAILEDREPORT' | translate}}</strong>
                </div>
                <div style="text-align: center;margin-top: 2%;">
                  <strong>{{'AuditDH_FinalReport.Theinspectionof' | translate}} {{selectedAudit.paoName}},
                    {{'AuditDH_FinalReport.hasbeenconductedforthefinancialyear' | translate}}
                    {{selectedAudit.financialYear}} {{'AuditDH_FinalReport.duringtheperiodfrom' | translate}}
                    {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} {{'AuditDH_FinalReport.to' | translate}}
                    {{selectedAudit.auditToDate | date:
                    'dd/MM/yyyy'}}
                    . {{'AuditDH_FinalReport.Thedetailedobservationsoftheinspectionareasunder' | translate}}: -
                  </strong>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <!--------------------------------------------------------------->
                  <div style="width:100%;margin: 1%;" *ngFor="let details of auditParaList;index as i;">
                    <div>
                      <div><strong>{{'AuditDH_FinalReport.ParaIdNumber' | translate}} : </strong>
                        <label>{{details.paraNumber}}</label>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.ParaTitle' | translate}} : </strong>
                        <label>{{details.title}}</label>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.Criteria' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.criteria}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.Conditions' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.conditions}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.Consequences' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.consequences}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.CausesRoleposition' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.causes}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.CorrectiveActions' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.correctiveActions}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.Details' | translate}} : </strong>
                        <div class="conference-background-color" innerHTML="{{details.details}}"></div>
                      </div>
                      <div><strong>{{'AuditDH_FinalReport.Severity' | translate}} : </strong>
                        <div class="conference-background-color"><label>{{details.severity}}</label></div>
                      </div>
                      <div>
                        <strong>{{'AuditDH_FinalReport.RiskCategory' | translate}} :</strong>
                        <div class="conference-background-color">
                          <label>{{details.categoryName}}</label>
                        </div>
                      </div>
                      <div>
                        <strong>{{'AuditDH_FinalReport.RiskCategoryDescription' | translate}}: </strong>
                        <div class="conference-background-color"> <label>{{details.riskCategoryDesc}}</label>
                        </div>
                      </div>
                      <div>
                        <strong>{{'AuditDH_FinalReport.AuditMemo' | translate}}: </strong>
                        <div class="conference-background-color"> <label>{{details.memoNumber}}</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="details.finResponse.length > 0">
                      <strong>{{'AuditDH_FinalReport.FinancialImplicationsDetails' | translate}} : </strong>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              {{'AuditDH_FinalReport.FinancialImplicationCase' | translate}}
                            </th>
                            <th>
                              {{'AuditDH_FinalReport.Amount' | translate}}
                            </th>
                            <th>
                              {{'AuditDH_FinalReport.Remarks' | translate}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let impli of details.finResponse">
                            <td>{{impli.financialImplicationCase}}</td>
                            <td>{{impli.amount}}</td>
                            <td>{{impli.remarks}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-------------------------------------------------------------->
                </div>
              </div>
            </div>
          </div>
          <div class="card left-brd-pink" *ngIf="saveButtonDisabled==false">
            <div class="card-body card-block" style="text-align: center;">
              <button class="btn btn-info btn-width" type="button" [disabled]="saveButtonDisabled"
                (click)="AuditReportSave();">{{'AuditDH_FinalReport.Submit' | translate}}</button>

            </div>
          </div>
        </div>

      </div>
</div>

<div class="row" id="print-section" style="display: none;">
  <div class="col-md-12">
    <!-- Form 1 Forwarding Letter Details -->
     <div class="card left-brd-white">
      <div class="card-body card-block">
        <mat-card *ngIf="auditReportForwardingLetterDetails">
          <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
            <div class="row">
              <div class="col-md-12" style="text-align: center; font-size: small;">
                <b style="padding-left: 76%;">{{auditReportName}}</b><br>
                <b>{{'AuditDH_FinalReport.GovermentofIndia' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.MinistryofFinance' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.DepartmentofExpenditure' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.ControllerGeneralofAccounts' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.InternalAuditDevision' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.MahalekhaNiyantrakBhawan' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.EBlockGPOINA' | translate}}</b><br>
                <b>{{'AuditDH_FinalReport.NewDelhi' | translate}} – 110023</b><br>
                <b>{{'AuditDH_FinalReport.EmailId' | translate}}:- {{_msg.iadAdminEmail}}</b><br>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <b>{{'AuditDH_FinalReport.No' | translate}}.</b>
                <span style="display: inline-block;">{{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterNumber}}</span> 
                <span style="padding-left: 75%;display: inline-block;">{{'AuditDH_FinalReport.Dated'| translate}} {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.letterDate | date: 'dd/MM/yyyy'}}</span>
              </div>
            </div><br />
            <div class="row" style="font-size: small;">
              <div class="col-md-3">
                <b>{{'AuditDH_FinalReport.To' | translate}},</b>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterTo}}"></div>
                <b></b>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>{{'AuditDH_FinalReport.Subject' | translate}}</u>: </b></div>
              <div class="col-md-10" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterSubject}}"></div>
              </div>

            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-12" style="word-wrap:break-word">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterContent}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-8"></div>
              <div class="col-md-4" style="word-wrap:break-word">
                <b>{{'AuditDH_FinalReport.YoursFaithfully' | translate}},</b><br />
                <div innerHTML="{{auditReportForwardingLetterDetails.letterFrom}}"></div>
              </div>
            </div>
            <div class="row" style="font-size: small;">
              <div class="col-md-2"><b><u>{{'AuditDH_FinalReport.CopyTo' | translate}}:-</u></b></div><br />
              <div class="col-md-8" style="text-align: left;">
                <div innerHTML="{{auditReportForwardingLetterDetails.letterCopyTo}}"></div>
              </div>
              <div class="col-md-2">
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div> 
    <!-- Form 2 Enter Key Components Related to Auditee -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <img src="../../../../assets/img/Nationalemblem.png">
            </div>
          </div><br>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b>{{'AuditDH_FinalReport.GovermentofIndia' | translate}}</b><br>
              <b>{{'AuditDH_FinalReport.MinistryofFinance' | translate}}</b> <br>
              <b>{{'AuditDH_FinalReport.OfficeoftheControllerGeneralofAccounts' | translate}}</b> <br><br>
              <b>{{'AuditDH_FinalReport.InternalAuditDivision' | translate}}</b> <br><br>
              <b>{{'AuditDH_FinalReport.NewDelhi' | translate}}</b><br><br>
              <b>{{'AuditDH_FinalReport.IRNo' | translate}}. {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.auditRow}} /
                {{selectedAudit.financialYear}} </b><br><br>
              <b>{{'AuditDH_FinalReport.InspectionReportof' | translate}} </b><br>
              <b>{{selectedAudit.paoName}}</b><br><br>
              <b>{{'AuditDH_FinalReport.PeriodofAudit' | translate}}</b><br>
              <b>({{selectedAudit.currentFinancialYear}})</b><br><br>
              <b>{{'AuditDH_FinalReport.Inspectionconductedby' | translate}} </b><br>
              <b>{{selectedauditorteam}}</b><br><br>
              <b>{{'AuditDH_FinalReport.From' | translate}} {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} to
                {{selectedAudit.auditToDate | date:
                'dd/MM/yyyy'}}</b><br><br>
              <b>{{'AuditDH_FinalReport.ReportIssuedOn' | translate}} -
                {{auditReportForwardingLetterDetails && auditReportForwardingLetterDetails.approvedDate
                | date: 'dd/MM/yyyy'}}</b><br><br>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <b style="text-align:center;margin-bottom: 1em;">{{'AuditDH_FinalReport.KEYCOMPONENTSRELATEDTOAUDITEE' |
                translate}}</b>
            </div>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.StatusofauthorizationduringtheperiodofAudit' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NameofPAO' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.PeriodofPosting' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.KeyActivationPeriod' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.officetelenumber' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.statusofAuthorizationList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.periodofPosting}}</td>
                  <td>{{comp.keyActivationPeriod}}</td>
                  <td>{{comp.officeTelephoneNumber}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.SanctionedstrengthandworkingstrengthoftheofficeasonAuditdate' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NameofPost' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.SanctionStrength' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.WorkingStrength' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.vacancies' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.sanctionedStrengthList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.nameofPAO}}</td>
                  <td>{{comp.sanctionStrength}}</td>
                  <td>{{comp.workingStrength}}</td>
                  <td>{{comp.vacancies}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.BudgetExpenditureoftheauditeeonlycaseofbudgetoverridden' | translate}} -
              <span *ngFor="let comp of KeyComponents.budget_ExpenditureList;index as i;">
                <span *ngIf="comp.budget_Expenditure == ''"> N/A</span>
                <span *ngIf="comp.budget_Expenditure != ''"> {{comp.budget_Expenditure}}</span>
              </span>
            </b>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.CountofNCDDOsCDDOsDDOsoftheAuditee' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NumberofNCDDOs' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NumberofCDDOs' | translate}}:</th>
                  <th>{{'AuditDH_FinalReport.NumberofDDOs' | translate}}:</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.ncddOs_CDDOs_DDOsList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofNCDDOs}}</td>
                  <td>{{comp.numberofCDDOs}}</td>
                  <td>{{comp.numberofDDOs}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>Status of outstanding CGA Audit Para</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCGAAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.StatusofoutstandingCAGAuditPara' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingCAGAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>

          <div class="row" style="margin-top: 2%;">
            <b>{{'AuditDH_FinalReport.StatusofoutstandingInternalAuditPara' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th>{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.NumberofParas' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaSettled' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.ParaOutstanding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Remarks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of KeyComponents.outstandingInternalAuditParasList;index as i; ">
                  <td>{{i+1}}</td>
                  <td>{{comp.numberofParas}}</td>
                  <td>{{comp.paraSettled}}</td>
                  <td>{{comp.paraOutstanding}}</td>
                  <td>{{comp.remarks}}</td>
                </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 3 Executive Summary -->
    <div class="card left-brd-white">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-md-12" style="text-align: center;font-size: small;">
            <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
              <span style="text-align: center;font-weight: bold;"> {{'AuditDH_FinalReport.ExecutiveSummary' |
                translate}}:</span> <br><br>
              <div innerHTML="{{executiveSummery}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Form 4 Detailed Risk Assessments Findings -->
    <div class="card left-brd-white">
      <div class="card-body card-block" style="font-size: small;">
        <div class="jumbotron" [ngxWatermark]="options" style="background-color: white;">
          <div class="row" style="margin-top: 2%; text-align: center;">
            <div class="col-md-12" style="text-align: center;">
              <b>{{'AuditDH_FinalReport.OVERVIEWOFRISKASSESSMENTOF' | translate}} {{selectedAudit.paoName}} </b>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <table>
                <tr>
                  <td>{{'AuditDH_FinalReport.SNo' | translate}}.</td>
                  <td>{{'AuditDH_FinalReport.CATEGORYOFRISK' | translate}}</td>
                  <td>{{'AuditDH_FinalReport.RISKRATING' | translate}}</td>
                  <td>{{'AuditDH_FinalReport.COLOURCODING' | translate}}</td>
                  <td>{{'AuditDH_FinalReport.Marks' | translate}}</td>
                </tr>
                <tr *ngFor="let comp of Overall;index as i; ">
                  <td>{{i+1}}</td>
                  <td>
                    <span *ngIf="comp.riskId == 1"> {{'AuditDH_FinalReport.FinancialRisk' | translate}} </span>
                    <span *ngIf="comp.riskId == 2"> {{'AuditDH_FinalReport.AcountingRisk' | translate}} </span>
                    <span *ngIf="comp.riskId == 3"> {{'AuditDH_FinalReport.ServiceDeliveryRisk' | translate}} </span>
                    <span *ngIf="comp.riskId == 4"> {{'AuditDH_FinalReport.SystemRisk' | translate}} </span>
                    <span *ngIf="comp.riskId == 5"> {{'AuditDH_FinalReport.Documentation Risk' | translate}} </span>
                    <span *ngIf="comp.riskId == 6"> {{'AuditDH_FinalReport.EstablishmentRisk' | translate}} </span>
                    <span *ngIf="comp.riskId == 7"> {{'AuditDH_FinalReport.OtherRisk' | translate}} </span>

                  </td>
                  <td>
                    {{comp.value}}
                  </td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div style="word-wrap:break-word">
                <div class="col-md-2"
                  style="background-color: red;height: 30px;width: 2.3em;margin-right: 2%;float: left;">
                </div>
                <div class="col-md-10" style="text-align: left;">
                  {{'AuditDH_FinalReport.HighRiskAreathatmustbeaddressedtoimmediately' | translate}}.</div>

              </div>
            </div>

            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: yellow;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;">
                {{'AuditDH_FinalReport.Amoderateriskareathatrequirestobeaddressedwithgreatercontrolssupervison' |
                translate}}.</div>
              <div class="col-md-4"></div>
            </div>
            <div class="col-md-12" style="margin-top: 2%;">
              <div class="col-md-2"
                style="background-color: green;height: 30px;width: 2.3em;margin-right: 2%;float: left;"></div>
              <div class="col-md-6" style="text-align: left;">
                {{'AuditDH_FinalReport.Alowriskareawhichshouldbemaintainedwithadequatecontrols' | translate}}.</div>
              <div class="col-md-4"></div>
            </div>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong class="card-title">{{'AuditDH_FinalReport.ObservationsoftheAuditParty' | translate}} </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.ParaNumber' | translate}}</th>
                  <th style="width:90%">{{'AuditDH_FinalReport.ParaTitle' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let para of auditParaList;index as i; ">
                  <td>{{para.paraId}}</td>
                  <td>{{para.title}}</td>
                </tr>
            </table>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>{{'AuditDH_FinalReport.PART' | translate}} 1</strong> <br>
            <strong class="card-title">{{'AuditDH_FinalReport.DETAILEDRISKASSESSMENTFINDINGS' | translate}} </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>1.{{'AuditDH_FinalReport.FinancialRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.Color Coding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.financialRisk;index as i; ">
                  <td>1.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>2.{{'AuditDH_FinalReport.AcountingRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.accounting;index as i; ">
                  <td>2.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>3.{{'AuditDH_FinalReport.ServiceDeliveryRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.serviceDelivery;index as i; ">
                  <td>3.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>4. {{'AuditDH_FinalReport.DocumentationRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.documentation;index as i; ">
                  <td>4.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>5. {{'AuditDH_FinalReport.EstablishmentRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.establishment;index as i; ">
                  <td>5.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 2%;">
            <b>6. {{'AuditDH_FinalReport.SystemRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.system;index as i; ">
                  <td>6.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <br>
          <div class="row" style="margin-top: 2%;">
            <b>7. {{'AuditDH_FinalReport.OtherRisk' | translate}}</b>
            <table>
              <thead>
                <tr>
                  <th style="width:10%">{{'AuditDH_FinalReport.SNo' | translate}}</th>
                  <th style="width:70%">{{'AuditDH_FinalReport.Aspects' | translate}}</th>
                  <th style="width:20%">{{'AuditDH_FinalReport.ColorCoding' | translate}}</th>
                  <th>{{'AuditDH_FinalReport.Marks' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comp of Form4Component.other;index as i; ">
                  <td>7.{{i+1}}</td>
                  <td>{{comp.aspectValue}}</td>
                  <td style="max-width: 15%;"
                    [ngClass]="{red: comp.colorValue == 'red', yellow: comp.colorValue == 'yellow',green: comp.colorValue == 'green'}">
                    <span *ngIf="comp.colorValue == ''">N/A</span>
                  </td>
                  <td style="max-width: 15%;">
                    {{comp.marks}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>{{'AuditDH_FinalReport.PART' | translate}} 2</strong> <br>
            <strong class="card-title">{{'AuditDH_FinalReport.DETAILEDREPORT' | translate}}</strong>
          </div>
          <div style="text-align: center;margin-top: 2%;">
            <strong>{{'AuditDH_FinalReport.Theinspectionof' | translate}} {{selectedAudit.paoName}},
              {{'AuditDH_FinalReport.hasbeenconductedforthefinancialyear' | translate}} {{selectedAudit.financialYear}}
              {{'AuditDH_FinalReport.duringtheperiodfrom' | translate}}
              {{selectedAudit.auditFromDate | date: 'dd/MM/yyyy'}} {{'AuditDH_FinalReport.to' | translate}}
              {{selectedAudit.auditToDate | date:
              'dd/MM/yyyy'}}
              . {{'AuditDH_FinalReport.Thedetailedobservationsoftheinspectionareasunder' | translate}}: -
            </strong>
          </div>
          <div class="row" style="margin-top: 2%;">
            <!--------------------------------------------------------------->
            <div style="width:100%;margin: 1%;" *ngFor="let details of auditParaList;index as i;">
              <div>
                <div><strong>{{'AuditDH_FinalReport.ParaIdNumber' | translate}} : </strong>
                  <label>{{details.paraNumber}}</label>
                </div>
                <div><strong>{{'AuditDH_FinalReport.ParaTitle' | translate}} : </strong>
                  <label>{{details.title}}</label>
                </div>
                <div><strong>{{'AuditDH_FinalReport.Criteria' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.criteria}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.Conditions' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.conditions}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.Consequences' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.consequences}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.CausesRoleposition' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.causes}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.CorrectiveActions' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.correctiveActions}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.Details' | translate}} : </strong>
                  <div class="conference-background-color" innerHTML="{{details.details}}"></div>
                </div>
                <div><strong>{{'AuditDH_FinalReport.Severity' | translate}} : </strong>
                  <div class="conference-background-color"><label>{{details.severity}}</label></div>
                </div>
                <div>
                  <strong>{{'AuditDH_FinalReport.RiskCategory' | translate}} :</strong>
                  <div class="conference-background-color">
                    <label>{{details.categoryName}}</label>
                  </div>
                </div>
                <div>
                  <strong>{{'AuditDH_FinalReport.RiskCategoryDescription' | translate}}: </strong>
                  <div class="conference-background-color"> <label>{{details.riskCategoryDesc}}</label>
                  </div>
                </div>
                <div>
                  <strong>{{'AuditDH_FinalReport.AuditMemo' | translate}}: </strong>
                  <div class="conference-background-color"> <label>{{details.memoNumber}}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="details.finResponse.length > 0">
                <strong>{{'AuditDH_FinalReport.FinancialImplicationsDetails' | translate}} : </strong>
                <table>
                  <thead>
                    <tr>
                      <th>
                        {{'AuditDH_FinalReport.FinancialImplicationCase' | translate}}
                      </th>
                      <th>
                        {{'AuditDH_FinalReport.Amount' | translate}}
                      </th>
                      <th>
                        {{'AuditDH_FinalReport.Remarks' | translate}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let impli of details.finResponse">
                      <td>{{impli.financialImplicationCase}}</td>
                      <td>{{impli.amount}}</td>
                      <td>{{impli.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-------------------------------------------------------------->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dialog [(visible)]="RejectPopUp">
  <div class="card-content panel panel-body panel-default">
    <h3 class="card-title text-center">{{'AuditDH_FinalReport.DoyouwanttoRejectthisDraftAuditReport' | translate}}?</h3>
    <mat-form-field class="wid-100">
      <textarea matInput placeholder="Return Remark" [(ngModel)]="rejectionRemark" maxlength="200"
        (keypress)="omit_special_char($event)"></textarea>
    </mat-form-field>
    <div class="form-group text-center col-md-12">
      <button type="button" class="btn btn-info" (click)="forward(5)">{{'AuditDH_FinalReport.Yes' | translate}}</button>
      &nbsp;
      <button type="button" class="btn btn-secondary" (click)="RejectPopUp = false">{{'AuditDH_FinalReport.Cancel' |
        translate}}</button>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="ForwardToSupervisorPopUp">
  <div class="card-content panel panel-body panel-default">
    <h4 class="card-title text-center">{{'AuditDH_FinalReport.PleaseSelectaSupervisor' | translate}} </h4>
    <!-- Large input -->
    <div class="row text-center">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="md-form form-lg">
          <mat-form-field class="w-75" style="margin-top:-100px">
            <mat-select matNativeControl placeholder="Select Reviewer" [(ngModel)]="selectedSupervisor">
              <mat-option *ngFor="let supervisor of arrSupervisor" [value]="supervisor.roleUserMapID">
                {{supervisor.empName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <button type="button" class="btn btn-info" (click)="forward(6);Cancel();">{{'AuditDH_FinalReport.Yes' |
          translate}}</button>        
        <button type="button" class="btn btn-secondary"
          (click)="Cancel();ForwardToSupervisorPopUp = !ForwardToSupervisorPopUp">{{'AuditDH_FinalReport.Cancel' |
          translate}}</button>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="RejectPopUpbyAAO">
  <div class="card-content panel panel-body panel-default">
    <h3 class="card-title text-center">{{'AuditDH_FinalReport.DoyouwanttoRejectthisDraftAuditReport' | translate}}?</h3>
    <mat-form-field class="wid-100">
      <textarea matInput placeholder="Return Remark" [(ngModel)]="rejectionRemark" maxlength="200"
        (keypress)="omit_special_char($event)"></textarea>
    </mat-form-field>
    <div class="form-group text-center col-md-12">
      <button type="button" class="btn btn-info" (click)="forward(9)">{{'AuditDH_FinalReport.Yes' | translate}}</button>
      &nbsp;
      <button type="button" class="btn btn-secondary" (click)="RejectPopUpbyAAO = false">{{'AuditDH_FinalReport.Cancel'
        | translate}}</button>
    </div>
  </div>
</app-dialog>
