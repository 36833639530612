<main>
  <div class="header-top">
    <marquee behavior=scroll direction="left" scrollamount="5" onmouseover="this.stop();" onmouseout="this.start();">
      <ng-container *ngFor="let data of whatsNewList;let i=index">
        <a (click)="OpenPDFDocument(data.fileName)"><span class="marqueetext"
            [ngClass]="{'marqueetextborder' : whatsNewList.length-1!=i}">{{data.description}}</span></a>
      </ng-container>

    </marquee>
    <ng-container>
      <a (click)="ViewNewUpdates()" style="width: 200px;">More Updates...</a>
    </ng-container>
  </div>
  <div class="container-fluid">
    <div class="login-wrapper">
      <!-- <section class="header_middle">
  <a title="Azadi Ka Amrit Mahotsav" href="https://rashtragaan.in/" onclick="javascript:return confirm( 'External website that opens in new window. Click OK to continue.Click Cancel to stop');" target="_blank" >
    <img src="../../assets/img/azadikamahotsavanthem.jpg" style="width: 100%;height: 250px;cursor: pointer;position: relative">
  </a>
</section> -->

      <!-- Header End here -->
      <!-- Form Star here -->
      <div class="login-body" #para>
        <div class="row">
          <div class="col-lg-12">
            <img src="~/../assets/img/logo-main.png" class="img-fluid cga-logo" />
          </div>
          <div class="col-lg-5">


            <form [formGroup]="loginForm" (ngSubmit)="RedirecToHome(formDirective)" class="login__form"
              #formDirective="ngForm">
              <div class="row">
                <div class="col-lg-12 text-center mb-4">
                  <img src="~/../assets/img/iAD.png" class="img-fluid form-logo" />
                </div>
                <div class="col-lg-12">
                  <div class="input-group">
                    <mat-form-field class="wid-100">
                      <input matInput placeholder="Enter Username" formControlName="userName" name="username"
                        autocomplete="off">
                      <i class="fa fa-user" style="position: absolute; right: 5px;" [ngClass]="'myClass'"
                        (click)="showPassword()"></i>
                      <mat-error>Username Required!</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-group">
                    <mat-form-field class="wid-100">
                      <input [type]="show_button ? 'text' : 'password'" matInput placeholder="Enter Password"
                        formControlName="password" name="pswd" autocomplete="off">
                      <i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" style="position: absolute; right: 5px;"
                        [ngClass]="'myClass'" (click)="showPassword()"></i>
                    </mat-form-field>

                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="captcha-section">

                    <div style="margin-top: 10px;" id="captcha">
                    </div>

                    <div class="captacha-image">
                      <i (click)="randomString()" title="Refresh Captcha" class="material-icons"
                        style="margin-top: 11px;">refresh</i>
                    </div>

                  </div>
                </div>
                <div class="col-lg-5">
                  <mat-form-field class="wid-100 enter-captcha" style="margin-top: 10px;">
                    <input matInput type="text" class="form-control" placeholder="Enter Captcha..." name="captcha"
                      formControlName="captchaCode" autocomplete="off">
                    <mat-error>Captcha Required!</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="btn-wraper">
                    <button type="submit" class="btn btn-info login-btn" style="margin-left:0px"
                      [disabled]="!loginForm.valid">LOGIN HERE</button>

                  </div>
                  <div style="text-align: end;">
                    <a class="forget-pwd" (click)="openPopUpForgetPassword()">Forgot
                      Password?</a>
                  </div>
                </div>
              </div>
              <div class="person">
                <img src="~/../assets/img/oias.png" class="img-fluid" />
              </div>


            </form>

          </div>
          <div class="col-lg-6">
          </div>
        </div>
      </div>
      <!-- Form End here -->
      <!-- Footer Star here -->
      <!-- <div class="footer-wraper">
  <section class="footer-2">
    <div>
      <p style="float: left;color: #fff;font-size: 13px;margin: 0%;padding: 10px;">
        &copy; CGA 2020
      </p>
    </div>
    <p class="text-center" style="color: #fff;">
      This web application belongs to Controller General of Accounts, Ministry of Finance, Government of India.
    </p>
  </section>
</div> -->
    </div>
  </div>
  <footer>
    <p>&copy; 2023</p>
  </footer>
</main>
<!-- Footer Starts Here -->
<!-- Footer End here -->

<app-dialog [(visible)]="CheckLoginRole" class="loginRole-modal">
  <div class="card-content panel panel-body panel-default text-center">
    <button type="button" class="close cross" data-dismiss="modal" (click)="closeRoleModal()"></button>
    <div class="row">

      <!-- <div class="col-md-4">
        <div class="md-form form-lg">
          <mat-form-field class="wid-100" style="margin-top:-100px">
            <mat-select matNativeControl placeholder="Select Department" [(ngModel)]="deptId"> -->
      <!--<mat-option label="Select Role">Select Department</mat-option>-->
      <!-- <mat-option value="IAD">IAD</mat-option> -->
      <!--<mat-option value="IAW">IAW</mat-option>-->
      <!-- </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <div class="col-md-12">
        <div class="md-form form-lg" *ngIf="loginResponse">
          <mat-form-field class="wid-100" style="margin-top:-100px">
            <mat-select matNativeControl placeholder="Select Role" [(ngModel)]="selectedRoleUserMapId">
              <mat-option *ngFor="let roleData of loginResponse.result"
                [value]="roleData.roleUserMapId">{{roleData.roleName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button type="button" class="btn btn-info text-center btn-sm " [disabled]="!selectedRoleUserMapId"
          (click)="NavigateToDashboard()">GO</button>
      </div>
    </div>
  </div>
</app-dialog>


<app-dialog [(visible)]="ForgetpasswordPopUp" (visibleChange)="captchaReset();" class="forgetPwd-modal">

  <div class="card-content panel panel-body panel-default">
    <h4 class="card-title text-center mt-3">Forget Password? </h4>
    <button type="button" class="close cross" data-dismiss="modal" (click)="closeRoleModal()"></button>
    <div class="row text-center">
      <div class="col-md-12">
        <div class="md-form form-lg">
          <mat-form-field class="w-100">
            <input matInput placeholder="Enter Email" [(ngModel)]="userEmail" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="captcha-section" style="float: right">
          <div id="captchaReset">
          </div>

          <div class="captacha-image">
            <i (click)="captchaReset()" title="Refresh Captcha" class="material-icons">refresh</i>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-left">
        <mat-form-field class="enter-captcha w-100">
          <input matInput type="text" class="form-control" placeholder="Enter Captcha..." name="captchaReset"
            [(ngModel)]="captchaPassControl" autocomplete="off">
          <mat-error>Captcha Required!</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12 text-center mt-3">
        <button type="button" class="btn btn-info text-center btn-sm" (click)="checkUser()">GO</button>
      </div>
    </div>
  </div>
</app-dialog>
