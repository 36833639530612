import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../global/global.module';
import { Observable } from 'rxjs';
import { Session } from 'protractor';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'
@Injectable()

export class LoginServices {
  BaseUrl: any = [];
  headers: any;
  constructor(private httpclient: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods':'GET, POST',
        'Access-Control-Allow-Credentials':'true',
        'Access-Control-Max-Age':'362880'
      }) 
    }
  } 


  Login(loginModel: any): Observable<any> {  
    return this.httpclient.post(this.config.login, loginModel, this.headers);
  }

  CheckUserAndSendMail(email: any): Observable<any> {
    email = this.encryptString(email);
    return this.httpclient.get(this.config.CheckUserAndSendMail+"?userName="+email+"&type=1", this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  getWhatsNewList(): Observable<any> {
    return this.httpclient.get(this.config.GetLatestWhatsNewEntry, 
      {}).pipe(map((resp: any) => { return resp }));
  }
  ViewPDFDocument(url: string) {
    url = this.encryptString(url);
    return this.httpclient.get(this.config.api_base_url + 
      'Login/DownloadFile?url=' + url, { responseType: 'arraybuffer' }).pipe(map((resp: any) => { return resp }));
  }
  encryptString(value)
  {
   var key = CryptoJS.enc.Utf8.parse('SPA865HYEMEW237K');
   var encryptedstring = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key,
     {
       keySize: 128 / 8,
       iv: key,
       mode: CryptoJS.mode.CBC,
       padding: CryptoJS.pad.Pkcs7
     });
 return encryptedstring;
  }

  encryptPost(value)
  { if(value == "" || value == undefined )
    return value
    else
    {
      var data = this.encryptString(value).toString();
      return data;
    }
    
  }

  decryptString(value)
  {    
   var key = CryptoJS.enc.Utf8.parse('SPA865HYEMEW237K');
   var encryptedstring = CryptoJS.AES.decrypt(value, key,
     {
       keySize: 128 / 8,
       iv: key,
       mode: CryptoJS.mode.CBC,
       padding: CryptoJS.pad.Pkcs7
     });
 return  encryptedstring.toString(CryptoJS.enc.Utf8);
  }

}

