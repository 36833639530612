import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { MaterialModule } from '../material.module';
 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BlankSpaceDirective } from './blank-space-textbox.directive';
import { HistoryPopupComponent } from './history-popup/history-popup.component';

import { MailHistoryPopupComponent } from './mail-history-popup/mail-history-popup.component';
import { MailBodyPopupComponent } from './mail-body-popup/mail-body-popup.component';

@NgModule({
  declarations: [DialogComponent, BlankSpaceDirective,HistoryPopupComponent,  MailHistoryPopupComponent, MailBodyPopupComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule
  ],
  exports: [DialogComponent, BlankSpaceDirective,HistoryPopupComponent]
})
export class SharedModule { }

