import { Component, OnInit } from '@angular/core';
import { LoginServices } from '../login/loginservice';
import { WhatsNewModel } from '../model/WhatsNewModel';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-new-updates',
  templateUrl: './new-updates.component.html',
  styleUrls: ['./new-updates.component.css']
})

export class NewUpdatesComponent implements OnInit {

  whatsNewList: WhatsNewModel[] = [];

  constructor(private _Service: LoginServices) { }

  ngOnInit(): void {
    this.GetWhatsNewList();
  }

  GetWhatsNewList() {
    this._Service.getWhatsNewList().subscribe(response => {
      if (response != undefined) {
        this.whatsNewList = response;
      }
    });
  }

  OpenPDFDocument(fileName: string) {
    this._Service.ViewPDFDocument('Documents/WhatsNew/' + fileName).subscribe(res => {
      const fileURL = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');
    });
  }

  DownloadPDF(fileName: string)
  {
    this._Service.ViewPDFDocument('Documents/WhatsNew/' + fileName).subscribe(res => {
      saveAs(new Blob([res], { type: 'application/pdf;charset=utf-8' }), fileName);
      });
  }

}
