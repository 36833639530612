<!-- <app-header></app-header> -->
<!-- <mat-sidenav-container class="example-container" autosize>
    <mat-sidenav class="example-sidenav" #sideNav mode="side" opened="true">
      <mat-nav-list>
        <app-nav-menu *ngFor="let item of navItems" [item]="item" class="mg_b" >
        </app-nav-menu>
      </mat-nav-list>
    </mat-sidenav>
  -->
<!-- <div class='main-panel body-content main-background'> -->
<!-- <button mat-icon-button (click)="sideNav.toggle()">
    <mat-icon class="material-icons md-24">menu</mat-icon>
  </button>￼
  <ul class="breadcrumb">
      <li><a href="#">Home</a></li>
      <li>Dashboard</li>
    </ul>
     -->


<router-outlet></router-outlet>
<!-- </mat-sidenav-container>  -->

<div #spinnerElement style="display:none;">
  <div class="loading">
    <h2>Loading...</h2>
  </div>
</div>

<ng-http-loader [spinner]="spinkit.skWave"></ng-http-loader>

