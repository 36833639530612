import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
import * as CryptoJS from 'crypto-js'

@Injectable()

export class HeaderServices {
  BaseUrl: any = [];
  headers: any;
  constructor(private httpclient: HttpClient,private _Service: LoginServices , @Inject(APP_CONFIG) private config: AppConfig) {
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
  }
  LogOff(userid : any): Observable<any> {
    userid = this._Service.encryptPost(userid);
   var model = {userid : userid};
   return this.httpclient.post(this.config.Header, model, this.headers).pipe(map((resp: any) => { return resp.result }));
  }

  /**
   *  fetches the active web type user manual file info 
   * @returns 
   */ 
  GetActiveUserManualDetails(): Observable<any> {
    return this.httpclient.get(this.config.GetActiveUserManual, {}).pipe(map((resp: any) => { return resp.result }));
  }

/**
 *  fetches the active web type user manual file data 
 * @param fileName 
 * @returns 
 */
 
  ViewActiveUserManual(fileName: string) {
    return this.httpclient.get(this.config.api_base_url + 'Login/DownloadFile?url=' + fileName,
      { responseType: 'arraybuffer' }).pipe(map((resp: any) => { return resp }));

  }

}

