import { Component, NgZone, ElementRef, ViewChild, Renderer2 } from '@angular/core';

import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router'
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent {
  public spinkit = Spinkit;
  @ViewChild('spinnerElement') spinnerElement: ElementRef;

  constructor(private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2) {
    router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
    
      this.ngZone.runOutsideAngular(() => {
      
        this.renderer.setStyle(this.spinnerElement.nativeElement, 'display', 'inline');
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setStyle(this.spinnerElement.nativeElement, 'display', 'none');
    });
  }





//   version = VERSION;
//   navItems: NavItem[] = [
//     {
//       displayName: 'Dashboard',
//       iconName: 'dashboard',
//       route: 'devfestfl',
//       children: [
//         {
//           displayName: 'Speakers',
//           iconName: 'group',
//           route: 'devfestfl/speakers',
//           children: [
//             {
//               displayName: 'Michael Prentice',
//               iconName: 'person',
//               route: 'devfestfl/speakers/michael-prentice',
//               children: [
//                 {
//                   displayName: 'Create Enterprise UIs',
//                   iconName: 'star_rate',
//                   route: 'fetch-data'
//                 }
//               ]
//             },
//             {
//               displayName: 'Stephen Fluin',
//               iconName: 'person',
//               route: 'devfestfl/speakers/stephen-fluin',
//               children: [
//                 {
//                   displayName: 'What\'s up with the Web?',
//                   iconName: 'star_rate',
//                   route: 'devfestfl/speakers/stephen-fluin/what-up-web'
//                 }
//               ]
//             },
//             {
//               displayName: 'Mike Brocchi',
//               iconName: 'person',
//               route: 'devfestfl/speakers/mike-brocchi',
//               children: [
//                 {
//                   displayName: 'My ally, the CLI',
//                   iconName: 'star_rate',
//                   route: 'devfestfl/speakers/mike-brocchi/my-ally-cli'
//                 },
//                 {
//                   displayName: 'Become an Angular Tailor',
//                   iconName: 'star_rate',
//                   route: 'devfestfl/speakers/mike-brocchi/become-angular-tailer'
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           displayName: 'Sessions',
//           iconName: 'speaker_notes',
//           route: 'devfestfl/sessions',
//           children: [
//             {
//               displayName: 'Create Enterprise UIs',
//               iconName: 'star_rate',
//               route: 'devfestfl/sessions/material-design'
//             },
//             {
//               displayName: 'What\'s up with the Web?',
//               iconName: 'star_rate',
//               route: 'devfestfl/sessions/what-up-web'
//             },
//             {
//               displayName: 'My ally, the CLI',
//               iconName: 'star_rate',
//               route: 'devfestfl/sessions/my-ally-cli'
//             },
//             {
//               displayName: 'Become an Angular Tailor',
//               iconName: 'star_rate',
//               route: 'devfestfl/sessions/become-angular-tailer'
//             }
//           ]
//         },
//         {
//           displayName: 'Feedback',
//           iconName: 'feedback',
//           route: 'devfestfl/feedback'
//         }
//       ]
//     },
//     {
//       displayName: 'Disney',
//       iconName: 'videocam',
//       children: [
//         {
//           displayName: 'Speakers',
//           iconName: 'group',
//           children: [
//             {
//               displayName: 'Michael Prentice',
//               iconName: 'person',
//               route: 'michael-prentice',
//               children: [
//                 {
//                   displayName: 'Create Enterprise UIs',
//                   iconName: 'star_rate',
//                   route: 'material-design'
//                 }
//               ]
//             },
//             {
//               displayName: 'Stephen Fluin',
//               iconName: 'person',
//               route: 'stephen-fluin',
//               children: [
//                 {
//                   displayName: 'What\'s up with the Web?',
//                   iconName: 'star_rate',
//                   route: 'what-up-web'
//                 }
//               ]
//             },
//             {
//               displayName: 'Mike Brocchi',
//               iconName: 'person',
//               route: 'mike-brocchi',
//               children: [
//                 {
//                   displayName: 'My ally, the CLI',
//                   iconName: 'star_rate',
//                   route: 'my-ally-cli'
//                 },
//                 {
//                   displayName: 'Become an Angular Tailor',
//                   iconName: 'star_rate',
//                   route: 'become-angular-tailer'
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           displayName: 'Sessions',
//           iconName: 'speaker_notes',
//           children: [
//             {
//               displayName: 'Create Enterprise UIs',
//               iconName: 'star_rate',
//               route: 'material-design'
//             },
//             {
//               displayName: 'What\'s up with the Web?',
//               iconName: 'star_rate',
//               route: 'what-up-web'
//             },
//             {
//               displayName: 'My ally, the CLI',
//               iconName: 'star_rate',
//               route: 'my-ally-cli'
//             },
//             {
//               displayName: 'Become an Angular Tailor',
//               iconName: 'star_rate',
//               route: 'become-angular-tailer'
//             }
//           ]
//         },
//         {
//           displayName: 'Feedback',
//           iconName: 'feedback',
//           route: 'feedback'
//         }
//       ]
//     },
//     {
//       displayName: 'Orlando',
//       iconName: 'movie_filter',
//       children: [
//         {
//           displayName: 'Speakers',
//           iconName: 'group',
//           children: [
//             {
//               displayName: 'Michael Prentice',
//               iconName: 'person',
//               route: 'michael-prentice',
//               children: [
//                 {
//                   displayName: 'Create Enterprise UIs',
//                   iconName: 'star_rate',
//                   route: 'material-design'
//                 }
//               ]
//             },
//             {
//               displayName: 'Stephen Fluin',
//               iconName: 'person',
//               route: 'stephen-fluin',
//               children: [
//                 {
//                   displayName: 'What\'s up with the Web?',
//                   iconName: 'star_rate',
//                   route: 'what-up-web'
//                 }
//               ]
//             },
//             {
//               displayName: 'Mike Brocchi',
//               iconName: 'person',
//               route: 'mike-brocchi',
//               children: [
//                 {
//                   displayName: 'My ally, the CLI',
//                   iconName: 'star_rate',
//                   route: 'my-ally-cli'
//                 },
//                 {
//                   displayName: 'Become an Angular Tailor',
//                   iconName: 'star_rate',
//                   route: 'become-angular-tailer'
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           displayName: 'Sessions',
//           iconName: 'speaker_notes',
//           children: [
//             {
//               displayName: 'Create Enterprise UIs',
//               iconName: 'star_rate',
//               route: 'material-design'
//             },
//             {
//               displayName: 'What\'s up with the Web?',
//               iconName: 'star_rate',
//               route: 'what-up-web'
//             },
//             {
//               displayName: 'My ally, the CLI',
//               iconName: 'star_rate',
//               route: 'my-ally-cli'
//             },
//             {
//               displayName: 'Become an Angular Tailor',
//               iconName: 'star_rate',
//               route: 'become-angular-tailer'
//             }
//           ]
//         },
//         {
//           displayName: 'Feedback',
//           iconName: 'feedback',
//           route: 'feedback'
//         }
//       ]
//     },
//     {
//       displayName: 'Maleficent',
//       disabled: true,
//       iconName: 'report_problem',
//       children: [
//         {
//           displayName: 'Speakers',
//           iconName: 'group',
//           children: [
//             {
//               displayName: 'Michael Prentice',
//               iconName: 'person',
//               route: 'michael-prentice',
//               children: [
//                 {
//                   displayName: 'Create Enterprise UIs',
//                   iconName: 'star_rate',
//                   route: 'material-design'
//                 }
//               ]
//             },
//             {
//               displayName: 'Stephen Fluin',
//               iconName: 'person',
//               route: 'stephen-fluin',
//               children: [
//                 {
//                   displayName: 'What\'s up with the Web?',
//                   iconName: 'star_rate',
//                   route: 'what-up-web'
//                 }
//               ]
//             },
//             {
//               displayName: 'Mike Brocchi',
//               iconName: 'person',
//               route: 'mike-brocchi',
//               children: [
//                 {
//                   displayName: 'My ally, the CLI',
//                   iconName: 'star_rate',
//                   route: 'my-ally-cli'
//                 },
//                 {
//                   displayName: 'Become an Angular Tailor',
//                   iconName: 'star_rate',
//                   route: 'become-angular-tailer'
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           displayName: 'Sessions',
//           iconName: 'speaker_notes',
//           children: [
//             {
//               displayName: 'Create Enterprise UIs',
//               iconName: 'star_rate',
//               route: 'material-design'
//             },
//             {
//               displayName: 'What\'s up with the Web?',
//               iconName: 'star_rate',
//               route: 'what-up-web'
//             },
//             {
//               displayName: 'My ally, the CLI',
//               iconName: 'star_rate',
//               route: 'my-ally-cli'
//             },
//             {
//               displayName: 'Become an Angular Tailor',
//               iconName: 'star_rate',
//               route: 'become-angular-tailer'
//             }
//           ]
//         },
//         {
//           displayName: 'Feedback',
//           iconName: 'feedback',
//           route: 'feedback'
//         }
//       ]
//     }
//   ];

//   constructor(public navService: NavService) {
//   }

//   // ngAfterViewInit() {
//   //   this.navService.appDrawer = this.appDrawer;
//   // }
 }
