import { Component, OnInit } from '@angular/core';
import { ChangepasswordService } from '../services/changepassword/changepassword.service';

import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from './custom-validators';
import swal from 'sweetalert2';
import { LoginServices } from '../login/loginservice';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  DeleteMenu: boolean;
  username: string;
  ObjPwdDetails: any = {};
  PasswordChangeStatus: string;
  currentpassword: string;
  param1: string;
  userId: string;
  constructor(private route: ActivatedRoute, private router: Router, private _cahngepwdServices: ChangepasswordService, private fb: FormBuilder, private _loginservice : LoginServices) { this.frmSignup = this.createSignupForm(); }

  ngOnInit() {

    this.DeleteMenu = true;
    this.ObjPwdDetails.username = this.route.snapshot.queryParamMap.get('u');
    this.userId = JSON.parse(localStorage.getItem("LoginData")).userId;
    this.ObjPwdDetails.userId = this.userId;

  }



  clear() {
    this.ObjPwdDetails.OldPassword = "";
    this.ObjPwdDetails.NewPassword = "";
    this.ObjPwdDetails.ConfirmPassword = "";

  }
  Cancel() {
    this.DeleteMenu = false;
    this.router.navigate(['/login']);
  }
  public restrictNumeric(password) {

    //alert('Blur')
    var minMaxLength = /^[\s\S]{8,15}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (minMaxLength.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      number.test(password) &&
      special.test(password)
    ) {
      return true;
    }
    else {
      alert('Passwords must contain at least eight characters, including uppercase, lowercase letters,operator and numbers.');
      return false;
    }

  }

  //----For test------
  public frmSignup: FormGroup;
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
        currentpassword: [
          null,
          Validators.compose([Validators.required])
        ],
        newpassword: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  submit() {
    this.ObjPwdDetails.username = this.frmSignup.value.email;
    this.ObjPwdDetails.NewPassword = this.frmSignup.value.newpassword;
    if (this.ObjPwdDetails.userId != undefined) {
      this.ObjPwdDetails.userId = this._loginservice.encryptPost(this.ObjPwdDetails.userId);
    }
    if (this.ObjPwdDetails.NewPassword != undefined) {
      this.ObjPwdDetails.NewPassword = this._loginservice.encryptPost(this.ObjPwdDetails.NewPassword);
    }

    if (this.ObjPwdDetails.currentpassword != undefined) {
      this.ObjPwdDetails.currentpassword = this._loginservice.encryptPost(this.ObjPwdDetails.currentpassword);
    }
    this._cahngepwdServices.ChangePassword(this.ObjPwdDetails).subscribe(data => {
      this.PasswordChangeStatus = data;

      if (this.PasswordChangeStatus == 'success') {
        swal("Your password has been changed.");

        this.router.navigate(['/login']);
        return false;
      }
      if (this.PasswordChangeStatus == 'failed') {
        swal("Your password is invalid. Please try again.");
      }
    });
    this.clear();
  }
}

