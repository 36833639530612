<div class="row">
    <div class="col-md-12">
      <div class="card left-brd-pink card_margin card_margin mb-0">
        <div class="card-header pink">
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="primary">close</mat-icon>
        </button>
          <div class="row">
            <div class="col-md-2"><strong class="card-title">Email Details</strong></div>
  
          </div>
        </div>
        <div [innerHTML]="mailconent"  class="mail-content"></div> 
      </div>
    </div>
  
                
                