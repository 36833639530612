<!--<app-dialog [(visible)]="DeleteMenu">-->

<!--//----Test------------------>
<!-- <div class="container p-3">
   <div class="offset-3 col-6">
     <div class="card">
       <div class="card-header  font-weight-bold">
         Sign Up Form
       </div>-->
<!--<div class="col-md-8">-->
<div class="card card-body forgot-pwd">
  <h3 style="text-align:center;">Forget Password </h3>
  <form [formGroup]="frmSignup" (submit)="submit()">
    <div class="form-group">
      <!--<label for="email" [ngClass]="frmSignup.controls['email'].invalid ? 'text-danger' : ''">Email Address</label>-->
      <input id="email" disabled=true formControlName="email"  type="email" [(ngModel)]="ObjPwdDetails.username" autocomplete="off" class="form-control">

      <!--<input type="text" id="inputLGEx" class="form-control form-control-lg" disabled [(ngModel)]="ObjPwdDetails.username" name="username">-->

      <label class="text-danger" *ngIf="frmSignup.controls['email'].hasError('required')">
        Email is Required!
      </label>
      <label class="text-danger" *ngIf="frmSignup.controls['email'].hasError('email')">
        Enter a valid email address!
      </label>
    </div>
    <div class="form-group">
      <label for="password" [ngClass]="frmSignup.controls['newpassword'].invalid ? 'text-danger' : ''">
        New
        Password:
      </label>
      <input id="password" formControlName="newpassword" type="password" class="form-control" [ngClass]="frmSignup.controls['newpassword'].invalid?'is-invalid':''">

      <label class="col-md-12 mt-10 pading-0" [ngClass]="frmSignup.controls['newpassword'].hasError('required') || frmSignup.controls['newpassword'].hasError('minlength')  ? 'text-danger' : 'text-success'">
        <i class="material-icons">
          {{
              frmSignup.controls['newpassword'].hasError('required') ||
                frmSignup.controls['newpassword'].hasError('minlength') ? 'cancel' :
                'check_circle'
          }}
        </i>
        Must be at least 8 characters!
      </label>
      <label class="col-md-12 pading-0" [ngClass]="frmSignup.controls['newpassword'].hasError('required') || frmSignup.controls['newpassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
        <i class="material-icons">
          {{
               frmSignup.controls['newpassword'].hasError('required') ||
                frmSignup.controls['newpassword'].hasError('hasNumber') ? 'cancel' :
                'check_circle'
          }}
        </i>
        Must contain at least 1 number!
      </label>
      <label class="col-md-12 pading-0" [ngClass]="frmSignup.controls['newpassword'].hasError('required') || frmSignup.controls['newpassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
        <i class="material-icons">
          {{
              frmSignup.controls['newpassword'].hasError('required') ||
                frmSignup.controls['newpassword'].hasError('hasCapitalCase') ? 'cancel' :
                'check_circle'
          }}
        </i>
        Must contain at least 1 in Capital Case!
      </label>
      <label class="col-md-12 pading-0" [ngClass]="frmSignup.controls['newpassword'].hasError('required') || frmSignup.controls['newpassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
        <i class="material-icons">
          {{
              frmSignup.controls['newpassword'].hasError('required') ||
                frmSignup.controls['newpassword'].hasError('hasSmallCase') ? 'cancel' :
                'check_circle'
          }}
        </i>
        Must contain at least 1 Letter in Small Case!
      </label>
      <label class="col-md-12 mb-10 pading-0" [ngClass]="frmSignup.controls['newpassword'].hasError('required') || frmSignup.controls['newpassword'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
        <i class="material-icons">
          {{
              frmSignup.controls['newpassword'].hasError('required') ||
                frmSignup.controls['newpassword'].hasError('hasSpecialCharacters') ? 'cancel' :
                'check_circle'
          }}
        </i>
        Must contain at least 1 Special Character!
      </label>
    </div>
    <div class="form-group">
      <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'text-danger' : ''">
        Confirm
        Password:
      </label>
      <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
             [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
      <label class="text-danger" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')">
        Password is Required!
      </label>
      <label class="text-danger" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')">
        Password do not match
      </label>
    </div>
    <div class="form-group">
      <!--<div class="col-md-12">-->
      <!--<button [disabled]="frmSignup.invalid" type="submit" class="btn btn-primary btn-block font-weight-bold ml-0 col-md-3">Sign up</button>-->
      <div class="col text-center">
        <button [disabled]="frmSignup.invalid" type="submit" class="btn btn-info text-center">Submit</button>
        <button type="button" class="btn btn-info text-center" (click)="clear()">Clear</button>
        <!--<button type="button" class="btn btn-warning text-center" (click)="Cancel()">Cancel</button>-->
      </div>



      <!--</div>-->

    </div>
  </form>
</div>
<!--</div>-->
<!--</div>
  </div>
</div>-->
<!--</app-dialog>-->





