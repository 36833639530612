import { Component, OnInit,Inject,Injectable , Input,TemplateRef, Output, EventEmitter,ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { MatDialog,MatDialogConfig,MatDialogRef, MAT_DIALOG_DATA,MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Console } from 'console';



@Component({
  selector: 'app-history-popup',
  templateUrl: './history-popup.component.html',
  styleUrls: ['./history-popup.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]

})

export class HistoryPopupComponent implements OnInit {  
  dataSourcehistory : any[];
  letterNumber:any;
  letterTitle:any;
  titleHead: any;
  title: any;
  hidden=true;
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  displayedColumnsMoreDetails: string[] = ['UserName', 'CreatedOn', 'RejectionReason'];
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {         
    this.dataSourcehistory=this.data.dataSourceMoreDetails;    
    this.letterNumber=this.data.letterNumber;
    this.letterTitle=this.data.letterTitle;
    this.titleHead=this.data.titleHead;
    this.title=this.data.title;
  }
  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);    
  }
  
}
