import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        alert(err.statusText || err.error.message)
        //  location.reload(true);
      }
      else if (err.status === 400) {
        var errorObj = JSON.parse(err.error);
        var alertStr = '';
        for (var k in errorObj) {
          alertStr += errorObj[k] + '\n';
        }
        alert(alertStr)
      }
      else if (err.status === 404) {
        alert(err.statusText || err.error.message)

      }
      else if (err.status === 500) {
        alert(err.statusText || err.error.message)

      }
      else if (err.status === 504) {
        alert(err.statusText || err.error.message);
      }
      else if (err.status === 200) {
        alert(err.error.message || err.statusText)
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}
