import { BrowserModule } from '@angular/platform-browser';
import { AuthguardGuard } from './authguard/authguard.guard';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './login/login.component';
import { GlobalModule } from './global/global.module';
import { ErrorInterceptor } from './global/error.interceptor';
import { SharedModule } from './shared-module/shared-module.module';
import { LoginServices } from './login/loginservice';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ToastrModule } from 'ngx-toastr';
import { httpInterceptor } from './global/jwt.interceptor';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ReportComponent } from './report/report.component';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {NgxPrintModule} from 'ngx-print';
import { NgxWatermarkModule } from 'ngx-watermark';
import { CommonMsg } from './global/common-msg';
import { FinalAuditmReportComponent } from './final-audit-mreport/final-audit-mreport.component';
import { FinalMAuditMReportComponent } from './final-maudit-mreport/final-maudit-mreport.component';
import { FinalAuditMAReportComponent } from './final-audit-mareport/final-audit-mareport.component';
import { NewUpdatesComponent } from './new-updates/new-updates.component';


export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangepasswordComponent,
    ReportComponent,
    ScrollToBottomDirective,
    ForgetpasswordComponent,
    FinalAuditmReportComponent,
    FinalMAuditMReportComponent,
    FinalAuditMAReportComponent,
    NewUpdatesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxPrintModule,
    CommonModule,
    NgxWatermarkModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    CdkTreeModule,
    DragDropModule,
    ChartsModule,
    GlobalModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule,
    CKEditorModule,
    FlatpickrModule.forRoot(),
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      {
        path: '', redirectTo: 'login', pathMatch: 'full', data: {
          breadcrumb: 'Login'
        }
      },
      {
        path: 'login', component: LoginComponent, data: {
          breadcrumb: 'Login'
        }
      },
      { path: 'forgetpassword', component: ForgetpasswordComponent },
      { path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthguardGuard]  },
      { path: 'report', component: ReportComponent },
      {path: 'final-maudit-mreport', component: FinalMAuditMReportComponent},
      {path: 'final-audit-mreport', component: FinalAuditmReportComponent},
      {path: 'final-audit-mareport', component: FinalAuditMAReportComponent},
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthguardGuard] },
      { path: 'newupdates', component: NewUpdatesComponent },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ], { relativeLinkResolution: 'legacy' })

  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy },
  { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  [LoginServices], [AuthguardGuard],[CommonMsg]
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

