import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditObservationService } from '../services/audit-observation/audit-observation.service';
import { FormGroupDirective } from '@angular/forms';
import swal from 'sweetalert2';
import { CommonMsg } from '../global/common-msg';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonService } from '../services/common/common.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuditReportService } from '../services/audit-report/audit-report.service';
import { NgxWatermarkOptions } from 'ngx-watermark';
import { LoginServices } from '../login/loginservice';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-final-audit-mareport',
  templateUrl: './final-audit-mareport.component.html',
  styleUrls: ['./final-audit-mareport.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FinalAuditMAReportComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  userId: number;
  roleId: number;
  roleUserMapId: number = 0;
  selectedAudit: any;
  public Editor = ClassicEditor;
  saveButtonClicked: boolean = false;
  saveButtonDisabled: boolean = false;
  RejectPopUp: boolean = false;
  rejectionRemark: string = '';
  arrSupervisor: any;
  selectedSupervisor: number = 0;
  ForwardToSupervisorPopUp: boolean = false;
  selectedAuditId: any;
  tabIndex: number = 0;
  tabCount = 5;
  KeyComponents: any;
  Form4Component: any;
  AuditId: number;
  executiveSummery: string;
  auditReportForwardingLetterDetails: ForwardingLetterDetails;
  auditReportExecutiveSummery: any;
  selectedAuditTeamList: any;
  selectedauditorteam: string;
  auditParaList: any[] = [];
  searchTerm: string = '';
  pageSize: number = 1000;
  pageNumber: number = 1;
  auditFinancialImplicationCases: any;
  auditParasStatus: boolean;
  auditReportName: string = "Draft Audit Report";
  currentauditreportstatus: number = 0;
  reportAuditId: number;
  auditList: any[]=[];
  Overall: any[] = [];
  options: NgxWatermarkOptions = {
    text: 'Draft Audit Report',
    width: 350,
    height: 300,
    fontFamily: 'Kanit',
    color: '#999',
    alpha: 0.7,
    degree: -45,
    fontSize: '20px',
};
  constructor(private route: ActivatedRoute, private _service: AuditObservationService, private _msg: CommonMsg,
    private _CommonService: CommonService, private _location: Location, private _serviceReport: AuditReportService,
    private _loginService: LoginServices) {
   
  }

  ngOnInit() {   
    this.route.queryParams
      .subscribe(params => {
        this.userId = this._loginService.decryptString(params.userId);
        this.roleId = this._loginService.decryptString(params.roleId.replace(' ','+'));
        this.roleUserMapId = this._loginService.decryptString(params.roleUserMapId);
        this.reportAuditId = params.auditId;
        this.getAuditDetailsByAudit();
        this.GetAuditForwardingLetterDetails();
        this.getKeyComponents();
        this.GetAuditReportExecutiveSummery();
        this.getForm4Components();  
      });
  }

  getAuditDetailsByAudit()
  {
    this._service.getAuditDetailsByAuditId(this.userId, this.reportAuditId).subscribe(response => {
      this.auditList = response;
      this.selectedAudit = this.auditList[0] as any;
      let auditData = this.selectedAudit;
      if (auditData) {
        this.selectedAuditId = auditData.auditId;
      }
    });
  }

  GetAuditForwardingLetterDetails() {
    if (this.reportAuditId) {
      const obj = { AuditID: this.reportAuditId };    
      this._serviceReport.GetAuditForwardingLetterDetails(obj).subscribe(response => {        
        if (response != undefined) {
         this.auditReportForwardingLetterDetails=response;
        }       
        this.currentauditreportstatus = this.auditReportForwardingLetterDetails.status;
        this.saveButtonDisabled = true;
        this.ShoworHideForwardtoSupervisor();
        this.ShoworHideForwardtoAuditHead();
        this.ShoworHideDraftOrFinalReport();
        this.AuditReportParasStatus();
        this.getAuditParas();
        if (this.roleId == 6 && (this.currentauditreportstatus == 1 || this.currentauditreportstatus == 5 || this.currentauditreportstatus == 7)) {
          this.saveButtonDisabled = false;
        }
      });
    }
  }

  AuditReportsParaFinancialImplicationCases() {
    if (this.reportAuditId) {
      this._serviceReport.AuditReportsParaFinancialImplicationCases(this.reportAuditId).subscribe(response => {
        this.auditFinancialImplicationCases = response;
      });
    }
  }
  AuditReportParasStatus() {
    if (this.reportAuditId) {
      this._serviceReport.AuditReportParasStatus(this.reportAuditId).subscribe(response => {
        this.auditParasStatus = response;
      });
    }
  }

  GetAuditReportExecutiveSummery() {
    if (this.reportAuditId) {
      const obj = { AuditID: this.reportAuditId };
      this.executiveSummery = "";
      this._serviceReport.GetAuditReportsExecutiveSummary(obj).subscribe(response => {
        this.auditReportExecutiveSummery = response;
        this.executiveSummery = this.auditReportExecutiveSummery.executiveSummary;
      });
    }
  }

  NewAuditReportForwardingLetter(type: string) {
    if (this.auditReportForwardingLetterDetails) {
      const result = this.ValidateForwardingLetterDetails(this.auditReportForwardingLetterDetails);
      if (result == 1) {
        const obj = {
          ForwardingletterDetailsID: this.auditReportForwardingLetterDetails.forwardingletterDetailsID, AuditID: this.reportAuditId,
          LetterNumber: this.auditReportForwardingLetterDetails.letterNumber, LetterDate: this.auditReportForwardingLetterDetails.letterDate,
          LetterTo: this.auditReportForwardingLetterDetails.letterTo, LetterSubject: this.auditReportForwardingLetterDetails.letterSubject,
          LetterContent: this.auditReportForwardingLetterDetails.letterContent, LetterFrom: this.auditReportForwardingLetterDetails.letterFrom,
          LetterCopyTo: this.auditReportForwardingLetterDetails.letterCopyTo, isActive: this.auditReportForwardingLetterDetails.isActive,
          Status: this.roleId == 5 ? 3 : 1, CreatedBy: this.userId, RejectionRemarks: this.auditReportForwardingLetterDetails.rejectionRemarks
        };
        this._serviceReport.InsertAuditForwardingLetterDetails(obj).subscribe(response => {
          if (response > 0) {
            swal(this._msg.updateMsg);
            this.saveButtonClicked = false;
            this.GetAuditForwardingLetterDetails();

            this.EditAuditReportForm();
            if (type.toLowerCase() == "next") {
              this.tabIndex = (this.tabIndex + 1) % this.tabCount;
            }
          }
          else {
            swal(this._msg.commonerrorMsg);
          }
        });
      }
    }
  }
  ShoworHideForwardtoSupervisor() {
    let hideForwardToSupervisor = true;
    if (this.currentauditreportstatus == 3 || this.currentauditreportstatus == 4) {
      hideForwardToSupervisor = false
    }
    return hideForwardToSupervisor;
  }
  ShoworHideForwardtoAuditHead() {
    let hideForwardToAuditHead = true;
    if (this.currentauditreportstatus == 2) {
      hideForwardToAuditHead = false;
    }
    return hideForwardToAuditHead;
  }
  ShoworHideDraftOrFinalReport() {
    
    this.auditReportName = "Draft Audit Report";
    if (this.currentauditreportstatus == 3 || this.currentauditreportstatus == 4) {
      this.auditReportName = "Draft Audit Report";
      this.updateOptions({ text: "Draft Audit Report" });
    }
    else if (this.currentauditreportstatus == 6 || this.currentauditreportstatus == 8) {
      this.auditReportName = "Final Audit Report";
      this.updateOptions({ text: "" });
    }
  }

  private updateOptions(_obj: any) {
    this.options = Object.assign({}, this.options, _obj);
}

  ValidateForwardingLetterDetails(Form1: any) {

    if (Form1) {
      if (Form1.letterNumber.length == 0) {
        swal('Please enter Letter Number .');
        return 0;
      }
      if ((this.roleId == 5 || this.roleId == 2) && Form1.letterDate == null) {
        swal('Please enter Letter Date .');
        return 0;
      }
      if (Form1.letterTo.length == 0) {
        swal('Please enter letter To.');
        return 0;
      }
      if (Form1.letterSubject.length == 0) {
        swal('Please enter Letter Subject.');
        return 0;
      }
      if (Form1.letterContent.length == 0) {
        swal('Please enter Letter Content.');
        return 0;
      }
      if (Form1.letterFrom.length == 0) {
        swal('Please enter Letter From.');
        return 0;
      }
      if (Form1.letterCopyTo.length == 0) {
        swal('Please enter Letter Copy To.');
        return 0;
      }
      return 1;
    }
  }

  AuditReportSave() {

    if (this.reportAuditId) {
      const isvalidForm1 = this.ValidateForwardingLetterDetails(this.auditReportForwardingLetterDetails);
      const isvalidForm3 = this.validateExecutiveSummary(this.executiveSummery);
      if (isvalidForm1 == 0 || isvalidForm3 == 0) {
        return;
      }
      if (this.checkErrorinForm2()) {
        return;
      }
      const obj = { AuditID: this.reportAuditId, status: this.roleId == 5 ? 3 : 2, isActive: 1, CreatedBy: this.userId };
      this._serviceReport.AuditReportSave(obj).subscribe(response => {
        if (response > 0) {
          swal(this._msg.updateMsg);
          this.auditReportForwardingLetterDetails = null;
          this.GetAuditForwardingLetterDetails();
          this.getAuditParas();
        }
        else {
          swal(this._msg.commonerrorMsg);
        }
      });
    }
  }



  forward(statusId) {
    if ((statusId == 5 || statusId==7) && (this.rejectionRemark == undefined || this.rejectionRemark.trim() == "")) {
      swal("Please enter Rejection remarks.");
      this.RejectPopUp = false;
      return false;
    }
    if (statusId == 6) {
      this.roleUserMapId = this.selectedSupervisor;
    }
    if (statusId == 4 || statusId == 6) {
      const isvalidForm1 = this.ValidateForwardingLetterDetails(this.auditReportForwardingLetterDetails);
      const isvalidForm3 = this.validateExecutiveSummary(this.executiveSummery);
      if (isvalidForm1 == 0 || isvalidForm3 == 0) {
        return;
      }
      if (this.checkErrorinForm2()) {
        return;
      }
    }
    const obj = {
      AuditID: this.selectedAudit.auditId, StatusID: statusId, RejectionRemark: this.rejectionRemark,
      RoleUserMapID: this.roleUserMapId, UserID: this.userId
    };
    this._serviceReport.UpdateAuditReportRequest(obj).subscribe(response => {
      if (response > 0) {
        switch (statusId) {
          case 3:
            swal(this._msg.forwardAuditHeadMsg);
            break;
          case 6:
            swal(this._msg.forwardSupervisorMsg);
            break;
          case 5:
            swal(this._msg.rejectAuditDHMsg);
            break;
          case 4:
            swal(this._msg.approveAuditDHreportMsg);
            break;
        }

        this.GetAuditForwardingLetterDetails();
        this.rejectionRemark = '';
        this.ForwardToSupervisorPopUp = false;
        this.RejectPopUp = false;
      }
      else {
        swal("Cannot forward until all paras are approved.");
        return;
      }
    })
  }


  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 45 || k == 44 || k == 95 || (k >= 48 && k <= 57));
  }

  bindSupervisorDropDown() {
    this._CommonService.bindSupervisorDropDown().subscribe(result => {
      this.arrSupervisor = result;
    })
  }
  Cancel() {
    this.selectedSupervisor = 0;
    this.bindSupervisorDropDown();
  }

  NewAuditReportExecutiveSummery(type: string) {
    const result = this.validateExecutiveSummary(this.executiveSummery);
    if (result == 1) {
      const obj = {
        AuditReportExecutiveSummeryID: this.auditReportExecutiveSummery.auditReportExecutiveSummeryID,
        AuditID: this.reportAuditId,
        ExecutiveSummary: this.executiveSummery,
        isActive: 1,
        CreatedBy: this.userId,
        RejectionRemarks: '',
        Status: this.roleId == 5 ? 3 : 1
      };
      this._serviceReport.InsertAuditReportExecutiveSummary(obj).subscribe(response => {
        if (response > 0) {
          swal(this._msg.updateMsg);
          this.GetAuditReportExecutiveSummery();
          if (type.toLowerCase() == "next") {
            this.tabIndex = (this.tabIndex + 1) % this.tabCount;
          }
        }
        else {
          swal(this._msg.commonerrorMsg);
        }
      });
    }
  }



  validateExecutiveSummary(Form3: any) {
    if (Form3.length == 0) {
      swal('Please enter executive summary.');
      return 0;
    }
    return 1;
  }



  addNewAuthRow(i, type) {
    if (type == 'strength') {
      let authrizationStatus: any[] = this.KeyComponents.sanctionedStrengthList;
      this.KeyComponents.sanctionedStrengthList = [];
      authrizationStatus.push({
        nameofPAO: '', sanctionStrength: '', workingStrength: '', vacancies: '',
        sanctionedId: 0, auditId: authrizationStatus[0].auditId
      });
      this.KeyComponents.sanctionedStrengthList = authrizationStatus;
    }
    else {
      let authrizationStatus: any[] = this.KeyComponents.statusofAuthorizationList;
      this.KeyComponents.statusofAuthorizationList = [];
      authrizationStatus.push({
        nameofPAO: '', periodofPosting: '', keyActivationPeriod: '', officeTelephoneNumber: '',
        authorizationId: 0, auditId: authrizationStatus[0].auditId
      });
      this.KeyComponents.statusofAuthorizationList = authrizationStatus;
    }
  }

  deletingAuthRow(i, type) {
    if (type == 'strength') {
      let authrizationStatus: any[] = this.KeyComponents.sanctionedStrengthList;
      this.KeyComponents.sanctionedStrengthList = [];
      authrizationStatus.splice(i, 1);
      this.KeyComponents.sanctionedStrengthList = authrizationStatus;
    }
    else {
      let authrizationStatus: any[] = this.KeyComponents.statusofAuthorizationList;
      this.KeyComponents.statusofAuthorizationList = [];
      authrizationStatus.splice(i, 1);
      this.KeyComponents.statusofAuthorizationList = authrizationStatus;
    }
  }

  insertupdateForm2AuditReport() {
    if (!this.checkErrorinForm2()) {
      this._serviceReport.InsertupdateForm2AuditReport(this.KeyComponents).subscribe(response => {
        swal(this._msg.updateMsg);
        this.getKeyComponents();
      });
    }
  }

  saveAndNextForm2AuditReport() {
    if (!this.checkErrorinForm2()) {
      this._serviceReport.InsertupdateForm2AuditReport(this.KeyComponents).subscribe(response => {
        swal(this._msg.updateMsg);
        this.getKeyComponents();
        this.tabIndex = (this.tabIndex + 1) % this.tabCount
      });
    }
  }

  checkErrorinForm2() {
    let error = false;
    this.KeyComponents.statusofAuthorizationList.forEach(element => {
      if (element.nameofPAO === '') {
        swal('Please enter Name of PAO.');
        error = true;
        return;
      }
      if (element.officeTelephoneNumber === '') {
        swal('Please office Telephone Number.');
        error = true;
        return;
      }
      if (element.periodofPosting === '') {
        swal('Please enter period of posting.');
        error = true;
        return;
      }
      if (element.keyActivationPeriod === '') {
        swal('Please enter key activation period.');
        error = true;
        return;
      }
    });
    this.KeyComponents.sanctionedStrengthList.forEach(element => {
      if (element.nameofPAO === '') {
        swal('Please enter key name of post.');
        error = true;
        return;
      }
      if (element.sanctionStrength === '') {
        swal('Please enter Sanction Strength.');
        error = true;
        return;
      }
      if (element.workingStrength === '') {
        swal('Please enter Working Strength.');
        error = true;
        return;
      }
      if (element.vacancies === '') {
        swal('Please enter vacancies.');
        error = true;
        return;
      }
    });
    this.KeyComponents.ncddOs_CDDOs_DDOsList.forEach(element => {
      if (element.numberofNCDDOs === '') {
        swal('Please enter number of NCDDOs.');
        error = true;
        return;
      }
      if (element.numberofCDDOs === '') {
        swal('Please enter number of CDDOs.');
        error = true;
        return;
      }
      if (element.numberofDDOs === '') {
        swal('Please enter number of DDOs.');
        error = true;
        return;
      }
    });
    this.KeyComponents.outstandingCGAAuditParasList.forEach(element => {
      if (element.numberofParas === '') {
        swal('Please enter number of paras.');
        error = true;
        return;
      }
      if (element.paraSettled === '') {
        swal('Please enter para settled.');
        error = true;
        return;
      }
      if (element.paraOutstanding === '') {
        swal('Please enter para outstanding.');
        error = true;
        return;
      }
    });
    this.KeyComponents.outstandingCAGAuditParasList.forEach(element => {
      if (element.numberofParas === '') {
        swal('Please enter number of paras.');
        error = true;
        return;
      }
      if (element.paraSettled === '') {
        swal('Please enter para settled.');
        error = true;
        return;
      }
      if (element.paraOutstanding === '') {
        swal('Please enter para outstanding.');
        error = true;
        return;
      }
    });
    this.KeyComponents.outstandingInternalAuditParasList.forEach(element => {
      if (element.numberofParas === '') {
        swal('Please enter number of paras.');
        error = true;
        return;
      }
      if (element.paraSettled === '') {
        swal('Please enter para settled.');
        error = true;
        return;
      }
      if (element.paraOutstanding === '') {
        swal('Please enter para outstanding.');
        error = true;
        return;
      }
    });
    return error;
  }

  getKeyComponents() {
    let authrizationStatus: any[] = [];
    this.KeyComponents = [];
    //authrizationStatus.push({PaoName:'test',PeriodofPosting:'2019-2020',KeyActivationPeriod:})
    this._serviceReport.getAuditReportForm2(this.reportAuditId).subscribe(response => {
      this.KeyComponents = response;
      if (response.statusofAuthorizationList.length == 0) {
        authrizationStatus.push({
          NameofPAO: '', PeriodofPosting: '', KeyActivationPeriod: '', OfficeTelephoneNumber: '',
          AuthorizationId: 0, AuditId: this.reportAuditId
        })
        this.KeyComponents.statusofAuthorizationList = authrizationStatus;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveAndNextForm4AuditReport(type) {
    this._serviceReport.InsertupdateForm4AuditReport(this.Form4Component).subscribe(response => {
      this.getForm4Components();
      swal(this._msg.updateMsg);
      this.EditAuditReportForm();
      if (type == 'next') {
        this.tabIndex = (this.tabIndex + 1) % this.tabCount;
      }
    });
  }

  getForm4Components() {
    let form4 = [];
    this.Form4Component = [];
    this._serviceReport.getAuditReportForm4(this.reportAuditId).subscribe(response => {
      this.Form4Component = response;
      form4.push(response.financialRisk.find(x => x.overAll == 'overall'));
      form4.push(response.accounting.find(x => x.overAll == 'overall'));
      form4.push(response.serviceDelivery.find(x => x.overAll == 'overall'));
      form4.push(response.documentation.find(x => x.overAll == 'overall'));
      form4.push(response.establishment.find(x => x.overAll == 'overall'));
      form4.push(response.system.find(x => x.overAll == 'overall'));
      form4.push(response.other.find(x => x.overAll == 'overall'));
      this.Overall = form4;
      this.countData();
    });
  }

  onOptionsSelected(selValue, i, type) {
    if(isNaN(selValue)){
      selValue = null;
    }
    let element = this.Form4Component.colorList.find(x => x.marks == selValue);
    if (type == 'fin') {
      this.Form4Component.financialRisk[i].color = element.color;
      this.Form4Component.financialRisk[i].colorId = element.id;
      this.Form4Component.financialRisk[i].marks = element.marks;
      this.Form4Component.financialRisk[i].colorValue = element.color;
    }
    if (type == 'acc') {
      this.Form4Component.accounting[i].color = element.color;
      this.Form4Component.accounting[i].colorId = element.id;
      this.Form4Component.accounting[i].colorValue = element.color;
      this.Form4Component.accounting[i].marks = element.marks;
    }
    if (type == 'ser') {
      this.Form4Component.serviceDelivery[i].color = element.color;
      this.Form4Component.serviceDelivery[i].colorId = element.id;
      this.Form4Component.serviceDelivery[i].colorValue = element.color;
      this.Form4Component.serviceDelivery[i].marks = element.marks;
    }
    if (type == 'doc') {
      this.Form4Component.documentation[i].color = element.color;
      this.Form4Component.documentation[i].colorId = element.id;
      this.Form4Component.documentation[i].colorValue = element.color;
      this.Form4Component.documentation[i].marks = element.marks;
    }
    if (type == 'est') {
      this.Form4Component.establishment[i].color = element.color;
      this.Form4Component.establishment[i].colorId = element.id;
      this.Form4Component.establishment[i].colorValue = element.color;
      this.Form4Component.establishment[i].marks = element.marks;
    }
    if (type == 'sys') {
      this.Form4Component.system[i].color = element.color;
      this.Form4Component.system[i].colorId = element.id;
      this.Form4Component.system[i].colorValue = element.color;
      this.Form4Component.system[i].marks = element.marks;
    }
    if (type == 'oth') {
      this.Form4Component.other[i].color = element.color;
      this.Form4Component.other[i].colorId = element.id;
      this.Form4Component.other[i].colorValue = element.color;
      this.Form4Component.other[i].marks = element.marks;
    }
    this.countData();
  }

  countData(){
    let count:number = 0;
    let startCount:number =0;
    this.Form4Component.financialRisk.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.accounting.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.serviceDelivery.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.documentation.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.establishment.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.system.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });
     
    count =0;
    startCount =0;

    this.Form4Component.other.forEach(element => {
      if(!isNaN(element.marks) && element.marks != null && element.marks != ''){
       if(element.overAll != 'overall'){
         count = count + parseInt(element.marks);
         startCount = startCount + 1;
       }
      }
      if(element.overAll == 'overall'){
        element.marks = (count/startCount).toFixed(3);
        if(element.marks == "NaN"){
          element.marks =0;
        }
        let overAll = parseFloat(element.marks);
        if(0 < overAll  && overAll <= 5){
          element.colorValue = 'red';
          if(overAll <2){
            element.colorId = 9;
          }
          if(overAll >2  && overAll <=3){
            element.colorId = 10;
          }
          if(overAll >3  && overAll <=4){
            element.colorId = 11;
          }
          if(overAll >3 && overAll <5){
            element.colorId = 1;
          }
        }
        else if (5 < overAll  && overAll <= 8){
          element.colorValue = 'yellow';
          if(overAll <=6){
            element.colorId = 6;
          }
          if(overAll >6  && overAll <=7){
            element.colorId = 7;
          }
          if(overAll > 7  && overAll <=8){
            element.colorId = 2;
          }
        }
        else if(8 < overAll  && overAll <= 10){
          element.colorValue = 'green';
          if(overAll <=9){
            element.colorId = 5;
          }
          if(overAll >9  && overAll <=10){
            element.colorId = 3;
          }
        }
        else{
          element.colorValue = '';
          element.colorId = 4;
        }
      }
     });

  }

  addForm4Row(type) {
    if (type == 'fin') {
      let authrizationStatus: any[] = this.Form4Component.financialRisk;
      this.Form4Component.financialRisk = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.financialRisk = authrizationStatus;
    }
    if (type == 'acc') {
      let authrizationStatus: any[] = this.Form4Component.accounting;
      this.Form4Component.accounting = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.accounting = authrizationStatus;
    }
    if (type == 'ser') {
      let authrizationStatus: any[] = this.Form4Component.serviceDelivery;
      this.Form4Component.serviceDelivery = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.serviceDelivery = authrizationStatus;
    }
    if (type == 'doc') {
      let authrizationStatus: any[] = this.Form4Component.documentation;
      this.Form4Component.documentation = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.documentation = authrizationStatus;
    }
    if (type == 'est') {
      let authrizationStatus: any[] = this.Form4Component.establishment;
      this.Form4Component.establishment = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.establishment = authrizationStatus;
    }
    if (type == 'sys') {
      let authrizationStatus: any[] = this.Form4Component.system;
      this.Form4Component.system = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.system = authrizationStatus;
    }
    if (type == 'oth') {
      let authrizationStatus: any[] = this.Form4Component.other;
      this.Form4Component.other = [];
      authrizationStatus.push({
        id: 0, auditId: this.Form4Component.auditId, statusId: 0, colorId: 4, riskId: 8,
        aspectId: 0, aspectValue: '', value: '', color: '', overAll: '' ,marks:''
      });
      authrizationStatus.sort((a, b) => a.overAll.localeCompare(b.overAll))
      this.Form4Component.other = authrizationStatus;
    }
    this.countData();
  }

  deleteForm4Row(type,i){
    if (!this.saveButtonDisabled) {
      if (type == 'fin') {
        let data: any[] = this.Form4Component.financialRisk;
        this.Form4Component.financialRisk = [];
        data.splice(i, 1);
        this.Form4Component.financialRisk = data;
      }
      else if (type == 'acc') {
        let data: any[] = this.Form4Component.accounting;
        this.Form4Component.accounting = [];
        data.splice(i, 1);
        this.Form4Component.accounting = data;
      }
      else if (type == 'ser') {
        let data: any[] = this.Form4Component.serviceDelivery;
        this.Form4Component.serviceDelivery = [];
        data.splice(i, 1);
        this.Form4Component.serviceDelivery = data;
      }
      else if (type == 'doc') {
        let data: any[] = this.Form4Component.documentation;
        this.Form4Component.documentation = [];
        data.splice(i, 1);
        this.Form4Component.documentation = data;
      }
      else if (type == 'est') {
        let data: any[] = this.Form4Component.establishment;
        this.Form4Component.establishment = [];
        data.splice(i, 1);
        this.Form4Component.establishment = data;
      }
      else if (type == 'sys') {
        let data: any[] = this.Form4Component.system;
        this.Form4Component.system = [];
        data.splice(i, 1);
        this.Form4Component.system = data;
      }
      else if (type == 'oth') {
        let data: any[] = this.Form4Component.other;
        this.Form4Component.other = [];
        data.splice(i, 1);
        this.Form4Component.other = data;
      }
      this.countData();
    }
    else{
      swal('Form is locked!');
    }
  }

  getAuditParas() {
    if (this.reportAuditId) {
      this._service.getAuditParaImplementation(this.reportAuditId, this.pageSize, this.pageNumber, this.searchTerm, this.roleId, '').subscribe(response => {
        if (response != undefined && response.result != undefined && response.result.length > 0) {
          this.auditParaList = [];
          if (this.currentauditreportstatus == 6) {
            this.auditParaList = response.result.filter(x => x.statusId == 8)
          }
          else {
            this.auditParaList = response.result.filter(x => x.statusId != 31);
          }
        }
      });
    }
  }

  EditAuditReportForm() {
    if (this.roleId == 5 && (this.auditReportForwardingLetterDetails.status == 3)) {
      this.saveButtonDisabled = false;
    }
  }
  
}

export interface ForwardingLetterDetails {
  forwardingletterDetailsID: string,
  AuditID: string,
  letterNumber: string,
  letterDate: Date,
  letterTo: string,
  letterSubject: string,
  letterContent: string,
  letterFrom: string,
  letterCopyTo: string,
  isActive: number,
  status: number,
  createdBy: number,
  rejectionRemarks: string;
  approvedDate: Date;
  auditRow:string;
}
