import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';

@Injectable({
  providedIn: 'root'
})
export class DeskauditService {

  constructor(private _http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private _Service: LoginServices) { }



  getDeskAuditFiles(auditId,pageSize,pageNumber,searchTerm): Observable<any> {

    return this._http.get<any>(this.config.getDeskAuditFiles + "?auditId=" + auditId+ "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&searchTerm=" + searchTerm, {}).pipe(map((resp: any) => resp));
  }

  getdownloadDetails(url: string) {
    url = this._Service.encryptString(url);
    return this._http.get(this.config.api_base_url + 'DeskAudit/DownloadFile?url=' + url, { responseType: 'arraybuffer' }).pipe(map((resp: any) => { return resp }));
  }
  ActiveDeactive(deactiveDeskAudit) {
    return this._http.post(this.config.active_deactiveDeskAudit, deactiveDeskAudit, { responseType: 'text' });
  }
  SaveAndUpdate(formData, userId): Observable<any> {
    return this._http.post(this.config.DeskAuditSaveAndUpdate, formData, { responseType: 'text' })

  }
}


