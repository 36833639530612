<mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <div class="card left-brd-pink card_margin">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12 text-center"><strong class="card-title">{{title}}</strong></div>
            <div class="col-md-12 text-center" *ngIf="letterNumber"> {{titleHead}} Number : {{letterNumber}}
              <span  *ngIf="letterTitle"> {{titleHead}} Title : {{letterTitle}}</span>
            </div>
          </div>
        </div>
        <div class="card-body card-block">
          <div class="col-md-12 example-card card_overflow">
            <mat-table [dataSource]="dataSourcehistory" matSort
              class="mat-elevation-z8 even-odd-color mat-table customPadding">
              <ng-container matColumnDef="UserName">
                <mat-header-cell *matHeaderCellDef mat-sort-header width="40%"> User </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.userName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="CreatedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdOn|date:'dd/MM/yyyy'}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="RejectionReason">
                <mat-header-cell *matHeaderCellDef mat-sort-header width="40%"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.rejectionReason}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsMoreDetails"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsMoreDetails;"></mat-row>

            </mat-table>

          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>