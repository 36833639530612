<mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <div class="card left-brd-pink card_margin">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12"> <strong class= "card-title">{{titleHead}}</strong></div>
          </div>
          </div>           
        <div class="card-body card-block">
          <div class="col-md-12 example-card card_overflow">
            <mat-table [dataSource]="dataSourcehistory" matSort
              class="mat-elevation-z8 even-odd-color mat-table customPadding">
              <ng-container matColumnDef="MailTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="customMailWidth">Mail To</mat-header-cell>
                <mat-cell *matCellDef="let element" class="customMailWidth"> {{element.mailTO}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="MailCC">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="customMailWidth">Mail CC</mat-header-cell>
                <mat-cell *matCellDef="let element" class="customMailWidth"> {{element.mailCC}} </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="MailSubject">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Mail Subject </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mailSubject}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="MailBody">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Mail Content </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
                  <a (click)="ViewMailConent(element.mailHistoryID)">View<br /></a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Response">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Mail Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.response}} </mat-cell>
              </ng-container>
           

              <ng-container matColumnDef="FileName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Attachment 
                </mat-header-cell>                  
                    <mat-cell *matCellDef="let element"> 
                    {{element.fileName}}<a *ngIf="element.fileName" (click)="DownloadFile(element.fileName)"
                  class="material-icons">vertical_align_bottom<br /></a>
                      </mat-cell>          
                  
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsMoreDetails"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsMoreDetails;"></mat-row>
           
            </mat-table>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>