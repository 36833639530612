import { EventEmitter, Injectable, Inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public sideNav: any;
  public currentUrl = new BehaviorSubject<string>(undefined);

  public watchId: number = 0;
  public locationTrackingActive = false;
  public currentLoation: { latitude: number, longitude: number } = { latitude: 0, longitude: 0 };
  private reactiveDeviceLocation$: Subject<Object>;


  constructor(private _http: HttpClient, private _Service: LoginServices, @Inject(APP_CONFIG) private config: AppConfig) {
    this.reactiveDeviceLocation$ = new BehaviorSubject<Object>(this.currentLoation);
  }

  bindSupervisorDropDown(): Observable<any> {
    return this._http.get(this.config.bindSupervisorDropDown + "?userId=" + this._Service.encryptString(sessionStorage.getItem('userId')), {}).pipe(map((resp: any) => { return resp.result }));

  }
  bindJuniorDropDownTillAuditee(auditId, roleMapID, type): Observable<any> {
    return this._http.get(this.config.BindUserDropDownForwardReturn + "?userId=" + this._Service.encryptString(sessionStorage.getItem('userId')) + "&iAWLevelId=" + this._Service.encryptString(sessionStorage.getItem('iAWLevelId')) + "&roleId=" + this._Service.encryptString(roleMapID)
      + "&type=" + type + "&AuditId=" + auditId, {})
      .pipe(map((resp: any) => { return resp.result }));
  }
  bindmSupervisorDropDown(userId): Observable<any> {
    userId = this._Service.encryptString(userId);
    return this._http.get(this.config.bindSupervisorDropDown + "?userId=" + userId, {}).pipe(map((resp: any) => { return resp.result }));

  }

  GetAuditUsers(roleId: any, auditId: any): Observable<any> {
    roleId = this._Service.encryptString(roleId);
    return this._http.get(this.config.GetAuditUsers + "?roleId=" + roleId + "&auditId=" + auditId, {})
      .pipe(map((resp: any) => { return resp.result }));
  }

  getLocation(): Observable<Object> {
    const opts = { enableHighAccuracy: true, maximumAge: 60000, timeout: 30000 };
    this.watchId = navigator.geolocation.watchPosition((position) => {
      // //this.locationTrackingActive = true;
      // this.currentLoation = { latitude: position.coords.latitude, longitude: position.coords.longitude };
      // this.reactiveDeviceLocation$.next(this.currentLoation);
    },
      (error) => {
        if (error.code == error.PERMISSION_DENIED)
          alert("provide location access to go ahead");
      },
      opts);

    if (navigator.geolocation) {      
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.currentLoation = { latitude: position.coords.latitude, longitude: position.coords.longitude };
          this.reactiveDeviceLocation$.next(this.currentLoation);
        }
      },
        (error: any) => this.getLocation());
    } else {
      alert("Geolocation is not supported by this browser.");
    }
    return this.reactiveDeviceLocation$;
  }

  viewLocation(lat:any, lng:any) {
		const updateUrl = 'https://maps.google.com/?q=' + lat + ',' + lng;
		window.open(updateUrl, '_blank');
	}
}
