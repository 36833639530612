import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../global/global.module';
import { Observable } from 'rxjs';
import { audit, map } from 'rxjs/operators';
import { LoginServices } from '../../login/loginservice';
import * as CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  constructor(private _http: HttpClient,private _Service: LoginServices, @Inject(APP_CONFIG) private config: AppConfig) { }

  BindDropFYear(): Observable<any> {
    // alert('sa')
    return this._http.get(this.config.BindDropFYear, {}).pipe(map((resp: any) => { return resp.result }));

  }
 
 getApprovedScheduledAudits(finYearId: number, quarter: string, roleUserMapId: number): Observable<any> {
    roleUserMapId=this._Service.encryptString(roleUserMapId);
    finYearId=this._Service.encryptString(finYearId);
    quarter=this._Service.encryptString(quarter);
    return this._http.get(this.config.getApprovedScheduledAudits + "?finYearId=" + finYearId + "&quarter=" + quarter + "&roleUserMapId=" + roleUserMapId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  updateScheduledAuditForVerification(obj: any): Observable<any> {
    obj.UserId = this._Service.encryptPost(obj.UserId);
    obj.ForwardedToUserId = this._Service.encryptPost(obj.ForwardedToUserId);
    obj.AuditDecisionStatusId = this._Service.encryptPost(obj.AuditDecisionStatusId);
    obj.StatusId = this._Service.encryptPost(obj.StatusId);
    return this._http.post(this.config.updateScheduledAuditsForVerification, obj).pipe(map((resp: any) => { return resp.result }));
  }

  getApprovedAuditTeamAuditsForDropdown(roleUserMapId:number,finYearId: number, quarter: string): Observable<any> {
    roleUserMapId = this._Service.encryptString(roleUserMapId);
    finYearId = this._Service.encryptString(finYearId);
    quarter = this._Service.encryptString(quarter);
    return this._http.get(this.config.getApprovedAuditTeamAuditsForDropdown + "?roleUserMapId=" + roleUserMapId+"&finYearId="+finYearId+"&Quarter="+quarter, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getApprovedAuditTeam(auditId: any): Observable<any> {    
    return this._http.get(this.config.GetApprovedAuditTeam + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  // Mobile Data View
  getMApprovedAuditTeam(auditId: any): Observable<any> {  
    auditId = this._Service.encryptString(auditId);  
    return this._http.get(this.config.GetApprovedAuditTeam + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  getApprovedAuditTeamList(auditId: any): Observable<any> {
    auditId=this._Service.encryptString(auditId);
    return this._http.get(this.config.GetApprovedAuditTeamList + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  GetApprovedAuditTeam_Audits(auditId: any): Observable<any> {
    auditId=this._Service.encryptString(auditId);
    return this._http.get(this.config.GetApprovedAuditTeam_Audits + "?auditId=" + auditId, {}).pipe(map((resp: any) => { return resp.result }));
  }

  getQuarterAuditTeam(finYearId: any): Observable<any> {
    finYearId=this._Service.encryptString(finYearId);
    return this._http.get(this.config.GetQuarterAuditTeam + "?finYearId=" + finYearId, {}).pipe(map((resp: any) => { return resp.result }));
  }
  
  revertAuditTeam(obj): Observable<any> {
    return this._http.post(this.config.revertAuditTeam, obj).pipe(map((resp: any) => { return resp.result }));
  }
  getAuditHistoryForAuditObservation(auditId: any,statusid: any, memoId: any): Observable<any> {
    statusid=this._Service.encryptString(statusid);
    return this._http.get(this.config.GetAuditHistoryForAuditObservation + "?auditId=" + auditId+"&statusid=" + statusid+"&memoId=" + memoId , {}).pipe(map((resp: any) => { return resp.result }));
  }
}
